import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import panzoom from 'panzoom';
// import GalaxyControls from './GalaxyControls';
import Cluster from './galaxy/Cluster';
import Star from './galaxy/Star';
import Label from './galaxy/Label';

const GalaxySvg = styled(motion.svg)`
  cursor: grab;
  width: ${p => p.responsive ? '100%' : '1500px'};
  height: ${p => p.responsive ? '100%' : '1500px'};
`;

const CentroidStar = styled.circle`
  r: 7px;
  pointer-events: none;
  fill: ${p => p.theme.colors.dark};
`;

const FocusCentroidStar = styled.circle`
  r: 7px;
  color: ${p => p.color};
  pointer-events: none;
  fill: ${p => p.theme.colors.background};
  stroke: currentColor;
  stroke-width: 2.5;
`;

const Beam = styled(motion.line)`
  stroke: ${p => p.intense ? p.color : `${p.color}33`};
  stroke-width: 2.5;
`;

const GridPath = styled.path`
  stroke: #ECECEC;
`;

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 3.0;

const MAX_POINTS_TO_ANIMATE = 500;

const animationVariants = {
  mount: {
    opacity: 0,
  },
  rest: {
    opacity: 1,
  },
};

export default function Galaxy({ participants, personas, focusPersona, setFocusPersona, focusParticipant, setFocusParticipant, movable = true, zoomFocusedPersona, customViewbox, noGrid }) {
  const galaxyRef = useRef();
  const panzoomRef = useRef();

  // const [currentZoom, setCurrentZoom] = useState(1);
  const [hoveredPersona, setHoveredPersona] = useState(null);
  const [hoveredParticipant, setHoveredParticipant] = useState(null);

  const data = personas.map(persona => {
    const points = participants.filter(r => r.personaId === persona.personaId);

    const [tx, ty] = points.reduce(([mx, my], d) => [mx + d.x, my + d.y], [0,0]);
    const [cx, cy] = [tx / points.length, ty / points.length];

    const minX = Math.min(...points.map(({x}) => x));
    const minY = Math.min(...points.map(({y}) => y));
    const maxX = Math.max(...points.map(({x}) => x));
    const maxY = Math.max(...points.map(({y}) => y));

    return {
      ...persona,
      points,
      centroid: { x: cx, y: cy },
      bounds: { x: minX, y: minY, width: maxX - minX, height: maxY - minY },
    }
  });

  let galaxyX, galaxyY, galaxyWidth, galaxyHeight, galaxyPadding;

  if (zoomFocusedPersona && focusPersona) {
    const { bounds: { x, y, width, height }} = data.find(p => p.personaId === focusPersona);
    galaxyX = 10 * x;
    galaxyY = 10 * y;
    galaxyWidth = 10 * width;
    galaxyHeight = 10 * height;
    galaxyPadding = 30;
  } else {
    galaxyX = 0;
    galaxyY = 0;
    galaxyWidth = 1000;
    galaxyHeight = 1000;
    galaxyPadding = 400;
  }

  const viewBox = `${galaxyX-galaxyPadding} ${galaxyY-galaxyPadding} ${galaxyWidth+2*galaxyPadding} ${galaxyHeight+2*galaxyPadding}`;

  useEffect(() => {
    if (movable && galaxyRef.current) {
      panzoomRef.current = panzoom(galaxyRef.current, {
        //smoothScroll: false,
        bounds: true,
        boundsPadding: 0.8,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        zoomDoubleClickSpeed: 1, // Disables the double-click functionality
      });

      // panzoomRef.current.on('zoom', e => setCurrentZoom(e.getTransform().scale));
      panzoomRef.current.moveTo(-300, -400);

      return () => panzoomRef.current?.dispose();
    }
  }, [galaxyRef, movable]);

  let focusContent;

  if (focusPersona !== null) {
    const f = data.find(p => p.personaId === focusPersona);

    if (focusParticipant === null) {
      focusContent = (
        <>
          {f.points.map(d => (
            <Beam
              color={f.color}
              key={`line-${d.id}`}
              x1={10*d.x}
              y1={10*d.y}
              x2={10*f.centroid.x}
              y2={10*f.centroid.y}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            />
          ))}
          <CentroidStar cx={10*f.centroid.x} cy={10*f.centroid.y} color={f.color} />
        </>
      );
    } else {
      const d = f.points.find(({ id }) => id === focusParticipant);

      focusContent = d && (
        <>
          <Beam intense color={f.color} key={`line-${d.id}`} x1={10*d.x} y1={10*d.y} x2={10*f.centroid.x} y2={10*f.centroid.y} />
          <FocusCentroidStar cx={10*f.centroid.x} cy={10*f.centroid.y} color={f.color} />
        </>
      );
    }
  }

  // function setZoom(newZoom) {
  //   const { width, height } = galaxyRef.current.parentElement.getBoundingClientRect();
  //   panzoomRef.current.smoothZoomAbs(width * 0.5, height * 0.5, newZoom);
  // }

  function handleFocusPersona(e, persona) {
    e.stopPropagation();
    setFocusPersona(persona);
  }

  function clearSelection() {
    setFocusParticipant(null);
    setFocusPersona(null);
  }

  const hoveredParticipantModel = hoveredParticipant && participants.find(r => r.id === hoveredParticipant);
  const enableAnimation = participants.length <= MAX_POINTS_TO_ANIMATE;

  return (
    <>
      <GalaxySvg
        variants={enableAnimation ? animationVariants : null}
        initial={enableAnimation ? "mount" : false}
        animate="rest"
        responsive={!movable}
        ref={galaxyRef}
        viewBox={viewBox}
        onClick={clearSelection}
      >
        <pattern id="grid" width="120" height="120" patternUnits="userSpaceOnUse">
          <GridPath d="M 120 0 L 0 0 0 120" fill="none" strokeWidth="1"/>
        </pattern>

        {!noGrid && <rect id="gridrect" x="-400" y="-400" width="1800" height="1800" fill="url(#grid)" />}

        {focusContent}

        {data.map((persona) => {
          return (
            <React.Fragment key={persona.name}>
              <Cluster persona={persona}
                handleClick={e => handleFocusPersona(e, persona.personaId)}
                hovered={hoveredPersona === persona.personaId}
                otherFocused={focusPersona && focusPersona !== persona.personaId}
                focused={focusPersona === persona.personaId}
                setHoveredPersona={setHoveredPersona}
              />

              {persona.points.map(participant => {
                let state = 'normal';

                if (focusParticipant === participant.id) {
                  state = 'participant-focus';
                } else if (hoveredParticipant === participant.id) {
                  state = 'participant-hover';
                } else if (focusParticipant !== null) {
                  state = 'blur';
                } else if (hoveredPersona === persona.personaId && hoveredParticipant !== null) {
                  state = 'other-participant-hover';
                } else if (hoveredPersona === persona.personaId) {
                  state = 'persona-hover';
                } else if (hoveredPersona !== null) {
                  state = 'normal'; // other persona hovered
                } else if (focusParticipant !== null) {
                  state = 'blur'; // other participant focused
                } else if (focusPersona === persona.personaId) {
                  state = 'persona-focus';
                } else if (focusPersona !== null) {
                  state = 'other-persona-focus';
                } else {
                  state = 'normal';
                }

                return (
                  <Star
                    key={`star-${participant.id}`}
                    participant={participant}
                    persona={persona}
                    state={state}
                    setHoveredParticipant={setHoveredParticipant}
                    viewDimensions={{ x: galaxyX, y: galaxyY, width: galaxyWidth, height: galaxyHeight }}
                    handleFocusParticipant={e => {
                      e.stopPropagation();
                      setFocusPersona(persona.personaId);
                      setFocusParticipant(participant.id);
                    }}
                  />
                );
              })}
            </React.Fragment>
        );
        })}

        {hoveredParticipantModel && (
          <Label
            x={hoveredParticipantModel.x + 2.5}
            y={hoveredParticipantModel.y - 2.5}
            lines={hoveredParticipantModel.furtherResearch ? 2 : 1}
            text={[
              hoveredParticipantModel.identifier ?? `Participant ${hoveredParticipantModel.num}`,
              hoveredParticipantModel.furtherResearch ? 'Available' : null
            ]}
          />
        )}
      </GalaxySvg>
      {/* movable && <GalaxyControls
        currentZoom={currentZoom}
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        setCurrentZoom={setZoom}
      /> */}
    </>
  );
}
