import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const BasicStar = styled(motion.circle)`
  r: 7px;
  fill: ${p => p.state === 'blur' ? '#bcbcbc' : p.color};
  opacity: ${p => p.state === 'other-participant-hover' ? 0.25 : 1 };
  cursor: pointer;
`;

const Halo = styled(motion.circle)`
  r: 11px;
  stroke: ${p => p.state === 'blur' ? '#bcbcbc' : p.color};
  opacity: ${p => p.state === 'other-participant-hover' ? 0.25 : 1 };
  stroke-width: 1;
  fill: none;
`;

export default function Star({ state, persona, participant, setHoveredParticipant, handleFocusParticipant, viewDimensions }) {
  const x = participant.x * 10;
  const y = participant.y * 10;

  const variants={
    mount: {
      cx: viewDimensions.x + viewDimensions.width / 2,
      cy: viewDimensions.y + viewDimensions.height / 2,
      scale: 0.2,
    },
    rest: {
      cx: x,
      cy: y,
      scale: 1,
    },
  };

  return (
    <>
      <BasicStar
        color={persona.color}
        state={state}
        onMouseOver={() => setHoveredParticipant(participant.id)}
        onMouseOut={() => setHoveredParticipant(null)}
        onClick={handleFocusParticipant}
        variants={variants}
        transition={{ delay: Math.random() * 0.5 }}
      />
      {participant.furtherResearch && (
        <Halo
          cx={x}
          cy={y}
          color={persona.color}
          state={state}
          variants={variants}
        />
      )}
    </>
  );
}
