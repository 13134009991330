import { Redirect, Route } from 'react-router';
import useAuth from 'hooks/useAuth';
import AccountPending from 'pages/user/AccountPending';

export default function PrivateRoute({ children, ...rest }) {
  const { isLoggedIn, roles } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          roles.includes('placeholder') ? <AccountPending /> : children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
