import { lazy, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Helmet from 'react-helmet';
import PublishModal from './PublishModal';
import LivePreview from './LivePreview';
import Survey from './Survey';
import ConfigureUploadedStudyPage from './ConfigureUploadedStudyPage';
import Share from './Share';
import NavBar from 'components/NavBar';
import Loader from 'components/Loader';
import ErrorPage from 'components/ErrorPage';
import useAuth from 'hooks/useAuth';
import useStudyAccessTimes from 'hooks/useStudyAccessTimes';
import StudyLayout from 'components/study/StudyLayout';

const FakeResponseCreator = lazy(() => import('components/FakeResponseCreator'));

const Wrapper = styled.div`
  height: 100%;
`;

export default function Study({ study, activeQuestion, setActiveQuestion, showPreview, setShowPreview, showPublishModal, setShowPublishModal }) {
  const match = useRouteMatch();
  const [, recordAccess] = useStudyAccessTimes();
  const { roles } = useAuth();

  useEffect(() => {
    recordAccess(study.id);
  }, [study.id, recordAccess]);

  if (study.state === 'uploadPending') {
    return <Loader />;
  }

  if (study.state === 'uploadTimeout') {
    return <ErrorPage message={
      <>
        <p>There was a problem uploading your study.</p>
        <p>Please try again, and reach out to support if you're still having trouble.</p>
      </>}
    />;
  }

  if (study.state === 'uploaded') {
    return (
      <Wrapper>
        <NavBar />

        <ConfigureUploadedStudyPage />
      </Wrapper>
    );
  }

  return (
    <StudyLayout studyId={study.id} name={study.name} access={study.myAccess}>
      <Helmet>
        <title>{study.name}</title>
      </Helmet>
      <Switch>
        <Route path={`${match.path}/survey`}>
          <Survey showPreview={showPreview} />
        </Route>
        {study.myAccess === 'full' ? (
          <Route path={`${match.path}/share`}>
            <Share showPreview={showPreview} />
          </Route>
        ) : null}
        <Route>
          <Redirect to={`${match.url}/survey`} />
        </Route>
      </Switch>

      <Route path={`${match.path}/(survey|share)`}>
        <LivePreview
          study={study}
          activeQuestion={activeQuestion}
          setActiveQuestion={setActiveQuestion}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
        />
      </Route>

      {roles.includes('admin') && (
        <FakeResponseCreator />
      )}

      {study.state === 'draft' && showPublishModal && <PublishModal handleClose={() => setShowPublishModal(false)} />}
    </StudyLayout>
  );
}
