import { useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import Text from 'atoms/Text';
import { ReactComponent as KnobSvg } from 'images/semdiff-knob.svg';

const SLIDER_HEIGHT = 300;

const SemanticDiffLayout = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 10px;
`;

const Knob = styled(motion.div)`
  width: 14px;
  height: 20px;
  position: relative;

  top: 140px;

  > svg {
    position: relative;
    top: -14px;
    left: -13px;
  }
`;

const MiddleText = styled(Text)`
  align-self: center;
`;

const BottomText = styled(Text)`
  align-self: end;
`;

const SliderContainer = styled.div`
  grid-row: 1/4;
  height: ${SLIDER_HEIGHT}px;
  width: 100%;
  cursor: pointer;
`;

const Slider = styled.div`
  position: relative;
  margin: 0 auto;
  width: 14px;
  height: 100%;
  border-radius: 20px;
  background-color: ${p => p.theme.colors.dark};
`;

const Track = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none;
`;

const Notch = styled(Track)`
  top: ${SLIDER_HEIGHT / 2 - 1}px;
  height: 2px;
  background-color: black;
`;

const PrimaryTrack = styled(Track)`
  background-color: ${p => p.theme.colors.brand};
  bottom: ${SLIDER_HEIGHT / 2}px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const SecondaryTrack = styled(Track)`
  background-color: ${p => p.theme.colors.highlight};
  top: ${SLIDER_HEIGHT / 2}px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;

export default function SemanticDifferentialQuestion({ question, answer, setAnswer }) {
  const sliderRef = useRef();
  const barHeight = sliderRef.current ? sliderRef.current.getBoundingClientRect().height : 300; // FIXME: unhardcode this
  const barTop = sliderRef.current ? sliderRef.current.getBoundingClientRect().y : 0; // FIXME: unhardcode this

  const value = answer ?? 0;

  const handleChange = (_event, info) => {
    const answer = (info.point.y - barTop) / (barHeight / 2) - 1;
    const clampedAnswer = Math.max(Math.min(answer, 1), -1);
    const roundedAnswer = Math.round(clampedAnswer * 10) / 10;

    setAnswer(roundedAnswer);
  };

  return (
    <SemanticDiffLayout>
      <SliderContainer>
        <Slider key={question.id} ref={sliderRef}>
          {value < 0 && <PrimaryTrack style={{ height: Math.abs(value * (SLIDER_HEIGHT / 2)) }} />}
          {value > 0 && <SecondaryTrack style={{ height: Math.abs(value * (SLIDER_HEIGHT / 2)) }} />}
          <Notch />
          <Knob
            drag="y"
            dragConstraints={sliderRef}
            dragElastic={0.2}
            dragMomentum={false}
            onDrag={handleChange}
          >
            <KnobSvg />
          </Knob>
        </Slider>
      </SliderContainer>

      <Text strong>{question.options[0].label || 'Option 1'}</Text>
      {value ? <div/> : <MiddleText strong>Drag up or down</MiddleText>}
      <BottomText strong>{question.options[1].label || 'Option 2'}</BottomText>
    </SemanticDiffLayout>
  );
};
