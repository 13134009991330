import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import Link from 'atoms/Link';
import FunctionLink from 'atoms/FunctionLink';
import useAuth from 'hooks/useAuth';
import Stack from 'atoms/Stack';

const Menu = styled(motion.div)`
  position: absolute;
  top: ${p => p.theme.navbarHeight + 10}px;
  left: 10px;
  width: 100px;
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.text};
  color: ${p => p.theme.colors.accentBackground};
  padding: 10px;
  z-index: 4;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: -4px;
    transform: rotate(45deg);
    background-color: ${p => p.theme.colors.text};
    width: 8px;
    height: 8px;
  }
`;

export default function NavBarMenu() {
  const { roles, logoutUser } = useAuth();

  return (
    <Menu
      initial={{ translateY: -20, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: -20, opacity: 0 }}
    >
      <Stack spacing="small">
        <Link nav noUnderline to="/studies">Home</Link>
        <Link nav noUnderline to="/account">Account</Link>
        {roles.includes('admin') ? (
          <Link nav noUnderline to="/admin">Admin</Link>
        ) : null}
        <FunctionLink noUnderline onClick={logoutUser}>Log out</FunctionLink>
      </Stack>
    </Menu>
  );
}
