import { useState } from 'react';
import maxBy from 'lodash.maxby';
import ImportanceBar from 'components/ImportanceBar';
import Grid from 'atoms/Grid';
import { answerDistribution } from 'utils';
import DistributionGrid from './DistributionGrid';
import IconButton from 'atoms/IconButton';

function ResultsGridRow({ question, participants, importance, metricFunc, persona }) {
  const [isOpen, setIsOpen] = useState(false);

  const distribution = answerDistribution(question, participants);
  // Defaults to "common response" if no metricFunc is provided
  const metric = metricFunc ? metricFunc(question, participants) : distribution && maxBy(distribution, ([_i,_l,n]) => n)?.[1];

  return (
    <>
      <Grid.Cell>{question.label}</Grid.Cell>
      {importance ? <ImportanceBar color={persona?.color} value={importance} /> : <div/>}
      <Grid.Cell>{metric}</Grid.Cell>
      <Grid.Cell align="center">
        <IconButton
          icon="chevron"
          iconRotation={isOpen ? 180 : 0}
          onClick={() => setIsOpen(v => !v)}
        />
      </Grid.Cell>

      {isOpen && <DistributionGrid color={persona?.color} distribution={distribution} />}
    </>
  );
}

export default function ResultsGrid({ questions, participants, questionImportances=[], metricLabel, metricFunc, persona }) {
  // TODO: DRY this up we have it in PersonaDetail too
  const importanceValues = Object.values(questionImportances).map(([_total, _n, average]) => average);
  const maxImportance = Math.max(...importanceValues) || 1;

  return (
    <Grid rowTemplate={['1fr', '100px', '150px', '50px']} >
      <Grid.Header>Question</Grid.Header>
      <Grid.Header>Importance</Grid.Header>
      <Grid.Header>{metricLabel || 'Common Response'}</Grid.Header>
      <Grid.Header>Expand</Grid.Header>

      <Grid.Line />

      {questions.map(q => {
        const importance = (questionImportances[q.id] || [0,0,0])[2];
        const scaledImportance = importance / maxImportance;

        return (
          <ResultsGridRow
            metricFunc={metricFunc}
            participants={participants}
            importance={scaledImportance}
            question={q}
            key={q.id}
            persona={persona}
          />
        );
      })}
    </Grid>
  );
}
