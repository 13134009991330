import React from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components/macro';
import { Island } from 'components/atoms/layout';

// Taken from https://codepen.io/aaroniker/pen/MzoXaZ
const rotate = keyframes`
  50% {
    transform: rotate(360deg);
    margin-left: 0;
  }
  50.0001%,
  100% {
    margin-left: -60px;
  }
`;

const move = keyframes`
  0%,50% {
    left: -8px;
  }
  25% {
    background: ${p => p.theme.colors[p.color ?? 'brand']};
  }
  75% {
    background: ${p => p.theme[p.color ?? 'brand']};
  }
  50.0001%,
  100% {
    left: auto;
    right: -8px;
  }
`;

const drop1 = keyframes`
  100% {
    transform: translate(32px, 8px) scale(0);
  }
`;

const drop2 = keyframes`
  0% {
    transform: translate(0, 0) scale(.9);
  }
  100% {
    transform: translate(32px, -8px) scale(0);
  }
`;

const InfinityLoader = styled(motion.div)`
  width: 120px;
  height: 60px;
  position: relative;
  transform: scale(${p => p.scale});
  div,
  span {
    position: absolute;
  }
  div {
    top: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    animation: ${rotate} 6.9s linear infinite;
    span {
      left: -8px;
      top: 50%;
      margin: -8px 0 0 0;
      width: 16px;
      height: 16px;
      display: block;
      background: ${p => p.theme.colors[p.color ?? 'brand']};
      box-shadow: 2px 2px 8px rgba(${p => p.theme.colors[p.color ?? 'brand']}, .09);
      border-radius: 50%;
      transform: rotate(90deg);
      animation: ${move} 6.9s linear infinite;
      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        background: inherit;
        top: 50%;
        left: 50%;
        margin: -7px 0 0 -7px;
        box-shadow: inherit;
      }
      &:before {
        animation: ${drop1} .8s linear infinite;
      }
      &:after {
        animation: ${drop2} .8s linear infinite .4s;
      }
    }
    &:nth-child(2) {
      animation-delay: -2.3s;
      span {
        animation-delay: -2.3s;
      }
    }
    &:nth-child(3) {
      animation-delay: -4.6s;
      span {
        animation-delay: -4.6s;
      }
    }
  }
`;

export default function Loader({ scale = 1, ...otherProps }) {
  return (
    <Island>
      <InfinityLoader scale={scale} {...otherProps}>
        <div>
          <span></span>
        </div>
        <div>
          <span></span>
        </div>
        <div>
          <span></span>
        </div>
      </InfinityLoader>
    </Island>
  );
}
