import React from 'react';
import Grid from 'atoms/Grid';
import { humanizeAnswer } from 'utils';

const IGNORED_QUESTION_TYPES = ['welcome_message', 'message'];

export default function SingleResponseGrid({ questions, answers }) {
  return (
    <Grid rowTemplate={['1fr', '1fr']}>
      <Grid.Header>Question</Grid.Header>
      <Grid.Header>Response</Grid.Header>
      <Grid.Line />
      {questions.filter(q => !IGNORED_QUESTION_TYPES.includes(q)).map(q => {
        const answer = answers[q.id];

        return (
          <React.Fragment key={q.id}>
            <Grid.Cell>{q.label}</Grid.Cell>
            <Grid.Cell>{humanizeAnswer(q, answer)}</Grid.Cell>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}
