import { useState} from 'react';
import Modal from 'components/Modal';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { Stack, Inline } from 'components/atoms/layout';
import Button from 'atoms/Button';

export default function ConfirmButton({ children, title, body, ctaText, cancelText = 'Cancel', onConfirm, ...otherProps }) {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleConfirm = () => {
    setShowModal(false);
    onConfirm();
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowModal(true)}
        {...otherProps}
      >
        {children}
      </Button>

      {showModal && (
        <Modal handleClose={handleClose}>
          <Stack>
            <Inline fullWidth justify="center">
              <Heading>{title}</Heading>
            </Inline>
            <Text size="body">{body}</Text>
            <Inline fullWidth justify="center">
              <Button onClick={handleConfirm}>
                {ctaText}
              </Button>

              <Button variant="secondary" onClick={handleClose}>
                {cancelText}
              </Button>
            </Inline>
          </Stack>
        </Modal>
      )}
    </>
  );
}
