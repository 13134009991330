import { useCallback, useEffect, useState } from 'react';

const BLACKLISTED_TARGETS = ['INPUT', 'TEXTAREA'];

export default function useKeyboardShortcut(shortcutKeys, callback) {
  const [activeKeys, setActiveKeys] = useState(() => {
    return Object.fromEntries(shortcutKeys.map(k => [k, false]));
  });

  const memoKey = shortcutKeys.join();

  const keydownListener = useCallback(event => {
    const { key, target, repeat } = event;

    if (repeat) { return; }
    if (BLACKLISTED_TARGETS.includes(target.tagName)) return;
    if (!shortcutKeys.includes(key)) { return; }

    setActiveKeys(prev => ({ ...prev, [key]: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoKey]);

  const keyupListener = useCallback(event => {
    const { key, target } = event;
    if (BLACKLISTED_TARGETS.includes(target.tagName)) return;
    if (!shortcutKeys.includes(key)) { return; }

    setActiveKeys(prev => ({ ...prev, [key]: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoKey]);

  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true);
    return () => window.removeEventListener('keydown', keydownListener, true);
  }, [keydownListener]);

  useEffect(() => {
    window.addEventListener('keyup', keyupListener, true);
    return () => window.removeEventListener('keyup', keyupListener, true);
  }, [keyupListener]);

  useEffect(() => {
    if (Object.values(activeKeys).every(v => v)) {
      callback();
    }
  }, [callback, activeKeys])
}
