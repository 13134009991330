import { useContext, useState } from 'react';
import { Fragment } from 'react';
import { ThemeContext } from 'styled-components/macro';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import { Stack, Content } from 'components/atoms/layout';
import Inline from 'atoms/Inline';
import AppContext from 'AppContext';
import { BorderlessInput } from 'components/atoms/inputs';
import Button from 'atoms/Button';

function tokenRow(key, defaultValue, currentValue, setToken, parent) {
  if (typeof defaultValue === 'object' && defaultValue !== null) {
    return (
      <Fragment key={key}>
        {Object.entries(defaultValue).map(([subKey,subValue]) => {
          return tokenRow(subKey, subValue, currentValue?.[subKey], setToken, key);
        })}
      </Fragment>
    );
  }

  return (
    <Fragment key={key}>
      <Grid.Cell>{parent}</Grid.Cell>
      <Grid.Cell>{key}</Grid.Cell>
      <Grid.Cell>{defaultValue}</Grid.Cell>
      <Grid.Cell>
        <BorderlessInput
          defaultValue={currentValue || defaultValue}
          onBlur={e => setToken(parent || key, parent ? key : null, e.target.value) }
        />
      </Grid.Cell>
    </Fragment>
  );
}

export default function AdminEvents() {
  const { userTheme, setUserTheme } = useContext(AppContext);
  const [gridKey, setGridKey] = useState(0);

  const theme = useContext(ThemeContext);

  function setToken(key, subKey, value) {
    setUserTheme({
      ...userTheme,
      [key]: subKey ? {
        ...userTheme[key],
        [subKey]: value,
      } : value,
    });
  }

  return (
    <Content>
      <Stack>
        <Inline fullWidth justify="space-between">
          <Heading>Design token playground</Heading>
          <Button variant="secondary" onClick={() => {
            setUserTheme({});
            setGridKey(v => v + 1);
          }}>
            Reset all
          </Button>
        </Inline>

        <Stack key={gridKey}>
          <Grid rowTemplate={['1fr', '1fr', '1fr', '1fr']}>
            <Grid.Header>Token</Grid.Header>
            <Grid.Header>Default</Grid.Header>
            <Grid.Header>Current</Grid.Header>

            <Grid.Line />

            {Object.entries(theme).map(([key, value]) => tokenRow(key, value, userTheme[key], setToken))}
          </Grid>
        </Stack>
      </Stack>
    </Content>
  );
}
