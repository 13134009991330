import { Fragment } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import sortBy from 'lodash.sortby';
import DetailDialog from './DetailDialog';
import { Stack } from 'components/atoms/layout';
import Text from 'atoms/Text';
import { humanizeAnswer } from 'utils';
import useStudyResults from 'hooks/useStudyResults';
import Panel from 'atoms/Panel';
import Heading from 'atoms/Heading';

const Green = styled.span`
  color: #51932A;
`;

export default function ParticipantDetail({ participant, questionImportances, onBack }) {
  const { study: { questions } } = useStudyResults();

  const importances = questionImportances[participant.personaId];
  const keyQuestions = sortBy(questions, q => importances[q.id] ? -importances[q.id][2] : 0).slice(0, 3);
  const history = useHistory();



  return (
    <DetailDialog
      onBack={onBack}
      onExplore={() => history.push(`participant/${participant.id}`)}
    >
      <Panel width={400}>
        <Stack>
          <Heading level={3}>Participant {participant.num}</Heading>

          <Stack spacing="small">
            {participant.furtherResearch && (
              <Stack spacing="xsmall">
                <Text size="small" strong>
                  <Green>Available</Green> for future research
                </Text>
                <Text size="small">{participant.email}</Text>
              </Stack>
            )}

            {keyQuestions.map(q => (
              <Fragment key={q.id}>
                <div>
                  <div><strong>{q.label}</strong></div>
                <div>{humanizeAnswer(q, participant.answers[q.id])}</div>
                </div>
              </Fragment>
            ))}
          </Stack>
        </Stack>
      </Panel>
    </DetailDialog>
  );
}
