import { useState } from 'react';
import { Stack, Heading, Content, Text, Panel } from 'atoms';
import { Spacer } from 'components/atoms/layout';
import Dashboard from 'components/Dashboard';
import ConfirmAccount from 'components/create/ConfirmAccount';
export default function AccountPending() {
  const [emailSent, setEmailSent] = useState(false);

  return (
    <Dashboard>
      <Content>
        <Spacer />

        {emailSent ? (
          <Stack fullWidth>
            <Heading level={3}>We've sent you a verification link</Heading>

            <Text>
              To finish setting up your account, click the link in the verification email
              we sent you.
            </Text>
          </Stack>
        ) : (
          <Panel>
            <Stack spacing="medium">
            <Heading level={3}>Verify your email to access your dashboard</Heading>
              <ConfirmAccount onSuccess={() => setEmailSent(true)}/>
            </Stack>
          </Panel>
        )}
      </Content>
    </Dashboard>
  );
}
