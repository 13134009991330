import { useState } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { REQUEST_UPLOAD_URL_MUTATION } from 'queries';
import Text from 'atoms/Text';
import useNotifications from 'hooks/useNotifications';
import DropZone from 'components/DropZone';
import { MY_STUDIES_QUERY } from 'queries';
import { Stack, Button } from 'atoms';

const ErrorMessage = styled(motion.p)`
  color: ${p => p.theme.colors.error};
  font-weight: bold;
`;

export default function UploadStudy({ onSuccess, handleBack }) {
  const notify = useNotifications();
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const [getUploadUrl] = useMutation(REQUEST_UPLOAD_URL_MUTATION, {
    refetchQueries: [{ query: MY_STUDIES_QUERY, variables: { userId: null }}],
    onError: ({ message }) => notify({ message }),
  });

  async function upload(files) {
    setInProgress(true);
    const [fileName, extension] = files[0].name.split('.');

    if (extension.toLowerCase() !== 'csv') {
      setError("We didn't recognise that format. Try uploading a .csv file");
      setInProgress(false);
      return;
    }

    const { data: { requestUploadUrl }} = await getUploadUrl({ variables: { name: fileName }});

    await fetch(requestUploadUrl.url, {
      method: 'PUT',
      body: files[0],
      headers: {
        'Content-Type': 'text/csv',
      },
    });

    onSuccess(requestUploadUrl.study.id);
  }

  return (
    <Stack>
      <Text>
        Drag or upload a .csv file of your survey. The <strong>column headers</strong> should
        be <strong>questions</strong> and <strong>rows</strong> should be <strong>participant’s responses</strong>.
      </Text>

      {error && (
        <ErrorMessage initial={{ opacity: 0, translateY: 30 }} animate={{ opacity: 1, translateY: 0 }}>
          {error}
        </ErrorMessage>
      )}

      <DropZone
        message="Drag a csv or click to upload"
        handleUpload={upload}
        loading={inProgress}
        disabled={inProgress}
      />

      {handleBack ? (
        <Button variant="secondary" onClick={handleBack}>
          Back
        </Button>
      ) : null}
    </Stack>
  );
}
