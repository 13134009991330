import { useState } from 'react';
import styled from 'styled-components/macro';
import { useParams, useHistory, useRouteMatch } from 'react-router';
import { Content, Stack, Inline } from 'components/atoms/layout';
import Heading from 'atoms/Heading';
import PersonaToggle from 'components/PersonaToggle';
import Select from 'components/Select';
import CompareQuestionsChart from './CompareQuestionsChart';
import Checkbox from 'components/Checkbox';

const COMPARABLE_QUESTION_TYPES = ['multi', 'screener', 'likert', 'nps', 'binary', 'semantic_differential'];

const Italic = styled.em`
  font-style: italic;
  white-space: nowrap;
`;

export default function CompareQuestions({ questions, responses, participants, personas, ignoredParticipantIds }) {
  const { studyId, q1, q2 } = useParams();
  const [ignoredPersonaIds, setIgnoredPersonaIds] = useState([]);
  const [showHeatmap, setShowHeatmap] = useState(true);
  const [showTrendline, setShowTrendline] = useState(true);
  const [spreadOption, setSpreadOption] = useState('random');

  const history = useHistory();
  const match = useRouteMatch();

  const validQuestions = questions.filter(q => COMPARABLE_QUESTION_TYPES.includes(q.type));
  const question1 = validQuestions.find(({ id }) => id === q1);
  const question2 = validQuestions.find(({ id }) => id === q2);

  const options = [...validQuestions.map(q => [q.label, q.id])];

  const updateUrl = (q1, q2) => {
    const newUrl = match.path
      .replace(/:studyId/, studyId)
      .replace(/:q1\?/, q1)
      .replace(/:q2\?/, q2);

    history.push(newUrl);
  };

  const setVisible = (personaIndex, visible) => {
    if (visible) {
      setIgnoredPersonaIds(ignoredPersonaIds.filter(p => p !== personaIndex));
    } else {
      setIgnoredPersonaIds([...ignoredPersonaIds, personaIndex]);
    }
  }

  return (
    <Content>
      <Stack spacing="large">
        <Heading>Question comparison</Heading>

        <Inline>
          <Select
            background
            xDirection="right"
            items={options}
            selectedValue={q1}
            onChange={v => updateUrl(v, q2)}
            placeholder="Pick a question"
          />

          <Italic>compared to</Italic>

          <Select
            background
            xDirection="right"
            items={options}
            selectedValue={q2}
            onChange={v => updateUrl(q1, v)}
            placeholder="pick a question"
          />
        </Inline>

        {(question1 && question2) ? (
          <>
            <CompareQuestionsChart
              question1={question1}
              question2={question2}
              responses={responses}
              participants={participants}
              personas={personas}
              ignoredPersonaIds={ignoredPersonaIds}
              ignoredParticipantIds={ignoredParticipantIds}
              showHeatmap={showHeatmap}
              showTrendline={showTrendline}
              spreadOption={spreadOption}
            />
            <Stack spacing="medium">
              <Inline fullWidth justify="center">
                <Checkbox checked={showTrendline} onChange={(v) => setShowTrendline(v)}>
                  Show trendline
                </Checkbox>

                <Checkbox checked={showHeatmap} onChange={(v) => setShowHeatmap(v)}>
                  Show heatmap
                </Checkbox>

                <Checkbox
                  checked={spreadOption === 'random'}
                  onChange={(v) => setSpreadOption(v ? 'random' : 'radial')}
                >
                  Random jitter
                </Checkbox>
              </Inline>
              {personas && (
                <Inline fullWidth justify="center">
                  {personas.map((persona) => (
                    <PersonaToggle
                      key={persona.personaId}
                      persona={persona}
                      checked={!ignoredPersonaIds.includes(persona.personaId)}
                      onChange={(v) => setVisible(persona.personaId, v)}
                    />
                  ))}
                </Inline>
              )}
            </Stack>
          </>
        ) : null}
      </Stack>
    </Content>
  )
}
