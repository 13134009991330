import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import Questions from './Questions';
import { Stack } from 'components/atoms/layout';
import StudyContext from 'studyContext';
import TextInputModal from 'components/TextInputModal';
import HelpPanel from 'components/HelpPanel';
import Heading from 'atoms/Heading';
import { ReactComponent as PencilSvg } from 'images/icons/pencil.svg';
import machineLadyImage from 'images/help/machine_lady.png';

// TODO: make this a component!!
const StudyName = styled(Heading)`
  cursor: pointer;

  > svg {
    display: none;
    margin-left: 10px;
  }

  &:hover {
    text-decoration: underline;

    > svg {
      display: initial;
    }
  }
`;

export default function SurveyQuestionsSection() {
  const [editingTitle, setEditingTitle] = useState(false);
  const { study: { name, questions }, dispatch } = useContext(StudyContext);

  return (
    <Stack style={{ position: 'relative' }}>
      {editingTitle && <TextInputModal
        title="Rename survey"
        description="Change your survey name"
        defaultValue={name}
        handleClose={() => setEditingTitle(false)}
        handleAction={name => { setEditingTitle(false); dispatch({ type: 'rename', name }) }}
      />}

      <div style={{ width: 'fit-content' }} onClick={() => setEditingTitle(true)}>
        {questions.length > 0 ? (
          <StudyName>
            {name}
            <PencilSvg />
          </StudyName>
        ) : (
          <Heading>Hello Researcher!</Heading>
        )}
      </div>

      <HelpPanel
        imageSrc={machineLadyImage}
        imageStyles={{ right: '60px' }}
        toggleStyles={{ position: 'absolute', top: '8px', right: 0, marginTop: 0 }}
        linkTitle="Resources we love"
        hideByDefault={questions.length > 0}
        allowHide={questions.length > 0}
        links={[
          { label: 'How to create data-driven personas', href: 'https://uxplanet.org/how-to-create-data-driven-personas-1fc3d6b7035f' },
          { label: 'How to write great survey questions', href: 'https://opentext.wsu.edu/carriecuttler/chapter/7-2-constructing-surveys/' },
        ]}
      >
        <p>
          Your survey questions are going to be used to generate clusters so when writing the survey
          try and have a good balance of <strong className="red">behavioural questions</strong> and
          {' '}<strong className="blue">motivational questions,</strong> alongside
          <strong className="green">2-3 open questions</strong> around pain-points and one question capturing their
          contact details.  While demographics are useful to capture, they tend to not define why people
          cluster so it's best not to ask too many of them.
        </p>

        <p>
          If you need help, don't hesitate to use the chat in the bottom right-hand corner and a
          researcher will be with you.
        </p>
      </HelpPanel>

      <Questions />
    </Stack>
  );
}
