import React from 'react';
import styled from 'styled-components/macro';
import { createPortal } from 'react-dom';
import Toast from 'components/Toast';

const NotificationList = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 11;
  pointer-events: none;
`;

export default function Notifications({ notifications }) {
  const notificationRoot = document.getElementById('notification-root');

  return createPortal(
    <NotificationList>
      {notifications.map(({ id, ...rest }) => (
        <Toast key={id} id={id} {...rest} />
      ))}
    </NotificationList>
  , notificationRoot);
}
