import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { MIN_RESPONSES } from '../../../constants';
import { ReactComponent as CircleSvg } from 'images/circle-inactive.svg';
import { ReactComponent as CircleTickSvg } from 'images/circle-tick.svg';
import Text from 'atoms/Text';
import { format } from 'date-fns';
import { Stack } from 'components/atoms/layout';
import Instructions from 'components/study/results/Instructions';
import useRequestAnalysis from 'hooks/useRequestAnalysis';
import Loader from 'components/Loader';
import useStudyResults from 'hooks/useStudyResults';

const ResponseGrid = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 1fr;
  gap: 20px;
  align-items: center;
  max-width: 400px;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 10px;
  grid-column-end: span 2;
  background-color: ${p => p.theme.colors.dark};
  border-radius: 10px;
`;

export default function NotEnoughResponses({ responses }) {
  const { study: { id }} = useStudyResults();
  const [requestAnalysis, { loading }] = useRequestAnalysis(id, 4); // TODO: numClusters

  useEffect(() => {
    // TODO: not multiple times!!
    // TODO: trigger this on the backend one day

    if (responses.length < MIN_RESPONSES) return;

    requestAnalysis().catch(e => console.error(e));

  }, [requestAnalysis, id, responses.length]);

  if (loading) { return <Loader /> }

  return (
    <Stack fullWidth alignItems="center">
      <Instructions
        title="Results"
        message={`Survey will start clustering once at least ${MIN_RESPONSES} people have completed the survey.`}
      />

      <ResponseGrid>
        {responses.map((response, i) => (
          <React.Fragment key={response.id}>
            <CircleTickSvg />
            <Text size="body">Participant {i+1}</Text>
            <Text size="body">
              {format(Date.parse(response.createdAt), 'HH:mma d/MM/yyyy')}
            </Text>
          </React.Fragment>
        ))}

        {new Array(Math.max(0, MIN_RESPONSES - responses.length)).fill(null).map((_, i) => (
          <React.Fragment key={i}>
            <CircleSvg/>
            <Placeholder />
          </React.Fragment>
        ))}
        </ResponseGrid>
    </Stack>
  );
}
