import { useState } from 'react';
import type { Participant } from 'utils/types';
import { useMutation, gql } from '@apollo/client';
import HighlightableAnswer from 'components/study/results/HighlightableAnswer';
import Panel from 'atoms/Panel';
import Heading from 'atoms/Heading';
import Inline from 'atoms/Inline';
import Stack from 'atoms/Stack';
import Button from 'atoms/Button';
import Text from 'atoms/Text';
import { highlightsToShow } from 'utils';

const SET_HIGHLIGHTS_MUTATION = gql`
  mutation setHighlights($responseId: ID!, $highlights: JSONObject, $removedHighlights: JSONObject) {
    updateHighlights(responseId: $responseId, highlights: $highlights, removedHighlights: $removedHighlights) {
      id
      highlights
      removedHighlights
    }
  }
`;

interface Props {
  questionId: string
  participant: Participant
  closeEditor: () => void
}

export default function HighlightEditor({ questionId, participant, closeEditor }: Props) {
  const algoHighlights = participant.algoHighlights?.[questionId] ?? [];

  const initialHighlights = highlightsToShow(
    participant.highlights?.[questionId],
    algoHighlights,
    participant.removedHighlights?.[questionId],
  );
  const [pendingHighlights, setPendingHighlights] = useState(initialHighlights);
  const [setHighlights, { loading }] = useMutation(SET_HIGHLIGHTS_MUTATION);

  function updateHighlights() {
    const ourHighlights: string[] = [];
    const badHighlights: string[] = [];

    pendingHighlights.forEach(h => {
      if (!algoHighlights.includes(h)) {
        ourHighlights.push(h);
      }
    });

    algoHighlights.forEach(h => {
      if (!pendingHighlights.includes(h)) {
        badHighlights.push(h);
      }
    });

    setHighlights({
      variables: {
        responseId: participant.id,
        highlights: { [questionId]: ourHighlights },
        removedHighlights: { [questionId]: badHighlights },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateHighlights: {
          id: participant.id,
          __typename: "Response",
          highlights: { ...participant.highlights, [questionId]: ourHighlights },
          removedHighlights: { ...participant.removedHighlights, [questionId]: badHighlights },
        }
      }
    });

    closeEditor();
  };

  function toggleWord(word: string) {
    if (pendingHighlights.includes(word)) {
      setPendingHighlights(pendingHighlights.filter(w => word !== w));
    } else {
      setPendingHighlights([...pendingHighlights, word]);
    }
  }

  return (
    <Panel width={600}>
      <Inline fullWidth justify="space-between">
        <Stack spacing="small">
          <Heading level={5}>Click on a word to highlight/unhighlight</Heading>

          <Text size="body">
            <HighlightableAnswer
              answer={participant.answers[questionId]}
              highlights={pendingHighlights}
              handleWordClick={toggleWord}
            />
          </Text>
        </Stack>

        <Button
          variant="primary"
          onClick={updateHighlights}
          loading={loading}
        >
          Done
        </Button>
      </Inline>
    </Panel>
  );
}
