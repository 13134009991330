import { ReactNode, useContext, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { ThemeContext } from 'styled-components/macro';
import Icon from 'atoms/Icon';
import { MobileOnly } from './atoms/layout';
import IconButton from 'atoms/IconButton';

interface SidebarDivProps {
  collapsed: boolean
}

const IconWrapper = styled.div`
  position: absolute;
  padding: 10px;
`;

const SidebarWrapper = styled(motion.div)<SidebarDivProps>`
  position: relative;
  height: 100%;
  flex-shrink: 0;
  border-color: ${p => p.theme.colors.dark};
  background-color: ${p => p.theme.colors.accentBackground};
  z-index: 1;

  // @media (max-width: ${p => p.theme.breakpoints.desktop}) {
  //   display: none;
  // }
`;

const SidebarContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 0 40px 40px;
  overflow-y: auto;
`;

const MenuIndicator = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

interface Props {
  children: ReactNode
  className: string
}

export default function Sidebar({ children, className }: Props) {
  const { sidebarWidth } = useContext(ThemeContext);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <SidebarWrapper
      collapsed={collapsed}
      initial={false}
      animate={{
        width: collapsed ? '20px' : sidebarWidth
      }}
      onClick={collapsed ? () => setCollapsed(false) : undefined}
      className={className}
    >
      <>
        {collapsed ? null : (
          <MobileOnly>
            <IconWrapper>
              <IconButton
                icon="chevron"
                color="text"
                iconRotation={90}
                onClick={() => setCollapsed(v => !v)}
              />
            </IconWrapper>
          </MobileOnly>
        )}

        <AnimatePresence>
          {collapsed ? (
            <MenuIndicator>
              <Icon
                i="chevron"
                color="text"
                rotation={270}
              />
            </MenuIndicator>
          ): (
            <SidebarContent
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: `-${sidebarWidth}` }}
            >
              {children}
            </SidebarContent>
          )}
        </AnimatePresence>
      </>
    </SidebarWrapper>
  );
}
