import styled from 'styled-components/macro';
import Helmet from 'react-helmet';
import Dashboard from 'components/Dashboard';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import AdminUserList from './AdminUserList';
import AdminUser from './AdminUser';
import AdminPromoCodeBatches from './AdminPromoCodeBatches';
import AdminPlayground from './AdminPlayground';
import AdminEvents from './AdminEvents';
import AdminDesignTokens from './AdminDesignTokens';
import StepList from 'components/StepList';
import Sidebar from 'components/Sidebar';
import { ContentWrapper } from 'components/atoms/layout';

const SidebarLayout = styled.div`
  height: 100%;
  display: flex;
`;

export default function AdminHome() {
  const match = useRouteMatch();
  const subPageMatch = useRouteMatch(`${match.path}/:subpage`);
  let currentPage = subPageMatch?.params?.subpage;

  if(currentPage === 'user') { currentPage = 'users' }

  const pages = [
    ['users', 'Users'],
    ['events', 'Live events'],
    ['promo_codes', 'Promo codes'],
    ['design_tokens', 'Design tokens'],
    ['playground', 'Playground'],
  ];

  return (
    <Dashboard invertNav darker>
      <Helmet>
        <title>admin</title>
      </Helmet>
      <SidebarLayout>
        <Sidebar>
          <StepList
            hideBackground
            baseUrl={match.url}
            activeStep={currentPage}
            steps={pages}
          />
        </Sidebar>

        <ContentWrapper style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Switch>
            <Route path={`${match.path}/user/:userId`}>
              <AdminUser />
            </Route>

            <Route path={`${match.path}/users`}>
              <AdminUserList />
            </Route>

            <Route path={`${match.path}/promo_codes`}>
              <AdminPromoCodeBatches />
            </Route>

            <Route path={`${match.path}/events`}>
              <AdminEvents />
            </Route>

            <Route path={`${match.path}/design_tokens`}>
              <AdminDesignTokens />
            </Route>

            <Route path={`${match.path}/playground`}>
              <AdminPlayground />
            </Route>

            <Route>
              <Redirect to={`${match.url}/users`} />
            </Route>
          </Switch>
        </ContentWrapper>
      </SidebarLayout>
    </Dashboard>
  );
}
