import { useContext, useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import StudyContext from 'studyContext';
import TextArea from 'atoms/TextArea';
import { QUESTION_TYPES } from '../../../constants';
import { Inline, Stack } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Dropdown from 'components/Dropdown';
import SemanticDifferentialInput from './SemanticDifferentialInput';
import OptionInput from './OptionInput';
import useMeasurePosition from 'hooks/useMeasurePosition';

const QuestionLabel = styled(motion.h4)`
  font-family: ${p => p.theme.fonts.body};
  font-weight: 500;

  ${p => p.dragging ? 'transform: none !important;' : ''}
`;

const QuestionTypeLabel = styled.span`
  font-weight: normal;
`;

const QuestionWrapper = styled(motion.div)`
  margin-left: ${p => p.state === 'active' ? '-15px' : '0'};
  margin-right: ${p => p.state === 'active' ? '-15px' : '0'};
  padding: 30px;
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => (p.state === 'active' || p.state === 'hovered') ? p.theme.shadows.standard : 'none'};
  background-color: ${p => (p.state === 'active' || p.state === 'hovered') ? p.theme.colors.accentBackground : p.theme.colors.accentBackground };
  cursor: pointer;
  transition: 0.3s background-color ease;
`;

export default function Question({ id, type, label, options, allowOther, questionNumber, canDuplicate, deleteConfirm, hoveredQuestion, setHoveredQuestion, updatePosition, updateOrder }) {
  const { dispatch, activeQuestion, setActiveQuestion } = useContext(StudyContext);
  const { optionDisplayType, fixedOptions, reverseOptions, options: hasOptions } = QUESTION_TYPES[type];
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [clickable, setClickable] = useState(true);

  const ref = useMeasurePosition((pos) => updatePosition(questionNumber - 1, pos));
  const textAreaRef = useRef();

  let state;

  if (activeQuestion === (questionNumber - 1)) {
    state = 'active';
  } else if (hoveredQuestion === id) {
    state = 'hovered';
  } else if (activeQuestion) {
    state = 'deemphasized';
  } else if (hoveredQuestion) {
    state = 'notHovered'
  } else {
    state = 'inactive';
  }

  const isActive = state === 'active';

  useEffect(() => {
    if (textAreaRef.current && isActive) {
      textAreaRef.current.focus()
    }
  }, [isActive]);

  const handleDelete = () => {
    dispatch({ type: 'removeQuestion', id });
  };

  return (
    <>
      {showDeleteConfirm && deleteConfirm(
        handleDelete,
        () => setShowDeleteConfirm(false)
      )}
      <QuestionWrapper
        layout
        ref={ref}
        state={state}
        onClick={e => {
          e.stopPropagation(); // since clicks on the backdrop clear activeQuestion

          if (!clickable) { return; }

          setActiveQuestion(questionNumber - 1);
        }}
        onMouseEnter={() => setHoveredQuestion(id)}
        onMouseLeave={() => setHoveredQuestion(null)}
        drag="y"
        onDragStart={() => { setClickable(false); setDragging(true); }}
        onDragEnd={() => { setTimeout(() => setClickable(true), 300); setDragging(false); }}
        style={{ zIndex: dragging ? 3 : 1 }}
        onViewportBoxUpdate={(_viewportBox, delta) => {
          dragging && updateOrder(questionNumber - 1, delta.y.translate);
        }}
      >
        <Stack>
          <Inline fullWidth justify="space-between">
            <Inline spacing="xsmall">
              <QuestionLabel layout dragging={dragging}>
                Question {questionNumber}
                <QuestionTypeLabel> | {QUESTION_TYPES[type].label}</QuestionTypeLabel>
              </QuestionLabel>
            </Inline>

            {isActive ? <Dropdown
              direction="up"
              fixedWidth={110}
              items={[
                canDuplicate && ['Duplicate', () => dispatch({ type: 'duplicateQuestion', id })],
                ['Delete', () => deleteConfirm ? setShowDeleteConfirm(true) : handleDelete()],
              ]}
            /> : null}
          </Inline>

          {isActive ? (
            <TextArea
              ref={textAreaRef}
              rows={5}
              maxLength={300}
              placeholder="Type your question in here"
              onChange={v => dispatch({ type: 'changeLabel', id, value: v })}
              value={label || ''}
            />
          ) : (
            <Text>{label}</Text>
          )}

          {hasOptions && isActive ? (
            optionDisplayType === 'semantic_differential'
              ? <SemanticDifferentialInput id={id} questionNumber={questionNumber} options={options} />
              : (
                <OptionInput
                  id={id}
                  questionNumber={questionNumber}
                  type={type}
                  displayType={optionDisplayType}
                  options={options}
                  fixedOptions={fixedOptions}
                  allowOther={allowOther}
                  reverseOptions={reverseOptions}
                  showExcludeToggles={type === 'screener'}
                />
              )
          ) : null}
        </Stack>
      </QuestionWrapper>
    </>
  );
}
