import React from 'react';
import styled from 'styled-components/macro';

const BorderlessTextWrapper = styled.div`
  width: ${p => p.fullWidth ? '100%' : 'auto'};
  position: relative;
  border-bottom: 2px solid transparent;
  height: fit-content;

  /* TODO: this shouldn't apply when the delete button is focused, only the text input */
  &:focus-within {
    border-color: ${p => p.theme.colors.highlight};
  }
`;

const BorderlessTextInput = styled.input`
  width: 100%;
  margin: 0;
  flex: 1;
  border: 0;
  border-bottom: 2px solid ${p => p.theme.colors.dark};
  padding: 4px 10px 6px 10px;
  color: black;
  font-size: 14px;
  background-color: inherit;

  &[disabled] {
    pointer-events: none;
    color: ${p => p.theme.colors.dark};
  }

  &:hover {
    border-color: ${p => p.theme.colors.text};
  }

  &:focus {
    border-color: ${p => p.theme.colors.text};
    outline: none;
    box-shadow: none;
  }
`;

export const BorderlessInput = React.forwardRef(({ children, fullWidth, style, ...props }, ref) => (
  <BorderlessTextWrapper fullWidth={fullWidth} style={style}>
    <BorderlessTextInput ref={ref} {...props} />
    {children}
  </BorderlessTextWrapper>
));
