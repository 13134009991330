import { useState, ReactNode, Fragment } from 'react';
import type { Participant } from 'utils/types';
import { countHighlights } from 'utils';
import Highlight from 'atoms/Highlight';
import FunctionLink from 'atoms/FunctionLink';

interface Props {
  responses: Participant[]
  maxToShow: number
  showHighlights: boolean
  children: (arg: ReactNode) => ReactNode
}

export default function HighlightSummary({ responses, maxToShow, showHighlights, children }: Props) {
  const [showAll, setShowAll] = useState(false);
  const sortedCounts = countHighlights(responses);
  const moreToShow = Math.max(sortedCounts.length - maxToShow, 0);

  if (!sortedCounts.length) { return null; }

  const visibleHighlights = showAll ? sortedCounts : sortedCounts.slice(0, maxToShow);

  return children(
    <>
      {visibleHighlights.map(([word,count], i) => {

        return (
          <Fragment key={word}>
            {showHighlights
              ? <Highlight>{word}</Highlight>
              : word
            }
            {count > 1 ? ` (${count})` : ''}
            {i === visibleHighlights.length - 1 ? '' : ', '}
          </Fragment>
        );
      })}
      .{' '}

      {!showAll && moreToShow > 0 && (
        <FunctionLink onClick={() => setShowAll(v => !v)}>
          Show {moreToShow} more
        </FunctionLink>
      )}
    </>
  );
}
