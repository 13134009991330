import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery, useMutation } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { Content, Stack } from 'components/atoms/layout';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import Button from 'atoms/Button';
import IconButton from 'atoms/IconButton';
import TextInputModal from 'components/TextInputModal';
import HelpPanel from 'components/HelpPanel';
import { STUDY_PERMISSIONS_QUERY, INVITE_USER_MUTATION, CHANGE_USER_ACCESS_MUTATION } from 'queries';
import useNotifications from 'hooks/useNotifications';
import machineLadyImage from 'images/help/machine_lady.png';
import Loader from 'components/Loader';

const HelpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default function Collaborate({ studyId }) {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviting, setInviting] = useState(false);
  const notify = useNotifications();

  const { data, loading } = useQuery(STUDY_PERMISSIONS_QUERY, {
    variables: { id: studyId },
  });

  const [inviteUserMutation] = useMutation(INVITE_USER_MUTATION, {
    refetchQueries: ['studyPermissions'],
    onCompleted: (data) => {
      if (data.inviteUserToStudy) {
        notify({ message: 'Invitation sent!' });
      } else {
        notify({ message: 'There was a problem inviting that user.' });
      }

      setInviting(false);
    },
    onError: (error) => {
      notify({ message: error.message });

      setInviting(false);
    },
  });

  const [changeAccessMutation, { loading: changeLoading }] = useMutation(CHANGE_USER_ACCESS_MUTATION, {
    refetchQueries: ['studyPermissions'],
    onCompleted: (data) => {
      notify({ message: 'Access revoked!' });

      setInviting(false);
    },
    onError: (error) => {
      notify({ message: error });
    },
  });

  const inviteUser = (email) => {
    if (!email.match(/@/)) {
      throw new Error('Email must contain an @');
    }

    setInviting(true);

    inviteUserMutation({
      variables: { email, studyId, access: 'view' },
    }).finally(() => {
      setShowInviteModal(false);
    });
  };

  const uninviteUser = (userId) => {
    changeAccessMutation({
      variables: { userId, studyId, access: null },
    });
  };

  return (
    <Content>
      <Stack spacing="medium">
        <Stack spacing="small">
          <Heading>Collaborators</Heading>

          <HelpWrapper>
            <HelpPanel
              identifier="collaborate"
              imageSrc={machineLadyImage}
              imageStyles={{ right: '60px' }}
              allowHide
            >
              <p>
                You can invite your colleagues to view your results.  They'll need to sign up for an Akin
                account before you can send the invitation.
              </p>

              <p style={{ maxWidth: '300px' }}>
                People you invite will have access to view <strong className="red">all of this study's results</strong> —
                including email addresses and open text answers — so make sure there's no sensitive data
                in there.
              </p>
            </HelpPanel>
          </HelpWrapper>
        </Stack>

        {loading ? (
          <Loader />
        ) : (
          data.study.permissions.length ? (
            <Grid rowTemplate={['1fr', '100px', '60px']}>
              <Grid.Header>Email address</Grid.Header>
              <Grid.Header>Access level</Grid.Header>
              <Grid.Header></Grid.Header>
              <Grid.Line />

              {data.study.permissions.map(permission => {
                return (
                  <React.Fragment key={permission.userId}>
                    <Grid.Cell>
                      {permission.email}
                    </Grid.Cell>

                    <Grid.Cell>
                      {permission.access}
                    </Grid.Cell>

                    <Grid.Cell align="center">
                      <IconButton
                        icon="x"
                        loading={changeLoading}
                        onClick={() => uninviteUser(permission.userId) }
                      />
                    </Grid.Cell>
                    <Grid.Line />
                  </React.Fragment>
                );
              })}
            </Grid>
          ) : null
        )}

        <Button variant="secondary" onClick={() => setShowInviteModal(true)}>
          Invite colleague
        </Button>
      </Stack>

      <AnimatePresence>
        {showInviteModal && <TextInputModal
          title="Invite colleague"
          description="Enter an email address to invite another user to view your study."
          fieldLabel="Email address"
          loading={inviting}
          handleClose={() => setShowInviteModal(false)}
          handleAction={inviteUser}
          confirmLabel="Invite"
        />}
      </AnimatePresence>
    </Content>
  )
}
