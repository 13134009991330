import { useState } from 'react';
import useStudyResults from 'hooks/useStudyResults';
import TextInputModal from 'components/TextInputModal';
import Button from 'atoms/Button';

export default function RenamePersonaButton({ persona }) {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const { study: { id, resultsOptions: { clusterNames }},  updateStudy, updateLoading } = useStudyResults();

  function handleRename(newName) {
    const newNames = clusterNames ? clusterNames.slice() : [];
    newNames[persona.personaId] = newName;

    updateStudy({
      variables: {
        id,
        input: {
          resultsOptions: {
            clusterNames: Array.from(newNames, e => e || '')
          },
        },
      },
    }).then(() => setShowRenameModal(false));
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowRenameModal(true)}
      >
        Rename
      </Button>

      {showRenameModal && (
        <TextInputModal
          title="Rename cluster"
          defaultValue={persona.name}
          handleAction={handleRename}
          handleClose={() => setShowRenameModal(false)}
          loading={updateLoading}
          confirmLabel="Rename"
        />
      )}
    </>
  );
}
