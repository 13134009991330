import { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as CaretIcon } from 'images/icons/chevron-right.svg';
import { NotMobile } from 'components/atoms/layout';
import { ReactComponent as FastForwardSvg } from 'images/icons/fast-forward.svg';
import useLocalStorage from 'hooks/useLocalStorage';
import FunctionLink from 'atoms/FunctionLink';
import ExternalLink from 'atoms/ExternalLink';
import Inline from 'atoms/Inline';

const PanelWrapper = styled(motion.aside)`
  position: relative;
  width: 100%;
  padding: 30px;
  box-shadow: ${p => p.theme.shadows.standard};
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.accentBackground};
  overflow: hidden;

  > h2 {
    margin-bottom: 30px;
  }

  > p {
    margin-bottom: 20px;
  }

  .red {
    color: ${p => p.theme.colors.brand};
  }

  .blue {
    color: #3C94BA;
  }

  .green {
    color: #51932A;
  }
`;

const HelpImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 10px;
  max-width: 200px;
`;

const LinkTitle = styled.h4`
  margin-bottom: 1rem;
`;

const LinkList = styled.ul`
  overflow: hidden;
`;

const ToggleWrapper = styled.div`
  z-index: 2;
`;

interface ToggleIconProps {
  $show: boolean
  rotation: number
}

const ToggleIcon = styled(FastForwardSvg)<ToggleIconProps>`
  transform: rotate(${p => p.$show ? `${p.rotation + 180}deg` : `${p.rotation}deg`});
  transition: transform 0.2s;
  z-index: 2;
`;

const HelpBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;

type HelpPanelLink = {
  label: string
  href: string
}

export interface Props {
  identifier: string
  children: ReactNode
  allowHide?: boolean
  hideByDefault?: boolean
  hideDirection?: 'up' | 'right'
  imageSrc?: string
  imageStyles?: object
  linkTitle?: string
  links?: HelpPanelLink[]
  panelStyles?: object,
  toggleStyles?: object
  clickOutsideToClose?: boolean
}

export default function HelpPanel({ identifier, children, allowHide = true, hideByDefault, hideDirection = 'up', imageSrc, imageStyles, linkTitle, links, panelStyles, toggleStyles, clickOutsideToClose }: Props) {
  const [persistedShow, setPersistedShow] = useLocalStorage<Record<string, boolean>>('helpPanels', {});
  const [show, setShow] = useState<boolean>(persistedShow[identifier] ?? !hideByDefault);

  // Persist state in localstorage
  const toggle = (newValue: boolean) => {
    if (identifier) {
      setPersistedShow({ ...persistedShow, [identifier]: newValue });
    }

    setShow(newValue);
  }

  return (
    <>
      {clickOutsideToClose && show ? (
        <HelpBackdrop onClick={() => toggle(false) }/>
      ) : null}
      {allowHide ? (
        <ToggleWrapper style={toggleStyles}>
          <FunctionLink
            noUnderline
            onClick={() => toggle(!show)}
          >
            <Inline align="center" spacing="xsmall">
              <span>{ show ? 'Hide help' : 'Show help' }</span>
              <ToggleIcon width={16} $show={show} rotation={hideDirection === 'up' ? 90 : 180} />
            </Inline>
          </FunctionLink>
        </ToggleWrapper>
      ) : null}
      <AnimatePresence initial={false}>
        {show ? (
          <PanelWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={panelStyles}
          >
            { children }

            <NotMobile>
              <HelpImage src={imageSrc} style={imageStyles}/>
            </NotMobile>

            {linkTitle && <LinkTitle>{linkTitle}</LinkTitle>}

            {links && (
              <LinkList>
                {links.map(({ label, href }) => (
                  <li key={label}>
                    <ExternalLink noUnderline newTab href={href}>
                      <Inline align="center" spacing="xsmall">
                        <span>{label}</span>
                        <CaretIcon width={20} />
                      </Inline>
                    </ExternalLink>
                  </li>
                ))}
              </LinkList>
            )}
          </PanelWrapper>
        ) : null}
      </AnimatePresence>
    </>
  )
}
