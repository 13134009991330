import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import InteractableTile from 'components/InteractableTile';

const SourceTileWrapper = styled(InteractableTile)`
  overflow: hidden;
`;

type BannerProps = {
  color: string,
};

const TileBanner = styled.div<BannerProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 13px;
  text-align: center;
  font-size: 10px;
  transform: rotate(45deg) translateX(30%);

  color: ${p => p.theme.colors.textInvert};
  background-color: ${p => p.theme.colors[p.color]};
`;

export type Props = {
  onClick: () => void,
  bannerText?: string,
  bannerColor?: string,
  children: ReactNode,
}

export default function SourceTile({ onClick, bannerText, bannerColor = 'brand', children }: Props) {
  return (
    <SourceTileWrapper onClick={onClick}>
      {bannerText ? <TileBanner color={bannerColor}>{bannerText}</TileBanner> : null}
      {children}
    </SourceTileWrapper>
  );
}
