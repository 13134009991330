import { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { NavLink as ReactRouterNavLink, useLocation } from 'react-router-dom';

const TabBarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Tab = styled.div`
  width: 90px;
  display: flex;
  justify-content: center;

  > a {
    position: relative;
    color: ${p => p.invert ? p.theme.colors.text : p.theme.colors.background};
    padding: 6px 6px 10px 6px;
    font-family: ${p => p.theme.fonts.body};
    font-size: 14px;
    font-weight: 300;

    &:hover:not(.active) {
      font-weight: 700;
    }

    &:active {
      font-weight: 700;
      color: ${p => p.theme.colors.brand};
    }

    &.active {
      font-weight: 700;
    }
  }
`;

const Underline = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: ${p => p.theme.colors.brand};
`;

export default function TabBar({ tabs, basePath, invert }) {
  const [hovering, setHovering] = useState(null);
  const headerRef = useRef();

  const location = useLocation();
  const actualBasePath = basePath ?? location.pathname.replace(/([^/]*)$/, '');
  const activeTabMatch = location.pathname.match(new RegExp(String.raw`${actualBasePath}/?([^/]*)`));
  const activeTab = activeTabMatch && activeTabMatch[1];

  return (
    <TabBarWrapper ref={headerRef} onMouseLeave={e => setHovering(null)}>
      <AnimateSharedLayout>
        {tabs.map(([path, label]) => {
          return (
            <Tab
              key={path}
              onMouseEnter={() => setHovering(path)}
              invert={invert}
            >
              <ReactRouterNavLink to={`${actualBasePath}/${path}`}>
                {label}
                {((hovering === null && activeTab === path) || hovering === path) && <Underline layoutId="underline" />}
              </ReactRouterNavLink>
            </Tab>
          );
        })}
      </AnimateSharedLayout>
    </TabBarWrapper>
  )
}
