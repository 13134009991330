import styled from 'styled-components/macro';
import { FeatureTitle, FeatureDescription, MarketingContent } from 'components/atoms/marketing';
import { NotMobile, InlineCollapse, Stack } from 'components/atoms/layout';
import Button from 'atoms/Button';

const ComponentWrapper = styled.div`
  width: 100%;
  padding: 70px 0;
  background-color: ${p => p.altBackground ? p.theme.marketingAltBackgroundColor : 'inherit'};
`;

export const FeatureImage = styled.img`
  transform: scale(${p => p.$scale[0]});

  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    transform: scale(${p => p.$scale[1]});

    max-width: 428px;
    max-height: 318px;
  }

  @media (min-width: ${p => p.theme.breakpoints.desktop}) {
    max-width: 50%;
    transform: scale(${p => p.$scale[2]});
  }
`;

// imageScale can be a single scale value or an array of 3 values, for mobile, desktop, and tablet respectively.
export default function MarketingComponent({ title, text, ctaLabel, ctaLink, image, imageAlt, imageScale = 1, imageOnLeft, altBackground }) {
  const scaleArray = Array.isArray(imageScale) ? imageScale : [imageScale, imageScale, imageScale];

  let ctaAction;

  if (ctaLink === 'demo') {
    ctaAction = () => { window.open('/demo') }
  } else if (ctaLink === 'our_survey') {
    ctaAction = () => { window.open('/s/bc0c76dd-b833-4467-8328-bba80baaeb81') }
  }

  return (
    <ComponentWrapper altBackground={altBackground}>
      <MarketingContent>
        <InlineCollapse fullWidth spacing="xlarge" justify="space-between" reverse={imageOnLeft}>
          <Stack style={{ maxWidth: 440 }}>
            <FeatureTitle>{title}</FeatureTitle>
            <FeatureDescription>{text}</FeatureDescription>
            {ctaLabel ? <NotMobile><Button variant="secondary" onClick={ctaAction}>{ctaLabel}</Button></NotMobile> : null}
          </Stack>

          <FeatureImage src={image} alt={imageAlt} $scale={scaleArray} />
        </InlineCollapse>
      </MarketingContent>
    </ComponentWrapper>
  );
}
