import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import StudyContext from 'studyContext';
import SurveyQuestionsSection from './survey/SurveyQuestionsSection';
import QuestionsLocked from './survey/QuestionsLocked';
import { ContentWrapper } from 'components/atoms/layout';
import Content from 'atoms/Content';
import { Fader } from 'components/Fader';

const FaderBelowNav = styled(Fader)`
  top: ${p => p.theme.navbarHeight}px;
`;

export default function Survey({ showPreview }) {
  const { study: { state, questions }, setActiveQuestion } = useContext(StudyContext);
  const locked = state !== 'draft';

  // TODO: unhardcode the nav height later please future Paul
  return (
    <ContentWrapper
      offsetY={40}
      showPreview={showPreview}
      onClick={() => setActiveQuestion(null)}
    >
      <FaderBelowNav />

      <Content>
        {locked
          ? <QuestionsLocked questions={questions}/>
          : <SurveyQuestionsSection />
        }
      </Content>
    </ContentWrapper>
  )
}
