import { useState } from 'react';
import styled from 'styled-components/macro';
import whiteLogoImage from 'images/logo-invert.svg';
import blackLogoImage from 'images/logo.svg';
import FunctionLink from 'atoms/FunctionLink';
import FeedbackPanel from 'components/FeedbackPanel';
import NavBarMenu from 'components/NavBarMenu';
import { NotMobile } from 'components/atoms/layout';
import Icon from 'atoms/Icon';
import Inline from 'atoms/Inline';
import { AnimatePresence } from 'framer-motion';

const TopNav = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: ${p => p.theme.navbarHeight}px;
  background-color: ${p => p.invert ? 'transparent' : p.theme.colors.text};
  z-index: 4;
  transition: background-color 0.8s ease-out;

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    grid-template-columns: ${p => p.noChildren ? '100px' : '0'} 1fr 100px;
  }

  > :first-child {
    margin: auto auto auto 20px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
      ${p => p.noChildren ? '' : 'visibility: hidden;'}
    }
  }

  > :last-child {
    margin: auto 20px auto auto;
  }

  a {
    color: ${p => p.invert ? p.theme.colors.text : p.theme.colors.background};
  }
`;

const NavContent = styled.div`
  margin: auto auto 0 auto;

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    margin-left: 0;
    padding-left: 40px;
  }

  > p {
    padding-bottom: 10px;
    font-weight: 500;
    color: ${p => p.invert ? p.theme.colors.text : p.theme.colors.background};
  }
`;

export default function NavBar({ children, blank, invert }) {
  const [showFeedbackPanel, setShowFeedbackPanel] = useState(false);
  const [showNavBarMenu, setShowNavBarMenu] = useState(false);

  return (
    <>
      <TopNav invert={invert} noChildren={children === undefined}>
        {blank ? <div /> : (
          <FunctionLink noUnderline onClick={() => setShowNavBarMenu(v => !v)}>
            <Inline spacing="xsmall" align="center">
              <img
                src={invert ? blackLogoImage : whiteLogoImage}
                alt="Akin logo"
              />

              <Icon i="chevron" color={invert ? 'text' : 'background'} rotation={showNavBarMenu ? 180 : 0} />
            </Inline>
          </FunctionLink>
        )}

        <NavContent>
          { children }
        </NavContent>

        <NotMobile>
          <FunctionLink noUnderline onClick={() => setShowFeedbackPanel(v => !v)}>
            <Inline spacing="xsmall" align="center">
              <Icon i="feedback" size="large" />
              Thoughts?
            </Inline>
          </FunctionLink>
        </NotMobile>
      </TopNav>

      <AnimatePresence>
        {showNavBarMenu ? (
          <NavBarMenu handleClose={() => setShowNavBarMenu(false) }/>
        ) : null}
      </AnimatePresence>

      <AnimatePresence>
        {showFeedbackPanel ? (
          <FeedbackPanel handleClose={() => setShowFeedbackPanel(false)} />
        ) : null}
      </AnimatePresence>
    </>
  );
}
