import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import Icon, { Props as IconProps } from 'atoms/Icon';

interface ButtonProps {
  $loading: boolean
  color?: string
}

const Button = styled(motion.button)<ButtonProps>`
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  border: none;
  cursor: pointer;
  color: ${p => p.$loading ? p.theme.colors.dark : p.theme.colors[p.color || 'brand'] };
  background-color: ${p => p.theme.colors[p.color || 'brand']}33;
  border-radius: 50%;
  outline: none;

  &:hover {
    color: ${p => p.theme.colors.textInvert};
    background-color: ${p => p.theme.colors[p.color || 'brand']};
  }

  &:active {
    color: ${p => p.theme.colors.textInvert};
    background-color: ${p => p.theme.colors.tertiary};
  }

  &[disabled] {
    color: ${p => p.theme.colors.dark};
    background-color: ${p => p.theme.colors.dark}33;
  }

  &:focus:not(:hover) {
    box-shadow: 0 0 0 2px ${p => p.theme.colors.highlight};
  }
`;

export interface Props {
  icon: IconProps['i']
  iconRotation?: number
  loading?: boolean
  color?: string
  className?: string
  onClick: () => void
}

export default function IconButton({ icon, iconRotation, loading, className, color, onClick }: Props) {
  return (
    <Button
      animate={loading ? {
        rotate: [0, 360],
        transition: { duration: 1, repeat: Infinity }
      } : {}}
      whileTap={{ scale: 1.2 }}
      onClick={onClick}
      $loading={!!loading}
      color={color}
      disabled={!!loading}
      className={className}
    >
      <Icon
        i={icon}
        size="small"
        rotation={iconRotation}
      />
    </Button>
  );
};
