import styled from 'styled-components/macro';
import { useParams } from 'react-router';
import Helmet from 'react-helmet';
import content from 'blogPosts.json';
import ErrorPage from './ErrorPage';
import { Emphasis } from './atoms/marketing';
import Heading from 'atoms/Heading';
import Content from 'atoms/Content';
import { Stack } from './atoms/layout';

type BlogRouteParams = {
  slug: string
}

interface Params {
  triggerSignup: () => void
}

const BlogContent = styled.article`
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: ${p => p.theme.spacing.small};
  }

  h1 {
    font-size: ${p => p.theme.headingSizes[1]};
    line-height: ${p => p.theme.headingLineHeights[1] };
  }

  h2 {
    font-size: ${p => p.theme.headingSizes[2]};
    line-height: ${p => p.theme.headingLineHeights[2] };
  }

  h3 {
    font-size: ${p => p.theme.headingSizes[3]};
    line-height: ${p => p.theme.headingLineHeights[3] };
  }

  h4 {
    font-size: ${p => p.theme.headingSizes[4]};
    line-height: ${p => p.theme.headingLineHeights[4] };
  }

  h5 {
    font-size: ${p => p.theme.headingSizes[5]};
    line-height: ${p => p.theme.headingLineHeights[5] };
  }

  h6 {
    font-size: ${p => p.theme.headingSizes[6]};
    line-height: ${p => p.theme.headingLineHeights[6] };
  }

  > * {
    margin-bottom: ${p => p.theme.spacing.small};
  }

  ul {
    list-style-type: initial;
  }

  code {
    font-family: ${p => p.theme.fonts.monospace};
  }
`;

// Only supports zero or one emphasized section, which is fine for now
// DRY this up with MarketingPage one day
function addEmphasis(text: string) {
  const [head, emph, tail] = text.split('*');

  if (!emph) { return head; }

  return <>{head} <Emphasis>{emph}</Emphasis> {tail}</>;
}

export default function BlogPost({ triggerSignup }: Params) {
  const { slug } = useParams<BlogRouteParams>();

  const blogPost = content.find(c => c.slug === slug);

  if (!blogPost) {
    return <ErrorPage message="That page could not be found." />;
  }

  return (
    <>
      <Helmet>
        <title>{blogPost.title}</title>
      </Helmet>

      <Content>
        <Stack>
          <Heading>{addEmphasis(blogPost.title)}</Heading>

          {blogPost.heroImage ? (
            <img src={blogPost.heroImage} alt="Blog post hero" />
          ) : null}

          <BlogContent dangerouslySetInnerHTML={{ __html: blogPost.content }} />
        </Stack>
      </Content>
    </>
  );
}
