import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import StudyContext from 'studyContext';
import { BorderlessInput } from 'components/atoms/inputs';
import { ReactComponent as SliderSvg } from 'images/studies/semantic-diff-slider.svg';

const SemanticGrid = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 10px;
  grid-template-rows: 40px 1fr 40px;
`;

const Slider = styled(SliderSvg)`
  grid-row: 1 / 4;

  color: ${p => p.theme.colors.dark};

  div:focus-within ~ & {
    color: ${p => p.theme.colors.text};
  }
`;

export default function SemanticDifferentialInput({ id, questionNumber, options }) {
  const { dispatch, setActiveQuestion } = useContext(StudyContext);

  const [{ label: label1 }, { label: label2 }] = options;

  return (
    <SemanticGrid>
      <BorderlessInput
        value={label1}
        placeholder="Option 1"
        onFocus={() => setActiveQuestion(questionNumber - 1)}
        onChange={e => dispatch({ type: 'changeOption', id, optionIndex: 0, value: e.target.value })}
      />
      <div />
      <BorderlessInput
        value={label2}
        placeholder="Option 2"
        onFocus={() => setActiveQuestion(questionNumber - 1)}
        onChange={e => dispatch({ type: 'changeOption', id, optionIndex: 1, value: e.target.value })}
      />
      <Slider/>
    </SemanticGrid>
  );
}
