import { Fragment } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import Loader from 'components/Loader';
import { Button, Stack, Grid, IconButton } from 'atoms';
import { MY_STUDIES_QUERY } from 'queries';

const SURVEY_MONKEY_STUDIES_QUERY = gql`
  query surveyMonkeyStudies {
    importableStudies(integration: SurveyMonkey) {
      id
      name
    }
  }
`;

const SURVEY_MONKEY_CLONE_STUDY_MUTATION = gql`
  mutation cloneSurveyMonkeyStudy($id: ID!) {
    importStudy(type: SurveyMonkey, id: $id) {
      id
      name
    }
  }
`;

type ImportableStudy = {
  id: string,
  name: string
}

type Props = {
  onSuccess: (studyId: string) => void;
  handleBack: () => void;
}

export default function SurveyMonkey({ onSuccess, handleBack }: Props) {
  // TODO: fetch-policy on these?
  const { loading: studiesLoading, data: studiesData } = useQuery(SURVEY_MONKEY_STUDIES_QUERY);

  const [importMutation, { loading: importLoading }] = useMutation(SURVEY_MONKEY_CLONE_STUDY_MUTATION, {
    refetchQueries: [{ query: MY_STUDIES_QUERY, variables: { userId: null }}],
    awaitRefetchQueries: true,
  });

  function importStudy(studyId: string) {
    importMutation({
      variables: {
        id: studyId,
      }
    }).then(response => onSuccess(response.data.importStudy.id));
  }

  if (studiesLoading || importLoading) {
    return <Loader />;
  }

  return (
    <Stack fullWidth>
      <Grid rowTemplate={["1fr 50px"]}>
        <Grid.Header>Study name</Grid.Header>
        <Grid.Header>Import</Grid.Header>

        {studiesData?.importableStudies.map((s: ImportableStudy) => {
          return (
            <Fragment key={s.id}>
              <Grid.Cell>{s.name}</Grid.Cell>
              <Grid.Cell align="right">
                <IconButton icon="clone" onClick={() => importStudy(s.id)} />
              </Grid.Cell>
            </Fragment>
          );
        })}
      </Grid>

      <Button variant="secondary" onClick={handleBack}>
        Back
      </Button>
    </Stack>
  );
}
