import styled from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import ParticipantView from 'components/participant/ParticipantView';
import { Stack, Inline, NotMobile, DesktopOnly } from 'components/atoms/layout';
import FunctionLink from 'atoms/FunctionLink';
import { ReactComponent as PreviewPlaceholderSvg } from 'images/preview-placeholder.svg';
import { ReactComponent as FastForwardSvg } from 'images/icons/fast-forward.svg';

const PreviewContainer = styled(motion.div)`
  position: fixed;
  top: ${p => p.theme.navbarHeight}px;
  right: 0;
  padding: 60px 40px 40px 40px;
  width: ${p => p.theme.previewWidth};
  height: calc(100% - ${p => p.theme.navbarHeight}px);
  background-color: ${p => p.theme.colors.accentBackground};
`;

const PreviewPane = styled.div`
  max-width: 410px;
  max-height: 667px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: ${p => p.theme.colors.background};
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.theme.shadows.standard};
`;

const ShowPreviewLink = styled.div`
  position: fixed;
  top: calc(60px + ${p => p.theme.navbarHeight}px);
  right: 35px;
`;

export default function LivePreview({ study, activeQuestion, setActiveQuestion, showPreview, setShowPreview }) {
  let currentPage = activeQuestion;

  // They might delete the last question
  if (currentPage < 0 || currentPage >= study.questions.length) {
    currentPage = null;
  }

  // TODO: this is a bad way to figure out if it's a "new" study or not
  if (currentPage === null && study.questions.length !== 0) {
    currentPage = 0;
  }

  return (
    <AnimatePresence>
      { showPreview ? (
        <PreviewContainer
          key="preview"
          className="hide-mobile"
          transition={{ duration: 0.5 }}
          initial={{ translateX: 450, opacity: 0.8 }}
          animate={{ translateX: 0, opacity: 1 }}
          exit={{ translateX: 450, opacity: 0.8 }}
        >
          <Stack fullHeight>
            <Inline fullWidth justify="flex-end">
              <FunctionLink
                noUnderline
                onClick={() => setShowPreview(false)}
              >
                <Inline align="center" spacing="xsmall">
                  <span>Hide preview</span>
                  <FastForwardSvg width={16} height={16} />
                </Inline>
              </FunctionLink>
            </Inline>

            <PreviewPane>
              {currentPage !== null ? (
                <ParticipantView
                  study={study}
                  preview
                  showPreviewBanner={false}
                  forceMobile
                  currentPage={currentPage}
                  changePage={p => setActiveQuestion(p)}
                />
              ) : (
                <PreviewPlaceholderSvg style={{ width: '100%', height: '99%' }} />
              )}
            </PreviewPane>
          </Stack>
        </PreviewContainer>
      ) : (
        <NotMobile>
          <ShowPreviewLink>
            <FunctionLink
              noUnderline
              onClick={() => setShowPreview(true)}
            >
              <Inline align="center" spacing="xsmall">
                <FastForwardSvg width={16} height={16} style={{ transform: 'rotate(180deg)' }} />
                <DesktopOnly>Show preview</DesktopOnly>
              </Inline>
            </FunctionLink>
          </ShowPreviewLink>
        </NotMobile>
      )}
    </AnimatePresence>
  );
}
