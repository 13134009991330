import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const ButtonBase = styled(motion.button)`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  white-space: nowrap;
  padding: 8.5px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  border-radius: ${p => p.theme.borderRadius};
  line-height: normal;
  background-color: transparent;

  transition: 0.2s background-color;

  &[disabled] {
    pointer-events: none;
  }

  &:focus:not(&:hover) {
    outline: none;
    box-shadow: 0 0 0 2px ${p => p.theme.colors.highlight};
  }

  > svg {
    margin-right: 10px;
  }
`;

const PrimaryButton = styled(ButtonBase)`
  color: ${p => p.theme.colors.textInvert};
  background-color: ${p => p.theme.colors.brand};

  &:hover {
    color: ${p => p.theme.colors.brand};
    background-color: ${p => p.theme.colors.brand}33;
  }

  &:active {
    color: ${p => p.theme.colors.tertiary};
    background-color: ${p => p.theme.colors.tertiary}33;
  }

  &[disabled] {
    color: ${p => p.theme.colors.textInvert};
    background-color: ${p => p.theme.colors.dark};
  }
`;

const SecondaryButton = styled(ButtonBase)`
  color: ${p => p.theme.colors.brand};
  background-color: ${p => p.theme.colors.brandLight};

  &:hover {
    color: ${p => p.theme.colors.textInvert};
    background-color: ${p => p.theme.colors.brand};
  }

  &:active {
    color: ${p => p.theme.colors.textInvert};
    background-color: ${p => p.theme.colors.tertiary};
  }

  &[disabled] {
    color: ${p => p.theme.colors.textInvert};
    background-color: ${p => p.theme.colors.dark};
  }
`;

const TextButton = styled(ButtonBase)`
  color: ${p => p.theme.colors.brand};

  &:hover {
    color: ${p => p.theme.colors.tertiary};
  }

  &:active {
    color: ${p => p.theme.colors.tertiary};
  }

  &[disabled] {
    color: ${p => p.theme.colors.dark};
  }
`;

const VARIANT_MAP = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  text: TextButton,
};

export interface Props {
  variant?: 'primary' | 'secondary' | 'text'
  className?: string
  loading?: boolean
  disabled?: boolean
  children: React.ReactNode
  onClick: () => void
};

export default function Button({
  variant = 'primary',
  children,
  onClick,
  className,
  disabled = false,
  loading = false,
  ...props
}: Props) {
  const ButtonElement = VARIANT_MAP[variant];

  return (
    <ButtonElement
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      {...props}
    >
      {loading ? 'Working' : children}
    </ButtonElement>
  );
}
