import { useContext, useState }from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Loader from 'components/Loader';
import NotificationContext from 'NotificationContext';
import Text from 'atoms/Text';
import FunctionLink from 'atoms/FunctionLink';

const slideIn = keyframes`
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1
  }
`;

const ToastWrapper = styled.div`
  max-width: 80ch;
  width: fit-content;
  display: grid;
  grid-template-columns: ${p => p.$hasCta ? '1fr 110px' : '1fr'};
  grid-gap: 20px;
  margin: 0 auto 10px auto;
  animation: ${slideIn} 0.2s ease-in-out;
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px 10px 20px;
  background-color: ${p => p.theme.colors.background};
  box-shadow: ${p => p.theme.shadows.standard};
  border-radius: ${p => p.theme.borderRadius};
  pointer-events: initial;
`;

const ToastContent = styled.div`
  display: grid;
  grid-template-columns: ${p => p.$hasCta ? '1fr' : '60px 1fr'};
  grid-gap: 30px;
  align-items: center;
  padding: 12px 20px 10px 20px;
  background-color: ${p => p.theme.colors.background};
  box-shadow: ${p => p.theme.shadows.standard};
  border-radius: ${p => p.theme.borderRadius};
`;

const ToastMessage = styled.div`
  font-weight: 500;
`;

export default function Toast({ id, message, cta }) {
  // TODO: is this leaking internals? probably
  const dispatch = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);

  return (
    <ToastWrapper $hasCta={cta}>
      <ToastContent $hasCta={cta}>
        {!cta && <Loader scale={0.4} />}
        <ToastMessage>{message}</ToastMessage>
      </ToastContent>
      {cta && (
        <CtaWrapper>
          {loading ? (
            <Text>Working</Text>
          ) : (
            <FunctionLink onClick={() => {
              setLoading(true);

              cta.func().then(() => {
                dispatch({ type: 'clear', id });
              });
            }}>
              {cta.label}
            </FunctionLink>
          )}
        </CtaWrapper>
      )}
    </ToastWrapper>
  );
}
