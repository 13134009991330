import { useState, useCallback } from 'react';

export default function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) as T : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = useCallback((value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  }, [key, setStoredValue]);

  // useEffect(() => {
  //   const listener = window.addEventListener('storage', e => {
  //     if (e.key !== key) return;
  //     if (e.oldValue === e.newValue) return;

  //     setStoredValue(e.newValue);
  //   });

  //   return () => window.removeEventListener('storage', listener);
  // }, [key]);

  return [storedValue, setValue] as const;
}
