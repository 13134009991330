import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as HamburgerSvg } from 'images/icons/hamburger.svg';
import { ReactComponent as CancelSvg } from 'images/icons/x.svg';
import Link from 'atoms/Link';
import { Stack } from 'components/atoms/layout';
import useAuth from 'hooks/useAuth';

const HamburgerTrigger = styled.button`
  border: none;
  background-color: inherit;
  z-index: 1;
  cursor: pointer;
`;

const HamburgerWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 40px;
`;

const HamburgerContent = styled.div`
  position: relative;
  max-width: 300px;
  padding: 30px;
  background-color: ${p => p.theme.colors.background};
  border: 2px solid ${p => p.theme.colors.text};
  border-radius: ${p => p.theme.borderRadius};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  color: ${p => p.theme.colors.text};
  border: none;
  background-color: inherit;
  padding: 0;

  > svg {
    width: 19px;
    height: 19px;
  }
`;

const AccentLink = styled(Link)`
  color: ${p => p.theme.colors.brand};

  &:hover, &:active {
    color: ${p => p.theme.colors.text};
  }
`;

export default function HamburgerMenu({ triggerLogin, triggerSignup }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <div>
      <HamburgerTrigger onClick={() => setMenuOpen(o => !o)}>
        <HamburgerSvg />
      </HamburgerTrigger>

      <AnimatePresence>
        {menuOpen && (
          <HamburgerWrapper
            animate={{ opacity: 1, x: 0  }}
            initial={{ opacity: 0, x: -350 }}
            exit={{ opacity: 0, x: 350 }}
          >
            <HamburgerContent>
              <Stack spacing="small">
                <Link nav to="/">Overview</Link>
                <Link nav to="/product">Product</Link>
                <Link nav to="/howitworks">How it works</Link>
                <Link nav to="/pricing">Pricing</Link>

                <div />

                {isLoggedIn ? (
                  <AccentLink to="/studies">My studies</AccentLink>
                ) : (
                  <>
                    <AccentLink as="a" onClick={() => { setMenuOpen(false); triggerLogin(); }}>Log in</AccentLink>
                    <AccentLink as="a" onClick={() => { setMenuOpen(false); triggerSignup(); }}>Sign up</AccentLink>
                  </>
                )}
              </Stack>

              <CloseButton onClick={() => setMenuOpen(false)}>
                <CancelSvg />
              </CloseButton>
            </HamburgerContent>
          </HamburgerWrapper>
        )}
      </AnimatePresence>
    </div>
  );
}
