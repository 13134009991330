import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const BasicStar = styled(motion.circle)`
  cursor: pointer;
`;

export default function Star({ x, y, color, ...otherProps }) {
  return (
    <BasicStar
      {...otherProps}
      cx={x}
      cy={y}
      r={1.6}
      fill={color}
    />
  );
}
