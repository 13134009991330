import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components/macro';
import { Route, Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Helmet from 'react-helmet';
import { ContentWrapper, Content, Inline } from 'components/atoms/layout';
import { Spacer } from 'components/atoms/layout';
import useAuth from 'hooks/useAuth';
import Heading from 'atoms/Heading';
import InPlaceEditPanel from 'components/InPlaceEditPanel';
import Text from 'atoms/Text';
import Button from 'atoms/Button';
import Stack from 'atoms/Stack';
import DeleteForever from 'components/account/DeleteForever';
import { USER_INFO_QUERY, UPDATE_USER_MUTATION, MANAGE_SUBSCRIPTION_MUTATION, USER_FRAGMENT } from 'queries';
import Dashboard from 'components/Dashboard';
import TextInputModal from 'components/TextInputModal';
import Loader from './Loader';
import miniMuscleManImage from 'images/marketing/mini_muscle_man.png';
import apiCall from 'utils/apiCall';
import useNotifications from 'hooks/useNotifications';
import { formatDate } from 'utils';

const Panel = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.theme.shadows.standard};
  padding: 22px 39px 19px 39px;
  background-color: ${p => p.theme.colors.accentBackground};
`;

const ProImage = styled.img`
  position: absolute;
  top: -120px;
  right: 30px;
  height: 120px;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Account() {
  const { id, email, setToken, roles } = useAuth();
  const notify = useNotifications();
  const [showPromoDialog, setShowPromoDialog] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const { data: settings, loading: settingsLoading, error: settingsError } = useQuery(USER_INFO_QUERY);

  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: data => {
      if (data.updateMe.jwt) {
        setToken(data.updateMe.jwt);
      }
    },
    onError: error => {
      if (error.graphQLErrors[0].message === 'Invalid promo code') {
        notify({ message: 'That promo code is not valid.' });
      } else {
        notify({ message: 'An error occurred' });
      }
    },
    update(cache, { data: { updateMe: { user } } }) {
      cache.writeFragment({
        data: user,
        fragment: USER_FRAGMENT,
      });
    },
  });

  const [manageSubscription, { loading: manageLoading }] = useMutation(MANAGE_SUBSCRIPTION_MUTATION, {
    onCompleted: data => {
      if (data.manageSubscription) {
        window.location = data.manageSubscription;
      }
    },
  })

  const handleSignup = async () => {
    setUpgradeLoading(true);
    const stripe = await stripePromise;
    const response = await apiCall('checkout', {
      method: 'POST',
      body: JSON.stringify({ userId: id, email }),
    });
    const session = await response.json();
    const result = await stripe.redirectToCheckout({ sessionId: session.id });

    if (result.error) {
      // TODO: show this error somehow
      console.error(result.error);
      setUpgradeLoading(false);
    }
  }

  const planType = settings?.me.plan.type;

  return (
    <Dashboard>
      <Helmet>
        <title>account</title>
      </Helmet>
      <ContentWrapper>
        <Content>
          <Stack spacing="medium">
            <Heading>Account details</Heading>

            <Spacer size="large"/>

            {roles.includes('pro_plan_active') ? (
              <Panel width={400}>
                <ProImage
                  src={miniMuscleManImage}
                  alt="Handsome man"
                />

                <Inline fullWidth justify="space-between">
                  <Stack spacing="small">
                    <Heading level={3}>
                      You are on the Pro plan
                    </Heading>
                    <Text>
                      Go forth and conquer research. <br />
                      {settings?.me.plan.subscribedUntil ? (
                        <>You are subscribed until <strong>{formatDate(settings.me.plan.subscribedUntil)}</strong>.</>
                      ) : null}
                    </Text>
                  </Stack>

                  {(!planType || planType === 'paidMonthly') ? (
                    <Button disabled={manageLoading} variant="secondary" onClick={manageSubscription}>Manage</Button>
                  ) : null}
                </Inline>
              </Panel>
            ) : (
              <Panel width={400}>
                <ProImage src={miniMuscleManImage} alt="Handsome man"/>

                <Inline fullWidth justify="space-between">
                  <Stack spacing="small">
                    <Heading level={3}>
                      You are on the free plan
                    </Heading>
                    <Text>
                      Upgrade to pro to have unlimited studies, participants and help support us roll out new features.
                    </Text>
                  </Stack>

                  <Inline spacing="xsmall">
                    <Button variant="secondary" onClick={() => setShowPromoDialog(true)}>Enter code</Button>
                    <Button onClick={handleSignup} loading={upgradeLoading}>Upgrade</Button>
                  </Inline>
                </Inline>
              </Panel>
            )}


            {/* <Panel>
              <InPlaceEditPanel
                title="Account name"
                persistedValue={name ?? ''}
                handleSave={name => {
                  return updateUser({ variables: {
                    input: { name }
                  }});
                }}
              />
            </Panel> */}

            <Panel width={400}>
              <InPlaceEditPanel
                title="Email address"
                persistedValue={email}
                handleSave={email => {
                  return updateUser({ variables: {
                    input: { email }
                  }});
                }}
              />
            </Panel>

            <Panel width={400}>
              {settingsLoading && <Loader scale={0.5} />}
              {!settingsLoading && !settingsError && (
                <Inline fullWidth spacing="xlarge" justify="space-between">
                  <Stack spacing="small">
                    <Heading level={3}>Marketing communications</Heading>
                    <Text size="small">
                      You are currently {settings.me.settings.marketingOptIn ? '' : 'not'} receiving marketing communcations.
                    </Text>
                  </Stack>

                  <Button
                    variant="secondary"
                    loading={updateLoading && !showPromoDialog}
                    onClick={() => {
                    updateUser({
                      variables: {
                        input: {
                          settings: {
                            marketingOptIn: !settings.me.settings.marketingOptIn,
                          }
                        }
                      }
                    });
                  }}>
                    {settings.me.settings.marketingOptIn ? 'Opt out': 'Opt in'}
                  </Button>
                </Inline>
              )}
            </Panel>

            <Panel width={400}>
              <Inline fullWidth justify="space-between">
                <Stack>
                  <Heading level={3}>Delete account</Heading>
                  <Text size="small">
                    If you do this, all your personas and the data they collected get removed from our system—forever.
                  </Text>
                </Stack>

                <Button variant="secondary" as={Link} to="/account/delete">Delete</Button>
              </Inline>
            </Panel>
          </Stack>

          <Route path="/account/delete">
            <DeleteForever />
          </Route>
        </Content>
      </ContentWrapper>

      {showPromoDialog ? (
        <TextInputModal
          handleClose={() => setShowPromoDialog(false)}
          handleAction={promoCode => {
            updateUser({
              variables: {
                input: { promoCode }
              }
            }).then(() => {
              setShowPromoDialog(false);
            });
          }}
          fieldLabel="Promo code"
          loading={updateLoading && showPromoDialog}
          confirmLabel="Submit"
          description="If you have a promo code for Akin Pro, you can enter it here."
        />
      ) : null}
    </Dashboard>
  );
}
