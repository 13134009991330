import React, { useContext, useEffect, useRef, useState } from 'react';
import StudyContext from 'studyContext';
import Dropdown from 'components/Dropdown';
import styled from 'styled-components/macro';
import { BorderlessInput } from 'components/atoms/inputs';
import IconButton from 'atoms/IconButton';
import FunctionLink from 'atoms/FunctionLink';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as RadioIcon } from 'images/studies/radio.svg';
import { ReactComponent as CheckIcon } from 'images/studies/check.svg';

// TODO: fix this weird stuff
import { ReactComponent as Freq0 } from 'images/studies/freq-0.svg';
import { ReactComponent as Freq1 } from 'images/studies/freq-1.svg';
import { ReactComponent as Freq2 } from 'images/studies/freq-2.svg';
import { ReactComponent as Freq3 } from 'images/studies/freq-3.svg';
import { ReactComponent as Freq4 } from 'images/studies/freq-4.svg';
import { ReactComponent as Freq5 } from 'images/studies/freq-5.svg';
import { ReactComponent as Freq6 } from 'images/studies/freq-6.svg';

const FREQ_ICONS = [Freq0, Freq1, Freq2, Freq3, Freq4, Freq5, Freq6];
const QUESTION_TYPES_THAT_CAN_ALLOW_OTHER = ['checkbox', 'multi'] // Probably somewhere better to put this

const OptionList = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${p => ([
      p.fixedOptions ? '50px' : '20px',
      '1fr',
      p.showExclude ? '74px' : '',
    ].join(' ')
  )};
  width: 100%;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconCell = styled(Cell)`
  color: ${p => p.active ? p.theme.colors.text : '#C4C4C4'};
`;

const RemoveButton = styled(IconButton).attrs({
  icon: 'x',
})`
  position: absolute;
  right: 3px;
  top: 0;
`;

const OtherLabel = styled.div`
  position: relative;
  padding: 4px 10px;
  color: #757575; /* matches chrome default placeholder */
`;

function getOptionIcon(displayType, optionIndex) {
  if (displayType === 'checkbox') {
    return CheckIcon;
  } else if (displayType === 'frequency') {
    return FREQ_ICONS[optionIndex];
  } else {
    return RadioIcon;
  }
}
export default function OptionInput({ id, type, displayType, questionNumber, fixedOptions, allowOther, reverseOptions, showExcludeToggles, options }) {
  const { dispatch, setActiveQuestion } = useContext(StudyContext);
  const [focusedOption, setFocusedOption] = useState(null);
  const optionsRef = useRef();

  const addOption = () => {
    dispatch({ type: 'addOption', id, value: '' });
  };

  const setOther = (value) => {
    dispatch({ type: 'changeQuestionAllowOther', id, value });
  }

  // We need to focus new options when they are added
  const numOptions = options?.length || 0;
  const [optionCount, setOptionCount] = useState(numOptions);
  useEffect(() => {
    if (numOptions > optionCount) {
      const inputs = optionsRef.current.querySelectorAll('input:last-of-type');
      inputs[inputs.length - 1].focus();
    }

    setOptionCount(numOptions);
  }, [optionCount, setOptionCount, numOptions]);

  // Frequency types (and some others) get displayed backwards
  let sortedOptions = (options || []).map((o, i) => ({ ...o, optionIndex: i }));
  if (reverseOptions) { sortedOptions.reverse(); }

  const handleEnter = i => {
    if (i === numOptions-1) {
      addOption()
    }

    // TODO: If enter on non-last option, should we focus the next option input?
  }

  const canHaveOther = !allowOther && QUESTION_TYPES_THAT_CAN_ALLOW_OTHER.includes(type);
  const OtherOptionIcon = getOptionIcon(displayType);

  return (
    <OptionList ref={optionsRef} showExclude={showExcludeToggles} fixedOptions={fixedOptions}>
      {sortedOptions.map(({ label, exclude, optionIndex }) => {
        const OptionIcon = getOptionIcon(displayType, optionIndex);

        // You can't delete the last two options
        const canDelete = !fixedOptions && sortedOptions.length > 2;

        return (
          <React.Fragment key={optionIndex}>
            <IconCell active={focusedOption === optionIndex} >
              <OptionIcon />
            </IconCell>

            <BorderlessInput
              value={label}
              placeholder={`${type === 'task_type' ? 'Task' : 'Option'} ${optionIndex + 1}`}
              onFocus={e => { setFocusedOption(optionIndex); setActiveQuestion(questionNumber - 1); }}
              onBlur={e => setFocusedOption(null)}
              onKeyUp={e => { if (e.key === 'Enter') { handleEnter(optionIndex); }}}
              onChange={e => dispatch({ type: 'changeOption', id, optionIndex, value: e.target.value })}
            >
              {canDelete ? (
                <RemoveButton onClick={e => dispatch({ type: 'removeOption', id, optionIndex })} />
              ) : null}
            </BorderlessInput>
            {showExcludeToggles ? (
              <Dropdown
                linkText={() => exclude ? 'Exclude' : 'Include'}
                items={[
                  ['Include', () => dispatch({ type: 'changeOptionExclude', id, optionIndex, value: false })],
                  ['Exclude', () => dispatch({ type: 'changeOptionExclude', id, optionIndex, value: true })],
                ]}
              />
            ) : null}
          </React.Fragment>
        );
      })}

      {allowOther ? (
        <>
          <IconCell>
            <OtherOptionIcon />
          </IconCell>

          <OtherLabel>
            Other
            <RemoveButton onClick={() => setOther(false)} />
          </OtherLabel>
        </>
      ) : null}
      { fixedOptions ? null : (
        <>
          <PlusIcon />

          <div>
            <FunctionLink onClick={addOption}>
              Add option
            </FunctionLink>

            {canHaveOther ? (
              <>
                {' '}/{' '}

                <FunctionLink onClick={() => setOther(true)}>
                  add "other"
                </FunctionLink>
              </>
            ) : null}
          </div>
        </>
      )}
    </OptionList>
  );
}
