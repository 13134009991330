import ResultsContext from './ResultsContext';
import { useQuery, useMutation } from '@apollo/client';
import { STUDY_RESULTS_QUERY, UPDATE_ACTIVE_STUDY_MUTATION } from 'queries';
import ErrorPage from 'components/ErrorPage';
import Loader from 'components/Loader';

export default function ResultsProvider({ studyId, children }) {
  const { data, loading, error, refetch } = useQuery(STUDY_RESULTS_QUERY, {
    variables: { id: studyId }
  });

  const [updateStudy, { loading: updateLoading }] = useMutation(UPDATE_ACTIVE_STUDY_MUTATION);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <ResultsContext.Provider value={{ study: data.study, refetch, updateStudy, updateLoading }}>
      {children}
    </ResultsContext.Provider>
  );
}
