import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface PanelWrapperProps {
  width?: number
  flat?: boolean
  color: string
}

const PanelWrapper = styled.div<PanelWrapperProps>`
  position: relative;
  ${p => `width: ${p.width}px;`}
  padding: 24px;
  background-color: ${p => p.theme.colors[p.color] };
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.flat ? p.theme.shadows.standard : p.theme.shadows.large};
  pointer-events: initial; // TODO: is this still necessary?
`;

export interface Props {
  width?: number
  children: ReactNode
  flat?: boolean
  color?: string
}

export default function Panel({ width, flat, children, color = 'accentBackground' }: Props) {
  return (
    <PanelWrapper width={width} flat={flat} color={color}>
      {children}
    </PanelWrapper>
  );
}
