import styled from 'styled-components/macro';
import { REDACTED_EMAIL_STRING } from '../constants';
import Text from 'atoms/Text';

const EmailWrapper = styled(Text)`
  ${p => p.redacted ? 'filter: blur(3px);' : null}
`;

export default function ParticipantEmail({ children }) {
  const redacted = children === REDACTED_EMAIL_STRING;

  return (
    <EmailWrapper size="small" strong redacted={redacted}>
      {children}
    </EmailWrapper>
  );
}
