import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as CheckedSvg } from 'images/checkbox-check.svg';
import { ReactComponent as UncheckedSvg } from 'images/checkbox-base.svg';
import { ReactComponent as FocusSvg } from 'images/checkbox-focus.svg';

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const checkAnimation = keyframes`
  0% { transform: scale(0); }
  100% { transform: scale(1); }
`;

const CheckboxInput = styled.input`
  position:absolute;
  clip: rect(0,0,0,0);
  clip: rect(0 0 0 0);
`;

const BoxWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const Base = styled(UncheckedSvg)`
  width: 24px;
  height: 24px;

`;

const Check = styled(CheckedSvg)`
  width: 22px;
  height: 22px;
  position: absolute;
  left: 1px;
  top: 3px;
  display: none;

  ${CheckboxInput}:checked ~ ${BoxWrapper} > & {
    display: block;
  }

  animation: ${checkAnimation} 0.1s ease-in-out;
`;

const Focus = styled(FocusSvg)`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;

  ${CheckboxInput}:focus ~ ${BoxWrapper} > & {
    display: block;
  }
`;

export default function Checkbox({ children, checked, onChange }) {
  const handleChange = (e) => onChange(e.target.checked);

  return (
    <CheckboxLabel>
      <CheckboxInput type="checkbox" checked={checked} onChange={handleChange} />
      <BoxWrapper>
        <Base />
        <Focus />
        <Check />
      </BoxWrapper>
      {children}
    </CheckboxLabel>
  );
};
