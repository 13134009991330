import styled from 'styled-components/macro';
import TextArea from 'atoms/TextArea';
import Text from 'atoms/Text';

const ValidationWrapper = styled.div`
height: 72px; /* TODO: figure out why it sits at +4px without this */
  padding-left: 10px;
  margin-left: -16px;
  border-left: 6px solid ${p => p.error ? p.theme.colors.error : 'transparent'};
  transition: 0.4s border-color;
`;

export default function EmailQuestion({ error, answer, setAnswer }) {
  return (
    <>
      {error && <Text color="error">You must enter a valid email address</Text>}

      <ValidationWrapper error={error}>
        <TextArea
          autoFocus
          rows={2}
          value={answer || ''}
          onChange={v => setAnswer(v)}
        />
      </ValidationWrapper>
    </>
  );
};
