import { useState } from 'react';
import { Fragment } from 'react';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import CodeBlock from 'atoms/CodeBlock';
import { Stack} from 'components/atoms/layout';
import { format, parseISO } from 'date-fns';
import useWebsocket from 'hooks/useWebsocket';

function formatDate(isoString) {
  if (!isoString) { return ''; }

  return format(parseISO(isoString), 'dd/MM/yy');
}

export default function AdminEvents() {
  const [events, setEvents] = useState([]);
  useWebsocket('admin', (eventType, event) => {
    if (eventType === 'event') {
      setEvents(events => [event, ...events]);
    }
  });

  return (
    <Stack>
      <Heading>Live events</Heading>

      {events && (
        <Stack>
          <Grid rowTemplate={['100px', '70px', '150px', '1fr']} >
            <Grid.Header>Event Type</Grid.Header>
            <Grid.Header>Timestamp</Grid.Header>
            <Grid.Header>User Id</Grid.Header>
            <Grid.Header>Other data</Grid.Header>
            <Grid.Line />

            {events.map(e => (
              <Fragment key={e.id}>
                <Grid.Cell>{e.type}</Grid.Cell>
                <Grid.Cell>{formatDate(e.createdAt)}</Grid.Cell>
                <Grid.Cell>{e.userId}</Grid.Cell>
                <Grid.Cell>
                  <CodeBlock>
                    {JSON.stringify(e.data, null, 2)}
                  </CodeBlock>
                </Grid.Cell>
              </Fragment>
            ))}
          </Grid>
        </Stack>
      )}
    </Stack>
  );
}
