import { useState } from 'react';
import Modal from 'components/Modal';
import ValidatedField from 'components/ValidatedField';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { Inline, Stack } from 'components/atoms/layout';
import Button from 'atoms/Button';
import { BorderlessInput } from 'components/atoms/inputs';

export default function TextInputModal({ defaultValue, handleAction, handleClose, fieldLabel, confirmLabel = 'Update', title, description, loading, validator}) {
  const [value, setValue] = useState(defaultValue || '');
  const [error, setError] = useState(null);

  const handleSubmit = value => {
    try {
      handleAction(value);
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <Modal handleClose={handleClose}>
      <Stack spacing="medium">
        {title && <Heading>{title}</Heading>}
        {description && <Text size="body">{description}</Text>}

        <ValidatedField label={fieldLabel} error={error}>
          <BorderlessInput
            autoFocus
            value={value}
            onChange={e => setValue(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') { handleSubmit(value) }
            }}
          />
        </ValidatedField>

        <Inline spacing="small">
          <Button loading={loading} onClick={() => handleSubmit(value)}>
            {confirmLabel}
          </Button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </Inline>
      </Stack>
    </Modal>
  );
}
