import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Button from 'atoms/Button';
import Heading from 'atoms/Heading';
import Text from 'atoms/Text';
import { Stack, Inline } from 'components/atoms/layout';
import { BorderlessInput } from 'components/atoms/inputs';

export default function InPlaceEditPanel({ title, persistedValue, handleSave }) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(persistedValue);
  const [loading, setLoading] = useState(false);

  const onSave = () => {
    setLoading(true);
    handleSave(value).then(() => {
      setEditing(false);
      setLoading(false);
    });
  }

  // TODO it fades out but not in!
  // TODO: enter should submit
  // TODO: loading state?

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={`content_${editing}`}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Inline fullWidth spacing="xlarge" justify="space-between">
          { editing ? (
            <>
              <BorderlessInput
                autoFocus
                fullWidth
                value={value}
                onChange={e => setValue(e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') { onSave(); }
                }}
              />

              <Inline spacing="small">
                <Button loading={loading} onClick={onSave}>
                  Save
                </Button>
                <Button disabled={loading} variant="secondary" onClick={() => setEditing(false)}>
                  Cancel
                </Button>
              </Inline>
            </>
          ) : (
            <>
              <Stack spacing="small">
                <Heading level={3}>{ title }</Heading>
                <Text size="small">{ persistedValue }</Text>
              </Stack>

              <Button variant="secondary" onClick={() => setEditing(true)}>
                Update
              </Button>
            </>
          )}
        </Inline>
      </motion.div>
    </AnimatePresence>
  );
}
