import { useMemo, useEffect, useReducer, useState } from 'react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import isPlainObject from 'lodash.isplainobject';
import useNotifications from 'hooks/useNotifications';
import Study from './Study';
import StudyContext from 'studyContext';
import studyReducer from 'studyReducer';
import ErrorPage from 'components/ErrorPage';
import { STUDY_QUERY, UPDATE_STUDY_MUTATION } from 'queries';
import useLocalStorage from 'hooks/useLocalStorage';
import FullScreenLoader from 'components/FullScreenLoader';

const STUDY_PERSIST_DELAY = 3000; //ms

function cloneWithoutTypename(obj) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  let result = {};
  for (let key in obj) {
    if (key === '__typename') {
      continue;
    }
    let value = obj[key];
    if (isPlainObject(value)) {
      result[key] = cloneWithoutTypename(value);
    } else if (Array.isArray(value)) {
      result[key] = value.map(cloneWithoutTypename);
    } else {
      result[key] = value;
    }
  }
  return result;
}

export default function StudyLoader({ studyId, children }) {
  const { studyId: studyIdParam } = useParams();
  const notify = useNotifications();

  const [showPreview, setShowPreview] = useLocalStorage('showPreview', true);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [serverState, setServerState] = useState(null);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const [study, dispatch] = useReducer(studyReducer, {});
  const { data, loading, networkStatus, error, refetch } = useQuery(STUDY_QUERY, {
    variables: { id: studyId ?? studyIdParam },
    notifyOnNetworkStatusChange: true,
    pollInterval: (study.state === 'uploadPending') ? 3000 : 0,
    onCompleted: (data) => {
      const study = cloneWithoutTypename(data.study);

      setServerState(study);
      dispatch({ type: 'initialize', study });
    },
  });

  const [updateStudy, { loading: updateLoading }] = useMutation(UPDATE_STUDY_MUTATION, {
    onCompleted: (data) => {
      const study = cloneWithoutTypename(data.updateStudy);
      setServerState(study);
    },
    onError: (e) => {
      notify({ message: e.message || 'Unable to save, try refreshing the page' });
    },
  });

  const debouncedUpdate = useMemo(() => {
    return debounce(newData => {
      if (!newData.id) { return; }
      if (!serverState.id) { return; }

      const { name, questions, options } = newData;
      const { name: oldName, questions: oldQuestions, options: oldOptions } = serverState;

      if (isEqual({ name, questions, options }, cloneWithoutTypename({ name: oldName, questions: oldQuestions, options: oldOptions }))) { return; }

      // if (!isEqual({ name }, cloneWithoutTypename({ name: oldName }))) { console.log('name', name, cloneWithoutTypename(oldName)) }
      // if (!isEqual({ options }, cloneWithoutTypename({ options: oldOptions }))) { console.log('options', options, cloneWithoutTypename(oldOptions)) }
      // if (!isEqual({ questions }, cloneWithoutTypename({ questions: oldQuestions }))) {
      //   console.log('questions', questions, cloneWithoutTypename(oldQuestions));

      //   questions.forEach((a, i) => {
      //     const b = cloneWithoutTypename(oldQuestions[i]);

      //     if (!isEqual(a, b)) { console.log(a, b); }
      //   })
      // }

      updateStudy({
        variables: {
          id: serverState.id,
          input: { name, questions, options }
        }
      });
    }, STUDY_PERSIST_DELAY);
  }, [updateStudy, serverState]);

  useEffect(() => {
    if (!data) return;

    debouncedUpdate(study);
  }, [debouncedUpdate, data, study]);

  if (error) return <ErrorPage />;
  if (!loading && (!data.study || !data.study.myAccess)) return <ErrorPage unrecoverable message="That study does not exist or you don't have access to view it." />;

  const showLoader = loading && networkStatus !== NetworkStatus.refetch;
  const dirty = !isEqual(study, serverState);

  // console.log(dirty ? 'dirty' : 'clean', study, serverState);

  return (
    <StudyContext.Provider value={{ study, showPreview, activeQuestion, setActiveQuestion, dispatch, setShowPublishModal, refetch, updateStudy, updateLoading, dirty }}>
      {!showLoader && (data?.study.id === study.id) && (
        <>
          {children ?? <Study
            study={study}
            activeQuestion={activeQuestion}
            setActiveQuestion={setActiveQuestion}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            showPublishModal={showPublishModal}
            setShowPublishModal={setShowPublishModal}
          />}
        </>
      )}

      <FullScreenLoader show={showLoader} />
    </StudyContext.Provider>
  );
}
