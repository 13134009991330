import { AuthContext } from './AuthContext';
import useLocalStorage from 'hooks/useLocalStorage';

function AuthProvider({ children }) {
  // TODO: bad to keep JWT in localstorage
  const [token, setToken] = useLocalStorage('jwt');

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
