import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router';
import { useMutation, gql } from '@apollo/client';
import useNotifications from 'hooks/useNotifications';
import useAuth from 'hooks/useAuth';
import Dropdown from 'components/Dropdown';
import { Stack, Inline } from 'components/atoms/layout';
import TextInputModal from 'components/TextInputModal';
import { CREATE_STUDY_MUTATION, CHANGE_USER_ACCESS_MUTATION } from 'queries';
import Loader from 'components/Loader';
import StaticGalaxy from 'components/study/results/StaticGalaxy';

const GalaxyBackground = styled(StaticGalaxy)`
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
`;

const Tile = styled(motion.div)`
  position: relative;
  border: none;
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.theme.shadows.large};
  background-color: ${p => p.theme.colors.accentBackground};
  text-align: left;
  ${p => p.active ? 'cursor: pointer;' : ''}

  &:active {
    background-color: ${p => p.theme.colors.dark};
  }

  &:focus {
    outline: none;
  }
`;

const StudyText = styled.div`
  font-size: 14px;
`;

const ControlArea = styled.div`
  padding: 14px 14px 10px 14px;
`;

const CaptionArea = styled.div`
  padding: 14px 14px 10px 14px;
  background-color: rgba(255, 255, 255, 60%);
  z-index: 1
`;

const StudyTitle = styled.div`
  max-height: 38px;
  overflow: hidden;

  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  overflow: hidden;
  word-break: break-word;
`;

const RENAME_STUDY_MUTATION = gql`
  mutation ($id: ID!, $name: String!) {
    renameStudy(id: $id, name: $name) {
      id
      name
    }
  }
`

const DELETE_STUDY_MUTATION = gql`
  mutation ($id: ID!) {
    deleteStudy(id: $id)
  }
`;

const tileVariants = {
  hidden: {
    opacity: 0,
    translateY: 50
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
  hover: {
    scale: 1.02,
  },
};

export default function StudyTile({ studyId, access, state, name, latestAnalysis, template, responseCount }) {
  // TODO: confirmation
  // TODO: stupid to have this in every tile, centralize it?
  const history = useHistory();
  const { id: userId } = useAuth();

  const notify = useNotifications();

  // Centralize this too?
  const [showRename, setShowRename] = useState(false);

  const [renameStudyMutation, { loading: renameLoading }] = useMutation(RENAME_STUDY_MUTATION);
  const [cloneStudyMutation, { loading: cloneStudyLoading }] = useMutation(CREATE_STUDY_MUTATION, {
    refetchQueries: ['dashboardStudies'],
    awaitRefetchQueries: true,
  })
  const [deleteStudyMutation, { loading: deleteLoading }] = useMutation(
    DELETE_STUDY_MUTATION,
    {
      refetchQueries: ['dashboardStudies'],
      awaitRefetchQueries: true, // So loading indicator shows until the refresh completes
    }
  );

  const [revokeAcessMutation, { loading: revokeLoading }] = useMutation(
    CHANGE_USER_ACCESS_MUTATION,
    {
      refetchQueries: ['dashboardStudies'],
      awaitRefetchQueries: true,
    },
  );

  const loading = renameLoading || cloneStudyLoading || deleteLoading || revokeLoading;

  const cloneStudy = async () => {
    cloneStudyMutation({ // FIXME: not changing template when cloning should be enforced, not sent again
      variables: { name: `Copy of ${name}`, template: template, cloneStudyId: studyId }
    }).catch(e => {
      notify({ message: `Error: ${e.message}`});
    });
  }

  const renameStudy = async name => {
    renameStudyMutation({ variables: { id: studyId, name }});
  }

  const deleteStudy = async () => {
    deleteStudyMutation({ variables: { id: studyId }});
  }

  const revokeMyAccess = async () => {
    revokeAcessMutation({ variables: { studyId, userId, access: null }});
  }

  const tileActive = true;
  const defaultSubpage = responseCount === 0 ? 'survey' : 'results';

  let statusMessage;

  if (state === 'uploadTimeout') {
    statusMessage = 'Error!';
  } else if (state === 'uploadPending') {
    statusMessage = 'Processing...';
  } else if (state === 'draft') {
    statusMessage = 'Draft';
  } else {
    statusMessage = `${responseCount === 0 ? 'No' : responseCount} response${responseCount === 1 ? '' : 's'}`;
  }

  return (
    <Tile
      active={tileActive}
      positionTransition
      variants={tileVariants}
      whileHover={tileActive ? "hover" : null}
      whileTap={tileActive ? { scale: 0.99 } : null}
      onClick={() => {
        if (!tileActive) return;

        history.push(`/study/${studyId}/${defaultSubpage}`)
      }}
    >
      {loading ? (
        <Loader scale={0.5} />
      ) : (
        <>
          {latestAnalysis?.participants ? (
            <GalaxyBackground
              participants={latestAnalysis.participants}
              personas={latestAnalysis.personas}
            />
            ) : (
              <GalaxyBackground fakeData />
            )
          }
          <Stack fullHeight justify="space-between" spacing="none">
            <ControlArea>
              <Inline fullWidth justify="flex-end">
                <Dropdown
                  fixedWidth={120}
                  xOffset={-24}
                  direction="up"
                  items={access === 'full' ? [
                    ['Rename', () => setShowRename(true), 'pencil'],
                    tileActive && ['Duplicate', cloneStudy, 'clone'],
                    ['Delete', deleteStudy, 'trash'],
                  ] : [
                    ['Remove', revokeMyAccess, 'trash'],
                  ]}
                />
              </Inline>
            </ControlArea>

            <CaptionArea>
              <StudyTitle>{name}</StudyTitle>

              <StudyText>
                {statusMessage}
              </StudyText>
            </CaptionArea>
          </Stack>
        </>
      )}

      <AnimatePresence>
        {showRename && <TextInputModal
          title="Rename survey"
          description="Change your survey name"
          defaultValue={name}
          handleClose={() => setShowRename(false)}
          handleAction={newName => { setShowRename(false); renameStudy(newName) }}
        />}
      </AnimatePresence>
    </Tile>
  );
}
