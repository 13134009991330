import { useState, Fragment } from 'react';
import styled from 'styled-components/macro';
import Grid from 'atoms/Grid';
import ParticipantEmail from 'components/ParticipantEmail';
import HighlightableAnswer from './HighlightableAnswer';
import Text from 'atoms/Text';
import IconButton from 'atoms/IconButton';
import HighlightEditor from 'components/study/results/HighlightEditor';
import { highlightsToShow } from 'utils';

const Wrapper = styled.div`
  position: relative;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: -30px;
  right: 10px;
`;

const BlurGrid = styled.div`
  position: absolute;
  left: -6px;
  top: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  backdrop-filter: blur(3px);
`;

const EditorBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-left: 230px; // TODO: very brittle!!
`;

const EditorPanel = styled.div`
  position: relative;
  left: 20px;
  width: fit-content;
  margin: 0 auto;
  transform: translateY(calc(-50% + 15px));
`;

export default function OpenResultsGrid({ answers, editable }) {
  const [editor, setEditor] = useState();
  const completedAnswers = answers.filter(a => a.answer);

  return (
    <Wrapper>
      <Grid rowTemplate={[editable ? '30px' : null, '1fr', '150px', '150px'].filter(Boolean)}>
        {editable ? <Grid.Header>Edit</Grid.Header> : null}
        <Grid.Header>Response</Grid.Header>
        <Grid.Header>Highlights</Grid.Header>
        <Grid.Header>Contact</Grid.Header>
        <Grid.Line />

        {completedAnswers.length === 0 && <p>No answers for this question.</p>}

        {completedAnswers.map(({ answer, questionId, highlights, algoHighlights, removedHighlights, participant }) => {
          return (
            <Fragment key={participant.id}>
              {editable ? (
                <Grid.Cell>
                  <IconButton
                    icon="pencil"
                    onClick={e => {
                      const offset = e.currentTarget.getBoundingClientRect().top;
                      setEditor({ questionId, participant, offset })}
                    }
                  />
                </Grid.Cell>
              ) : null}
              <Grid.Cell>
                <HighlightableAnswer
                  answer={answer}
                  highlights={highlights}
                  algoHighlights={algoHighlights}
                  removedHighlights={removedHighlights}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Text size="small">
                  {highlightsToShow(highlights, algoHighlights, removedHighlights).join(', ')}
                </Text>
              </Grid.Cell>
              <Grid.Cell style={{ wordBreak: 'break-word' }}>
                <ParticipantEmail>
                  {participant?.email}
                </ParticipantEmail>
              </Grid.Cell>
            </Fragment>
          );
        })}
      </Grid>

      {editor ? (
        <>
          <BlurGrid />

          <EditorBackground onClick={() => setEditor(null)}>
            <EditorPanel
              style={{ top: editor.offset }}
              onClick={e => e.stopPropagation()}
            >
              <CloseButtonWrapper>
                <IconButton
                  icon="x"
                  onClick={() => setEditor(null)}
                />
              </CloseButtonWrapper>

              <HighlightEditor
                participant={editor.participant}
                questionId={editor.questionId}
                closeEditor={() => setEditor(null)}
              />
            </EditorPanel>
          </EditorBackground>
        </>
      ) : null}
    </Wrapper>
  );
}
