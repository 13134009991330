import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Helmet from 'react-helmet';
import useAuth from 'hooks/useAuth';
import { ContentWrapper, Inline } from 'components/atoms/layout';
import Clusters from './results/Clusters';
import Persona from './results/Persona';
import RawResults from './results/RawResults';
import CompareQuestions from './results/CompareQuestions';
import PredictHome from './results/predict/PredictHome';
import CreatePrediction from './results/predict/CreatePrediction';
import Prediction from './results/predict/Prediction';
import Participants from './results/Participants';
import Participant from './results/Participant';
import Calibrate from './results/Calibrate';
import Collaborate from './results/Collaborate';
import Reports from './results/Reports';
import ResultsSidebar from './results/ResultsSidebar';
import { MIN_RESPONSES } from '../../constants';
import Instructions from 'components/study/results/Instructions';
import { DemoButton } from 'components/marketing/ResultsDemo';
import Banner from 'components/Banner';
import { ReactComponent as ClusterSvg } from 'images/cluster-map-demo.svg';
import { ReactComponent as InteractSvg } from 'images/icons/interact.svg';
import Text from 'atoms/Text';
import { FREE_PLAN_RESPONSE_LIMIT } from '../../constants';
import useStudyResults from 'hooks/useStudyResults';
import FakeResponseCreator from 'components/FakeResponseCreator';

const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
`;

const Backdrop = styled.div`
  width: 100%;
  display: flex;
  min-height: 0;
  flex-grow: 1;
`;

export default function ResultsPage({ readOnly }) {
  const { id: userId, roles } = useAuth();
  const { colors: { clusters: clusterColors }} = useContext(ThemeContext);

  const {
    study: {
      id,
      userId: studyUserId,
      name,
      questions,
      analysisOptions,
      resultsOptions,
      latestAnalysis: analysis,
      responses,
      permissions,
      myAccess,
    }
  } = useStudyResults();

  const match = useRouteMatch();
  const clusterMatch = useRouteMatch(`${match.path}/clusters`);

  const ignoredParticipantIds = analysisOptions?.ignoredParticipantIds ?? [];
  const nonIgnoredResponses = responses.filter(r => !ignoredParticipantIds.includes(r.id));

  if (!analysis && !responses.length) return (
    <Instructions
      title="Awaiting participants"
      message={`Clusters will start building once at least ${MIN_RESPONSES} people have completed the survey.  In the meantime, you can explore our demo.`}
    >
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <ClusterSvg width={350} height={294} />
      <DemoButton as="a" href="/demo" target="_blank" style={{ position: 'relative', top: -250 }}>
        <Inline spacing="small">
          <InteractSvg width={26} />
          <Text strong size="large">Open demo results</Text>
        </Inline>
      </DemoButton>
    </Instructions>
  );

  const isAdmin = roles.includes('admin');
  const proPlan = roles.includes('pro_plan_active');
  const myStudy = userId === studyUserId;
  const hitFreeLimit = myStudy && !proPlan && !readOnly && responses.length >= FREE_PLAN_RESPONSE_LIMIT;

  const decoratedPersonas = analysis?.personas?.map((p,i) => {
    return {
      ...p,
      color: clusterColors[i],
    }
  });

  const participantsAndResponses = nonIgnoredResponses.map(r => {
    const p = analysis?.participants?.find(np => r.id === np.id);

    return {
      ...p,
      ...r,
      algoHighlights: p?.highlights || [],
    }
  });

  return (
    <ResultsWrapper>
      <Helmet>
        <title>{name}</title>
      </Helmet>

      { hitFreeLimit ? (
        <Banner
          text={`You have reached the free plan limit of ${FREE_PLAN_RESPONSE_LIMIT} participants. Any more costs us to store and compute the data.`}
          linkText="Upgrade to the pro plan"
          linkTo="/account"
        />
      ) : null}

      { isAdmin && !myStudy && !readOnly ? (
        <Banner
          text="You are logged in as an admin and this is not your study.  Don't break anything!"
        />
      ) : null}

      <Backdrop>
        <ResultsSidebar studyId={id} personas={decoratedPersonas} name={name} myAccess={myAccess} readOnly={readOnly} />

        <ContentWrapper noPadding={clusterMatch} style={{ overflowX: 'hidden', flexGrow: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/clusters`}>
              <Clusters
                readOnly={readOnly}
                state={analysis?.state}
                personas={decoratedPersonas}
                responses={responses}
                participants={participantsAndResponses}
                questionImportance={analysis?.questionImportance}
                resultsOptions={resultsOptions}
              />
            </Route>

            <Route exact path={`${match.path}/cluster/:personaId`}>
              <Persona personas={decoratedPersonas} participants={participantsAndResponses} questionImportances={analysis?.questionImportance} />
            </Route>

            <Route exact path={`${match.path}/compare_questions/:q1?/:q2?`}>
              <CompareQuestions questions={questions} personas={decoratedPersonas} participants={analysis?.participants} responses={responses} ignoredParticipantIds={ignoredParticipantIds} />
            </Route>

            <Route exact path={`${match.path}/predict/new`}>
              <CreatePrediction />
            </Route>

            <Route exact path={`${match.path}/predict/:analysisId`}>
              <Prediction />
            </Route>

            <Route exact path={`${match.path}/predict`}>
              <PredictHome />
            </Route>

            <Route exact path={`${match.path}/participant/:participantId`}>
              <Participant readOnly={readOnly} participants={responses} ignoredParticipantIds={ignoredParticipantIds} />
            </Route>

            <Route exact path={`${match.path}/participants`}>
              <Participants personas={decoratedPersonas} participants={analysis?.participants} responses={responses} ignoredParticipantIds={ignoredParticipantIds} />
            </Route>

            <Route exact path={`${match.path}/raw_results`}>
              <RawResults participants={participantsAndResponses} personas={decoratedPersonas}/>
            </Route>

            {myAccess === 'full' ? (
              <Route exact path={`${match.path}/calibrate`}>
                <Calibrate/>
              </Route>
            ) : null}

            {myAccess === 'full' ? (
              <Route exact path={`${match.path}/collaborate`}>
                <Collaborate studyId={id} permissions={permissions || []} />
              </Route>
            ) : null}

            <Route exact path={`${match.path}/reports`}>
              <Reports
                questions={questions}
                participants={participantsAndResponses}
                personas={decoratedPersonas}
              />
            </Route>

            <Route exact path={match.path}>
              <Redirect to={`${match.url}/clusters`} />
            </Route>
          </Switch>

          {roles.includes('admin') && (
            <FakeResponseCreator />
          )}
        </ContentWrapper>
      </Backdrop>
    </ResultsWrapper>
  )
}
