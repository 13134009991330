import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import ClusterBackgroundImage from 'images/marketing/cluster_background.png';

interface WrapperProps {
  center?: boolean
}

const HeadingBackground = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${p => p.theme.spacing.small};
  width: 100%;
  padding: ${p => p.theme.spacing.small} 0;

  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    ${p => p.center ? 'align-items: center;' : ''}
    background-image: url(${ClusterBackgroundImage});
    height: 250px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (min-width: ${p => p.theme.breakpoints.desktop}) {
    height: 400px;
  }
`;

interface Props {
  children: ReactNode
  center?: boolean
}

export default function ClusterBackground({ center, children }: Props) {
  return (
    <HeadingBackground center={center}>
      {children}
    </HeadingBackground>
  );
}
