/* eslint-disable react-hooks/exhaustive-deps */
import { parseISO } from 'date-fns';
import { useCallback } from 'react';
import useLocalStorage from './useLocalStorage';

export default function useStudyAccessTimes() {
  const [studyAccess, setStudyAccess] = useLocalStorage<Record<string, string>>('studyAccess', {});

  const recordAccess = useCallback((studyId: string) => {
    setStudyAccess({ ...studyAccess, [studyId]: new Date().toISOString() });
  }, [setStudyAccess]);

  const hydratedStudyAccess = Object.fromEntries(Object.entries(studyAccess).map(([k,v]) => {
    return [k, parseISO(v)]
  }));

  return [hydratedStudyAccess, recordAccess];
}
