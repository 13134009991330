import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import Loader from 'components/Loader';

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${p => p.theme.colors.background};
  z-index: 4;
`;

export default function FullScreenLoader({ show }) {
  const minTimeToLoad = 1500;
  const [minTimeReached, setMinTimeReached] = useState(!show || process.env.NODE_ENV === 'development');

  useEffect(() => {
    setTimeout(() => setMinTimeReached(true), minTimeToLoad);
  }, [minTimeToLoad]);

  return (
    <AnimatePresence>
      {(show || !minTimeReached) && (
        <Wrapper
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 1 }}}
        >
          <Loader />
        </Wrapper>
      )}
    </AnimatePresence>
  )
}
