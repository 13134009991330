import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import ValidatedField from 'components/ValidatedField';
import Checkbox from 'components/Checkbox';
import { Button, TextInput, Text, ExternalLink, Stack } from 'atoms';

const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation confirmAccount($input: UserInput!) {
    confirmAccount(input: $input) {
      id
      email
    }
  }
`;

type Props = {
  onSuccess: (email: string) => void,
}

// TODO: DRY this with Signup.js
export default function ConfirmAccount({ onSuccess }: Props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>();
  const [tosChecked, setTosChecked] = useState(false);
  const [marketingOptIn, setMarketingOptIn] = useState(false);

  const [confirmAccount, { loading }] = useMutation(CONFIRM_ACCOUNT_MUTATION, {
    onCompleted: data => {
      onSuccess(data.confirmAccount.email);
    },
    onError: e => {
      if (e.message === 'instance not unique') {
        setError('Email address already signed up');
      } else {
        setError(e.message);
      }
    }
  });

  function confirm() {
    if (!email.match(/@/) || email.match(/\s/)) {
      setError('Enter a valid email address');
      return;
    }

    confirmAccount({
      variables: {
        input: {
          email: email.toLowerCase(),
          settings: { marketingOptIn },
        }
      }
    });
  }
  return (
    <>
      <ValidatedField
        label="Email address"
        error={error}
      >
        <TextInput
          autoFocus
          borderless
          onChange={v => setEmail(v)}
          onEnter={confirm}
        />
      </ValidatedField>

      <Stack spacing="small">
        <Checkbox
          checked={marketingOptIn}
          onChange={(v: boolean) => setMarketingOptIn(v)}
        >
          <Text family="marketing" size="small">
            I would like to receive marketing communications.
          </Text>
        </Checkbox>

        <Checkbox
          checked={tosChecked}
          onChange={(v: boolean) => setTosChecked(v)}
        >
          <Text family="marketing" size="small">
            I agree to the{' '}
            <ExternalLink newTab href="/policies/akin_privacy_policy_july_2020.pdf">privacy policy</ExternalLink>
            {' '}and{' '}
            <ExternalLink newTab href="/policies/akin_terms_of_use_july_2020.pdf">terms of use</ExternalLink>
          </Text>
        </Checkbox>
      </Stack>

      <Button onClick={confirm} disabled={!tosChecked} loading={loading}>Sign up</Button>
    </>
  );
}
