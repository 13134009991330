import sortBy from 'lodash.sortby';
import type { Participant, Question, Answer } from 'utils/types';
import { format, parseISO } from 'date-fns';

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDate(isoString: string, formatString = 'dd/MM/yy') {
  if (!isoString) { return ''; }
  return format(parseISO(isoString), formatString);
}

function labelOrOtherValue(question: Question, answer: Answer): string {
  if (typeof answer === 'string') {
    return answer === '' ? 'Other' : `Other: ${answer}`;
  } else if (answer === null) {
    return '';
  } else if (Array.isArray(answer)) {
    return answer.map(a => labelOrOtherValue(question, a)).join(', ');
  } else {
    return question.options[answer]?.label || 'Error: missing label';
  }
}
export function humanizeAnswer(question: Question, answer: Answer) {
  if (question.type === 'semantic_differential') {
    if (typeof answer !== 'number') { return `Bad semdiff answer: ${answer}`; }
    return humanizeSemdiffAnswer(answer, question.options[0].label, question.options[1].label);
  } else if (question.options && question.type !== 'open') {
    // (open questions shouldn't have options but they used to, sometimes)
    return labelOrOtherValue(question, answer);
  } else {
    return answer;
  }
}

export function humanizeSemdiffAnswer(answer: number, label1: string, label2: string) {
  const choice = answer < 0 ? label1 : label2;
  const degree = Math.ceil(Math.abs(answer) / 0.25) * 25;

  return `${degree}% ${choice}`;
}

export function countHighlights(participants: Participant[]) {
  const allHighlights = participants.flatMap(r => {
    const highlights = Object.values(r.highlights || {});
    const algoHighlights = Object.values(r.algoHighlights || {});
    const removedHighlights = Object.values(r.removedHighlights || {});

    return highlightsToShow(highlights.flat(), algoHighlights.flat(), removedHighlights.flat());
  });

  if (!allHighlights.length) { return []; }

  const highlightCounts = allHighlights.reduce<Record<string, number>>((total, word) => {
    return {
      ...total,
      [word]: total[word] ? total[word] + 1 : 1,
    };
  }, {});

  const sortedCounts = sortBy(Object.entries(highlightCounts), ([_,c]) => -c);

  return sortedCounts;
}

export function highlightsToShow(highlights: string[], algoHighlights: string[], removedHighlights: string[]) {
  const totalHighlights = new Set([...(highlights ?? []), ...(algoHighlights ?? [])]);

  return Array.from(totalHighlights).filter(h => !(removedHighlights ?? []).includes(h));
}

export function answerDistribution(question: Question, participants: Participant[]) {
  if (question.type === 'semantic_differential') {
    const [label1, label2] = question.options.map(o => o.label);
    return [
      `100% ${label1}`, `75% ${label1}`, `50% ${label1}`, `25% ${label1}`,
      `25% ${label2}`, `50% ${label2}`, `75% ${label2}`, `100% ${label2}`,
    ].map((o, optionIndex) => {
      const n = participants.filter(p => {
        const answer = p.answers[question.id];

        if (typeof answer !== 'number') { return false; }
        return humanizeSemdiffAnswer(answer, label1, label2) === o;
      }).length;
      return [optionIndex, o, n];
    });
  } else if (question.type === 'checkbox' || question.type === 'task_type') { // TODO: make this generic
    const distribution = question.options.map((o, optionIndex) => {
      const n = participants.filter((p) => {
        const answer = p.answers[question.id];

        if (answer === null || !Array.isArray(answer)) {
          return false;
        }

        return answer.includes(optionIndex)
      }).length;
      return [optionIndex, o.label, n];
    });

    const participantsWithOther = participants.filter(p => {
      const answer = p.answers[question.id];

      if (answer === null || !Array.isArray(answer)) {
        return false;
      }

      return answer.some(a => typeof a === 'string');
    });

    return question.allowOther ? [...distribution, ['other', 'Other', participantsWithOther.length]] : distribution;
  } else if (question.options) {
    const distribution = question.options.map((o, optionIndex) => {
      const n = participants.filter(p => p.answers[question.id] === optionIndex).length;
      return [optionIndex, o.label, n];
    });

    const participantsWithOther = participants.filter(p => typeof p.answers[question.id] === 'string');
    return question.allowOther ? [...distribution, ['other', 'Other', participantsWithOther.length]] : distribution;
  }

  return null;
}
