import { useEffect } from 'react';
import ResultsProvider from 'components/study/ResultsProvider';
import ResultsPage from 'components/study/ResultsPage';
import { useParams } from 'react-router-dom';
import StudyLayout from 'components/study/StudyLayout';
import ResultsUpdates from './ResultsUpdates';
import useStudyAccessTimes from 'hooks/useStudyAccessTimes';

export default function ResultsWrapper() {
  const { studyId } = useParams();
  const [, recordAccess] = useStudyAccessTimes();

  useEffect(() => {
    recordAccess(studyId);
  }, [studyId, recordAccess]);

  // FIXME: get access properly
  return (
    <ResultsProvider studyId={studyId}>
      <StudyLayout studyId={studyId} name="results" access="full">
        <ResultsUpdates />
        <ResultsPage />
      </StudyLayout>
    </ResultsProvider>
  );
}
