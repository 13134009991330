import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ElementProps {
  size?: 'small' | 'body'
  family?: string
  color?: string
  align?: 'left' | 'center' | 'right'
  strong?: boolean
  inline?: boolean;
}

const TextElement = styled.code<ElementProps>`
  display: block;
  padding: ${p => p.theme.spacing.small};
  border-radius: ${p => p.theme.borderRadius};
  white-space: pre;
  font-size: ${p => p.theme.fontSizes.body};
  line-height: ${p => p.theme.lineHeights.body};
  font-family: ${p => p.theme.fonts.monospace};
  background-color: ${p => p.theme.colors.text};
  color: ${p => p.theme.colors.textInvert};
`;

export interface Props {
  children: ReactNode
};

export default function Text(props: Props) {
  return (
    <TextElement {...props}/>
  );
}
