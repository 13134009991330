import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

interface BarProps {
  color: string
}

const Bar = styled.div<BarProps>`
  width: 100%;
  height: 7px;
  background-color: ${p => `${p.theme.colors[p.color] ?? p.color}33`};
  border-radius: 10px;
  overflow: hidden;
`;

interface ImportanceProps {
  value: number
  color?: string
}

const Importance = styled(motion.div)<ImportanceProps>`
  height: 100%;
  background-color: ${p => p.color ? (p.theme.colors[p.color] ?? p.color) : p.theme.colors.text};
  border-radius: 10px;
  transform-origin: center left;
`;

export interface Props {
  value: number
  color: string
  layoutId?: string
}

export default function ImportanceBar({ value, color, layoutId }: Props) {
  return (
    <Bar color={color}>
      <Importance
        value={value}
        color={color}
        animate={{
          width: `${value * 100}%`,
          scaleX: 1
        }}
        initial={{ scaleX: 0 }}
        layoutId={layoutId}
      />
    </Bar>
  );
}
