import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import { createPortal } from 'react-dom';

export const ModalBackground = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.modalOverlay};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

interface ModalDialogProps {
  thin: boolean
  backgroundColor?: string
}

export const ModalDialog = styled(motion.div)<ModalDialogProps>`
  min-width: ${p => p.thin ? '450px' : '600px'};
  max-width: ${p => p.thin ? '450px' : '80ch'};
  max-height: 80%;
  background-color: ${p => p.theme.colors.accentBackground};
  overflow-y: auto;

  padding: 40px;
  border-radius: ${p => p.theme.borderRadius};

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    min-width: 300px;
    max-width: 95%;
  }
`;

export interface Props {
  handleClose: () => void
  children: ReactNode
  thin?: boolean
}

export default function Modal({ handleClose, children, thin = false, ...otherProps }: Props) {
  const modalRoot = document.getElementById('modal-root')!;

  return createPortal(
    <ModalBackground
      onClick={e => { e.stopPropagation(); handleClose && handleClose() }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <ModalDialog
        {...otherProps}
        onClick={e => e.stopPropagation()}
        onKeyUp={e => { if(e.key === 'Escape') { e.stopPropagation(); handleClose && handleClose() } }}
        animate={{ scale: 1 }}
        initial={{ scale: 0 }}
        exit={{ scale: 0 }}
        thin={thin}
      >
        {children}
      </ModalDialog>
    </ModalBackground>,
    modalRoot
  );
}
