import { useMemo, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { gql, useQuery, useMutation } from '@apollo/client';
import QRCode from 'qrcode.react';
import debounce from 'lodash.debounce';
import StudyContext from 'studyContext';
import useNotifications from 'hooks/useNotifications';
import { Content, ContentWrapper, Stack, Inline } from 'components/atoms/layout';
import TextInput from 'atoms/TextInput';
import Button from 'atoms/Button';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { RadioList, Radio } from 'components/atoms/radio';
import Modal from 'components/Modal';

const ColorSwatch = styled.input.attrs({
  type: 'color',
})`
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  cursor: pointer;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    background-color: ${p => p.theme.colors.background};
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 100%;
  }
`;

const HexWrapper = styled.div`
  position: relative;

  &::before {
    content: 'Hex';
    display: block;
    position: absolute;
    left: 10px;
    top: 9.5px;
  }
`;

const STUDY_RESPONSE_COUNT_QUERY = gql`
  query studyResponseCount($id: ID!) {
    study(id: $id) {
      id
      responseCount
    }
  }
`;

const UNPUBLISH_MUTATION = gql`
  mutation unpublishMutation($id: ID!) {
    unpublishStudy(id: $id) {
      id
      state
    }
  }
`;

export default function Share({ showPreview }) {
  const { dispatch, study, setShowPublishModal } = useContext(StudyContext);
  const [showQr, setShowQr] = useState(false);
  const { id, state, options, slug } = study;
  const notify = useNotifications();

  const { data, loading } = useQuery(STUDY_RESPONSE_COUNT_QUERY, {
    variables: { id },
  });

  const [unpublish, { loading: loadingUnpublish }] = useMutation(UNPUBLISH_MUTATION, {
    variables: { id },
    onCompleted: d => {
      dispatch({ type: 'changeState', state: d.unpublishStudy.state });
      notify({ message: 'Your study has been unpublished' });
    },
    onError: e => notify({ message: `Study could not be unpublished: ${e.message}` }),
  });
  const [newColor, setNewColor] = useState(options?.accentColor ?? '#000000');
  const linkInputRef = useRef();
  const colorInputRef = useRef();
  const published = state !== 'draft';

  function copyLink() {
    linkInputRef.current.select();
    document.execCommand('copy');
    notify({ message: 'Link copied to clipboard' });
  }

  const updateColor = useMemo(
    () => debounce(v => dispatch({ type: 'setOption', option: 'accentColor', value: v }, 500, { leading: true })),
    [dispatch]
  );

  useEffect(() => {
    updateColor(newColor);
  }, [newColor, updateColor]);

  const shareUrl = `${window.location.protocol}//${window.location.host}/s/${slug}`;
  return (
    <>
      <ContentWrapper showPreview={showPreview}>
        <Content>
          <Stack spacing="large">
            { state === 'published' ? (
              <Stack>
                <Heading>Share survey</Heading>
                <Text size="body">
                  What your participants see first, include relevant information about your survey
                  and what they should expect.
                </Text>
                {!loading && data && data.study.responseCount === 0 ? (
                  <Button loading={loadingUnpublish} onClick={unpublish}>
                    Unpublish
                  </Button>
                ) : null}
              </Stack>
            ) : (
              <Stack>
                <Heading>Publish survey</Heading>
                <Text size="body">
                  Once your survey is published you can start sending out a link to participants.
                  Review your survey for tips to improve your survey.
                </Text>
                <Button onClick={() => setShowPublishModal(true)}>
                  Publish
                </Button>
              </Stack>
            )}

            <Stack id="link">
              <Text size="body" strong>Survey link</Text>

              <div>
                <Stack spacing="small">
                  {published
                    ? <Text size="body">Copy the link to send out out to participants</Text>
                    : (
                      <Stack spacing="small">
                        <Text size="body">
                          Your survey isn't published <br/>
                          Publish your survey so you can share the latest version
                        </Text>
                      </Stack>
                    )
                  }

                  <Inline fullWidth spacing="small">
                    <TextInput
                      readOnly
                      disabled={!published}
                      ref={linkInputRef}
                      style={{ height: '40px' }}
                      defaultValue={shareUrl}
                      onClick={e => e.target.select()}
                    />
                    <Button
                      variant="secondary"
                      disabled={!published}
                      onClick={() => setShowQr(v => !v)}
                    >
                      QR Code
                    </Button>
                    <Button
                      variant="secondary"
                      disabled={!published}
                      onClick={copyLink}
                    >
                      Copy
                    </Button>
                  </Inline>

                  {showQr && (
                    <Modal handleClose={() => setShowQr(false)}>
                      <Inline fullWidth justify="center">
                        <QRCode
                          value={shareUrl}
                          renderAs="svg"
                          size={256}
                        />
                      </Inline>
                    </Modal>
                  )}
                </Stack>
              </div>
            </Stack>

            <Stack id="color">
            <Text size="body" strong>Survey colour</Text>
              <Text size="body">This affects the progress bar and the button colour of the survey</Text>
              <Inline spacing="small">
                <ColorSwatch
                  ref={colorInputRef}
                  value={newColor}
                  onChange={e => setNewColor(e.target.value)}
                />
                <HexWrapper>
                  <TextInput
                    value={newColor}
                    onChange={e => setNewColor(e.target.value)}
                    style={{
                      width: '115px',
                      height: '40px',
                      flex: '0',
                      paddingLeft: '40px',
                    }}
                  />
                </HexWrapper>
                <Button
                  variant="secondary"
                  onClick={() => colorInputRef.current.click()}
                >
                  Edit
                </Button>
              </Inline>
            </Stack>

            <Stack id="typography">
              <Text size="body" strong>Typography</Text>
              <RadioList>
                <Radio
                  checked={options?.typography === 'sans'}
                  onChange={() => dispatch({ type: 'setOption', option: 'typography', value: 'sans' })}
                >
                  Only sans-serif
                </Radio>
                <Radio
                  checked={options?.typography !== 'sans'}
                  onChange={() => dispatch({ type: 'setOption', option: 'typography', value: 'combo' })}
                >
                  Serif & sans-serif combo
                </Radio>
              </RadioList>
            </Stack>
          </Stack>
        </Content>
      </ContentWrapper>
    </>
  )
}
