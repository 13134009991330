import styled from 'styled-components/macro';

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  border: 2px dashed black;
  background-color: ${p => p.theme.colors.dark};
`;

export interface Props {
  label?: string
};

export default function Placeholder({ label = 'Placeholder' }: Props) {
  return (
    <Box>{label}</Box>
  );
}
