import { useRef, useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components/macro'
import { Reset } from 'styled-reset'
import ReactGA from 'react-ga';
import NotificationProvider from 'components/NotificationProvider';
import AuthProvider from 'components/auth/AuthProvider';
import AppContext from 'AppContext';
import { DEFAULT_THEME } from 'themes';
import GlobalStyle from './GlobalStyle';
import merge from 'deepmerge';
import apiCall from 'utils/apiCall';

const history = createBrowserHistory();

export default function AppWrapper({ children }) {
  // TODO: move this nonsense
  const [userTheme, setUserTheme] = useState({});
  const gaInit = useRef(false);

  // TODO: extract this to its own thing
  if (!gaInit.current && process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
      // debug: true,
    });

    gaInit.current = true;

    history.listen(location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }

  useEffect(() => {
    if (!window.location.host.match(/^localhost/)) {
      trackPageView();
      history.listen(trackPageView);
    }
  }, []);

  function trackPageView() {
    apiCall(`event`, {
      method: 'POST',
      body: JSON.stringify({
        path: window.location.pathname,
      }),
    }).catch(e => console.error(e));
  }

  return (
    <ThemeProvider theme={merge(DEFAULT_THEME, userTheme)}>
      <Reset />
      <GlobalStyle />

      <AppContext.Provider value={{ userTheme, setUserTheme }}>
        <AuthProvider>
          <Router history={history}>
            <NotificationProvider>
              {children}
            </NotificationProvider>
          </Router>
        </AuthProvider>
      </AppContext.Provider>
    </ThemeProvider>
  );
}
