import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ElementProps {
  maxWidth: string
  mobileMaxWidth: string
  fullHeight: boolean
}

const ContentWrapper = styled.div<ElementProps>`
  max-width: ${p => p.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 120px;
  height: ${p => p.fullHeight ? '100%;' : 'fit-content'};

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    max-width: ${p => p.mobileMaxWidth};
  }
`;

export interface Props {
  maxWidth?: string
  mobileMaxWidth?: string
  fullHeight?: boolean
  children: ReactNode
}

export default function Content({
  maxWidth = '800px',
  mobileMaxWidth = '100%',
  fullHeight = false,
  children,
}: Props) {
  return (
    <ContentWrapper
      maxWidth={maxWidth}
      mobileMaxWidth={mobileMaxWidth}
      fullHeight={fullHeight}
    >
      {children}
    </ContentWrapper>
  );
}
