import { useEffect, useState, Fragment, useContext } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Stack, Inline } from 'components/atoms/layout';
import Content from 'atoms/Content';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import Select from 'components/Select';
// import { ReactComponent as ResetSvg } from 'images/icons/menu/refresh.svg';
import Toggle from 'components/Toggle';
import { AnimatePresence } from 'framer-motion';
import ConfirmButton from 'components/ConfirmButton';
import Modal from 'components/Modal';
import Button from 'atoms/Button';
import useStudyResults from 'hooks/useStudyResults';

const NON_CALIBRATE_QUESTION_TYPES = [ // TODO: put this somewhere smart
  'identifier', 'email', 'screener', 'welcome_message', 'message', 'ignore',
];

// Only offer between 3-10 clusters
const POSSIBLE_CLUSTERS = new Array(8).fill(null).map((v,i) => [`${i + 3} clusters`, i + 3]);
const DEFAULT_NUM_CLUSTERS = 5;

const ClusterDotWrapper = styled.div`
  display: flex;
  height: 34px;
  width: fit-content;
  flex-direction: column;
  justify-content: flex-start;

  flex-wrap: wrap;
`;

const ClusterDot = styled(motion.div)`
  width: 11px;
  height: 11px;
  margin: 3px;
  border-radius: 5.5px;
  background-color: ${p => p.color};
`;

function ClusterVisualisation({ n }) {
  const { colors: { clusters: clusterColors }} = useContext(ThemeContext);

  return (
    <ClusterDotWrapper>
      <AnimatePresence initial={false}>
        {clusterColors.slice(0, n).map((color, index) => (
          <ClusterDot
            key={color}
            color={color}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: index * 0.02 }}
          />
        ))}
      </AnimatePresence>
    </ClusterDotWrapper>
  )
}

export default function Calibrate() {
  const history = useHistory();

  const { study: { id, questions, analysisOptions }, updateStudy, updateLoading, refetch } = useStudyResults();

  const [ignoredQuestionIds, setIgnoredQuestionIds] = useState(analysisOptions?.ignoredQuestionIds || []);
  const [numClusters, setNumClusters] = useState(analysisOptions?.numClusters || DEFAULT_NUM_CLUSTERS);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [navigationOverride, setNavigationOverride] = useState(false);

  const handleBlockedNavigation = (nextLocation) => {
    setShowConfirmModal(nextLocation);
    return false
  }

  const handleConfirmNavigationClick = () => {
    setNavigationOverride(showConfirmModal.pathname);
  };

  useEffect(() => {
    if (!navigationOverride) { return; }

    history.push(navigationOverride);
  }, [navigationOverride, history])

  const saveChanges = () => {
    updateStudy({
      variables: {
        id: id,
        input: {
          analysisOptions: {
            ignoredQuestionIds,
            numClusters: Math.round(numClusters),
          },
        },
      },
    }).then(() => {
      setUnsavedChanges(false);
      refetch().then(() => history.push('clusters'));
    });
  };

  function handleChangeClusters(newValue) {
    setUnsavedChanges(true);
    setNumClusters(newValue);
  }

  function toggleQuestion(question, exclude) {
    setUnsavedChanges(true);
    setIgnoredQuestionIds(exclude ? [...ignoredQuestionIds, question.id] : ignoredQuestionIds.filter(id => id !== question.id))
  }

  const questionsThatCanBeExcluded = questions.filter(q => !NON_CALIBRATE_QUESTION_TYPES.includes(q.type));
  const tryingToExcludeEverything = ignoredQuestionIds.length === questionsThatCanBeExcluded.length;

  return (
    <Content>
      <Prompt
        when={unsavedChanges && !navigationOverride}
        message={handleBlockedNavigation}
      />
      {showConfirmModal && (
        <Modal handleClose={() => setShowConfirmModal(false)}>
          <Stack>
            <Inline fullWidth justify="center">
              <Heading>Are you sure?</Heading>
            </Inline>
            <Text size="body">You have unsaved changes — are you sure you want to leave this page?</Text>
            <Inline fullWidth justify="center">
              <Button onClick={handleConfirmNavigationClick}>Leave</Button>
              <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
            </Inline>
          </Stack>
        </Modal>
      )}
      <Stack spacing="large">
        <Stack>
          <Inline fullWidth justify ="space-between">
            <Heading>Edit clusters</Heading>
            <ConfirmButton
              variant="secondary"
              title="These changes require AI"
              body="The changes you made require akin to put your survey through our artificial intelligence again to get the best clusters. This could take a few minutes."
              ctaText="Continue"
              cancelText="Cancel changes"
              onConfirm={saveChanges}
              disabled={tryingToExcludeEverything || !unsavedChanges}
              loading={updateLoading}
            >
              Save changes
            </ConfirmButton>

            {/* {!unsavedChanges && <Button variant="secondary" onClick={resetToDefault}>
              <ResetSvg />
              Reset
            </Button>} */}
          </Inline>
          <Text size="body">
            Choose the number of clusters and any questions to remove from the cluster algorithm.
            You can reset to the reccommended clustering at anytime.
          </Text>
        </Stack>

        <Inline>
          <Select
            selectedValue={numClusters}
            onChange={v => handleChangeClusters(v)}
            items={POSSIBLE_CLUSTERS}
            xDirection="left"
          />
          <ClusterVisualisation n={numClusters}/>
        </Inline>

        <Heading level={3}>Exclude questions from clustering</Heading>
        <Grid rowTemplate={['1fr', '170px']}>
          <Grid.Header>Question</Grid.Header>
          <Grid.Header>Exclude from clustering</Grid.Header>
          <Grid.Line />

          {questionsThatCanBeExcluded.map(q => {
            const excluded = ignoredQuestionIds.includes(q.id);

            return (
              <Fragment key={q.id}>
                <Grid.Cell>{q.label}</Grid.Cell>
                <Grid.Cell align="right">
                  <Toggle
                    checked={excluded}
                    onChange={v => toggleQuestion(q, v)}
                  />
                </Grid.Cell>

                <Grid.Line />
              </Fragment>
            );
          })}
        </Grid>
      </Stack>
    </Content>
  )
}
