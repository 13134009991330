import styled from 'styled-components/macro';
import TabBar from 'components/TabBar';
import NavBar from 'components/NavBar';

const Wrapper = styled.div`
  height: 100%;
`;

export default function StudyLayout({ studyId, name, access, children }) {
  return (
    <Wrapper>
      <NavBar>
        <TabBar basePath={`/study/${studyId}`} tabs={[
          ['survey', 'Survey'],
          access === 'full' ? ['share', 'Share'] : null,
          ['results', 'Results'],
        ].filter(Boolean)} />
      </NavBar>

      { children }
    </Wrapper>
  );
}
