import { useState, Fragment } from 'react';
import { useMutation, gql } from '@apollo/client';
import Heading from 'atoms/Heading';
import Stack from 'atoms/Stack';
import Button from 'atoms/Button';
import Inline from 'atoms/Inline';
import useStudyResults from 'hooks/useStudyResults';
import Select from 'components/Select';
import type { Question, Response, Option } from 'utils/types';
import { Redirect, useHistory } from 'react-router';
import Grid from 'atoms/Grid';
import Checkbox from 'components/Checkbox';
import Text from 'atoms/Text';
import Panel from 'atoms/Panel';
import Content from 'atoms/Content';

const CREATE_PREDICTION_QUERY = gql`
  mutation createPrediction($studyId: ID!, $input: PredictInput!) {
    requestPrediction(studyId: $studyId input: $input) {
      id
      createdAt
      state
      params {
        questionId
        answers
      }
    }
  }
`;

// TODO: checkboxes? semantic_differential?
const PREDICTABLE_QUESTION_TYPES = ['multi', 'screener', 'likert', 'nps', 'binary'];

export default function NewPredictionModal() {
  const history = useHistory();
  const [state, setState] = useState('question');
  const { study: { id, questions, analysisOptions, responses }} = useStudyResults();
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null);
  const [validAnswers, setValidAnswers] = useState<number[]>([]);
  const [createMutation, { loading }] = useMutation(CREATE_PREDICTION_QUERY, {
    //refetchQueries: ['recentPredictions'], // TODO: change this to use object syntax?
  });

  const eligibleQuestions = (questions as Question[]).filter(q => {
    return PREDICTABLE_QUESTION_TYPES.includes(q.type);
  });

  if (eligibleQuestions.length === 0) {
    return (
      <Stack fullWidth>
        <Stack spacing="small">
          <Text size="body">
            You don't have any questions that we can predict!
          </Text>

          <Text size="body">
            Try a survey with multi-choice questions or semantic differentials.
          </Text>
        </Stack>
      </Stack>
    );
  }
  // TODO: DRY this
  const ignoredParticipantIds = analysisOptions?.ignoredParticipantIds ?? [];
  const nonIgnoredResponses = (responses as Response[]).filter(r => !ignoredParticipantIds.includes(r.id));
  const selectedQuestion = selectedQuestionId && questions.find((q: Question) => q.id === selectedQuestionId);

  const yAxis = selectedQuestionId ? nonIgnoredResponses.map((r: Response) => {
    const answer = r.answers[selectedQuestionId];

    if (typeof answer === 'number') {
      return validAnswers.includes(answer) ? 1 : 0;
    }

    return 0;
  }) : null;

  function toggleAnswer(answer: number, value: boolean) {
    if (value) {
      setValidAnswers([...validAnswers, answer]);
    } else {
      setValidAnswers(validAnswers.filter(v => v !== answer));
    }
  }

  function createPrediction() {
    createMutation({
      variables: {
        studyId: id,
        input: {
          questionId: selectedQuestionId,
          answers: validAnswers,
          yAxis,
        },
      }
    }).then(response => {
      history.push(response.data.requestPrediction.id);
    });
  }

  if (state === 'question') {
    return (
      <Content>
        <Panel>
          <Stack fullWidth>
            <Heading>Choose a question</Heading>

            <Text>
            Choose a question that has the answers you want predict.
            </Text>
            <Text>
              <em style={{ fontStyle: 'italic' }}>
                e.g. if you wanted to know why people have an NPS score of 2 and below,
                pick ‘How likely are you to recommend us?’
              </em>
            </Text>

            <Select
              background
              items={eligibleQuestions.map(q => [q.label, q.id])}
              selectedValue={selectedQuestionId}
              onChange={setSelectedQuestionId}
              placeholder="Select a question to predict"
              // TODO: once <Select> is typecript remove these:
              className={null}
              fixedWidth={false}
              xDirection={null}
            />

            <Inline fullWidth spacing="small">
              <Button variant="secondary" onClick={() => history.push('../predict')}>
                Back
              </Button>

              <Button
                onClick={() => setState('answer')}
                disabled={!selectedQuestionId}
              >
                Next
              </Button>
            </Inline>
          </Stack>
        </Panel>
      </Content>
    );
  } else if (state === 'answer') {
    return (
      <Content>
        <Panel>
          <Stack fullWidth>
            <Heading>Choose an answer</Heading>

            <Text>
              Choose an answer that you want to predict.
            </Text>
            <Text>
              <em style={{ fontStyle: 'italic' }}>
              e.g. if you wanted to know why people have an NPS score below 2 and below,
              pick ‘1, 2’
              </em>
            </Text>

            <Grid rowTemplate={['1fr', '1fr', '1fr', '1fr']}>
              {selectedQuestion.options?.map((option: Option, index: number) => {
                return (
                  <Fragment key={index}>
                    <Grid.Cell>
                      <Checkbox
                        checked={validAnswers.includes(index)}
                        onChange={(v: boolean) => toggleAnswer(index, v)}
                      >
                        {option.label}
                      </Checkbox>
                    </Grid.Cell>
                  </Fragment>
                );
              })}
            </Grid>

            <Text size="small">
              {yAxis ? Math.round(yAxis?.filter(v => v === 1).length / yAxis.length * 100) : 0}% of
              your responses meet this criteria.
            </Text>

            <Inline fullWidth spacing="small">
              <Button variant="secondary" onClick={() => setState('question')}>
                Back
              </Button>

              <Button
                loading={loading}
                onClick={createPrediction}
                disabled={validAnswers.length === 0}
              >
                Next
              </Button>
            </Inline>
          </Stack>
        </Panel>
      </Content>
    );
  } else {
    return <Redirect to="../predict" />;
  }
}
