import { Island } from 'components/atoms/layout';
import Stack from 'atoms/Stack';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import Link from 'atoms/Link';

interface Props {
  message?: string
  unrecoverable?: boolean
}

export default function ErrorPage({ message, unrecoverable = false }: Props) {
  return (
    <Island>
      <Stack>
        <Heading>Error</Heading>
        <Text size="body">
          {message || (<>
            Something went wrong in the lab<br/>
            and our team has been notified.
          </>)}
        </Text>
        { unrecoverable ? (
          <Link to="/studies">
            Back home
          </Link>
        ) : (
          <Button onClick={() => window.location.reload()}>
            Refresh
          </Button>
        )}
      </Stack>
    </Island>
  );
}
