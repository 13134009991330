import React, { useReducer } from 'react';
import NotificationContext from 'NotificationContext';
import Notifications from 'components/Notifications';

function notificationReducer(state, { type, ...params }) {
  switch(type) {
    case 'notify':
      // Right now `tag` is the method of determining uniqueness
      if (params.tag && state.find(m => m.tag === params.tag)) {
        return state;
      } else {
        return [...state, params];
      }
    case 'clear':
      return state.filter(m => m.id !== params.id);
    default:
      throw new Error(`Unknown notification actionType: ${type}`);
  }
}

export default function NotificationProvider({ children }) {
  const [notifications, dispatch] = useReducer(notificationReducer, []);

  return (
    <NotificationContext.Provider value={dispatch}>
      <Notifications notifications={notifications} />
      {children}
    </NotificationContext.Provider>
  )
}
