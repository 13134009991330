import styled from 'styled-components/macro';
import Link from 'atoms/Link';
import Text from 'atoms/Text';

const BannerWrapper = styled.div`
  width: 100%;
  padding: 12px 40px;
  display: flex;
  justify-content: space-between;
  background-color: ${p => p.theme.colors.brand};
  color: white;
`;

export default function Banner({ text, linkText, linkTo }) {
  return (
    <BannerWrapper>
      <Text strong>{text}</Text>

      {linkText ? (
        <Link noUnderline to={linkTo}>
          {linkText}
        </Link>
      ) : null}
    </BannerWrapper>
  );
};
