import styled from 'styled-components/macro';
import Dropdown from 'components/Dropdown';
import Text from 'atoms/Text';

const SelectWrapper = styled.div`
  width: fit-content;
  padding: 4px 10px;
  border-radius: 100px;
  ${p => p.background ? `background-color: ${p.theme.colors.dark};` : ''}
`;

export default function Select({ selectedValue, items, onChange, className, fixedWidth, background, xDirection, placeholder }) {
  const dropdownItems = items.map(([label, value]) => {
    return [label, () => onChange(value)];
  })

  const selectedItem = items.find(([label, value]) => value === selectedValue);
  const selectedLabel = selectedItem ? selectedItem[0] : placeholder;

  return (
    <SelectWrapper background={background} className={className}>
      <Dropdown
        linkText={() => <Text strong>{selectedLabel}</Text>}
        items={dropdownItems}
        yOffset={-30}
        xDirection={xDirection}
        fixedWidth={fixedWidth}
      />
    </SelectWrapper>
  );
}
