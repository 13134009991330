import StudyContext from 'studyContext';
import { format } from 'date-fns';
import Text from 'atoms/Text';
import { FRIENDLY_STATE_NAMES } from '../../constants';
import useStudyResults from 'hooks/useStudyResults';

export default function StudyState() {
  const { study: { state, createdAt }} = useStudyResults(StudyContext);

  return (
    <Text size="small">
      {FRIENDLY_STATE_NAMES[state]}{' '}
      {format(Date.parse(createdAt), 'MM/dd/yy')}
    </Text>
  );
}
