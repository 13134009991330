import React from 'react';
import styled from 'styled-components/macro';

const BannerWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background-color: ${p => p.theme.colors.brand};
  color: white;
  z-index: 1;
`;

export default function PreviewBanner() {
  return (
    <BannerWrapper>
      Survey preview
    </BannerWrapper>
  );
}
