import { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useQuery, useMutation, gql, NetworkStatus } from '@apollo/client';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import { Stack } from 'components/atoms/layout';
import Loader from 'components/Loader';
import IconButton from 'atoms/IconButton';
import { format, formatDistance, parseISO } from 'date-fns';
import Link from 'atoms/Link';
import Inline from 'atoms/Inline';
import Text from 'atoms/Text';
import Button from 'atoms/Button';
import Panel from 'atoms/Panel';

const ErrorMessage = styled.label`
  color: ${p => p.theme.colors.error};
  font-weight: bold;
`;

const USER_QUERY = gql`
  query adminUser($id: ID!) {
    user(id: $id) {
      id
      email
      plan {
        giftedByUserId
        subscribedUntil
      }
      roles
      studies {
        id
        state
        name
        createdAt
        updatedAt
        responseCount
        latestAnalysis {
          id
          state
          createdAt
        }
      }
    }
  }
`;

const REQUEST_ANALYSIS = gql`
  mutation requestAnalysis($studyId: ID!) {
    requestAnalysis(studyId: $studyId)
  }
`;

const TOGGLE_GIFT_SUB = gql`
  mutation updateUser($userId: ID!, $input: AdminUserInput!) {
    updateUser(userId: $userId, input: $input) {
      id
      roles
      plan {
        giftedByUserId
        subscribedUntil
      }
    }
  }
`;

function formatDate(isoString) {
  if (!isoString) { return ''; }

  return format(parseISO(isoString), 'dd/MM/yy');
}

export default function AdminUser() {
  const { userId} = useParams();
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  const { data, loading, networkStatus, error } = useQuery(USER_QUERY, {
    variables: { id: userId  },
    notifyOnNetworkStatusChange: true,
    pollInterval: autoRefresh ? 5000 : 0,
  });

  const [requestAnalysisMutation, { loading: requestLoading }] = useMutation(REQUEST_ANALYSIS, {
    refetchQueries: ['adminUser'],
    awaitRefetchQueries: true,
  });

  const [toggleGiftSubMutation, { loading: toggleGiftSubLoading }] = useMutation(TOGGLE_GIFT_SUB, {
    variables: {
      userId,
    }
  });

  function requestAnalysis(studyId) {
    setLoadingStates(old => ({ ...old, [studyId]: true }));

    requestAnalysisMutation({
      variables: { studyId }
    }).finally(result => {
      setLoadingStates(old => ({ ...old, [studyId]: false }));
    });
  }

  if (loading && networkStatus === NetworkStatus.loading) {
    return <Loader />
  }

   if (error) {
    return (
      <ErrorMessage>
        Error retrieving data: {error.graphQLErrors.map(e => e.message)}
      </ErrorMessage>
    );
  }

  const currentlyGiftedSub = !!data.user.plan?.giftedByUserId;
  const proPlan = data.user.roles?.includes('pro_plan_active');
  const isAdmin = data.user.roles?.includes('admin');

  function toggleGiftSub() {
    toggleGiftSubMutation({
      variables: {
        input: {
          giftSub: !currentlyGiftedSub,
        },
      },
    });
  }

  return (
    <Stack>
      <Inline fullWidth justify="space-between" align="center">
        <Heading level={3}>
          Studies for {data.user.email}{' '}
          (<Link to={`/studies?imp=${data.user.id}`}>dashboard</Link>)
        </Heading>
        <Inline align="center" spacing="xsmall">
          Auto-refresh
          <IconButton
            icon="refresh"
            loading={networkStatus === NetworkStatus.poll}
            onClick={() => setAutoRefresh(v => !v)}
            color={autoRefresh ? 'highlight' : 'brand'}
            />
          </Inline>
      </Inline>

      <Inline fullWidth justify="center">
        <Panel flat>
          <Inline align="center">
            <Stack spacing="xsmall">
              <Text>
                {currentlyGiftedSub
                  ? 'This user has an infinite gift subscription.'
                  : `This user is on the ${proPlan ? 'Pro' : 'Free'} plan.`
                }
              </Text>
              {isAdmin && <Text strong>This is an admin account.</Text>}
            </Stack>

            <Button
              variant="secondary"
              onClick={toggleGiftSub}
              loading={toggleGiftSubLoading}
            >
              {currentlyGiftedSub ? 'Revoke gift sub' : 'Gift them a sub'}
            </Button>
          </Inline>
        </Panel>
      </Inline>


      <Stack>
        <Grid rowTemplate={['1fr', '70px', '70px', '70px', '70px', '140px', '100px', '70px']} >
          <Grid.Header>Study name</Grid.Header>
          <Grid.Header>State</Grid.Header>
          <Grid.Header>Created</Grid.Header>
          <Grid.Header>Updated</Grid.Header>
          <Grid.Header>Responses</Grid.Header>
          <Grid.Header>Last analysis</Grid.Header>
          <Grid.Header>Analysis state</Grid.Header>
          <Grid.Header>Re-run</Grid.Header>
          <Grid.Line />

          {data.user.studies.map(s => (
            <Fragment key={`${s.id}`}>
              <Grid.Cell>
                <Link to={`/study/${s.id}`}>{s.name}</Link>
              </Grid.Cell>
              <Grid.Cell>{s.state}</Grid.Cell>
              <Grid.Cell>{formatDate(s.createdAt)}</Grid.Cell>
              <Grid.Cell>{formatDate(s.updatedAt)}</Grid.Cell>
              <Grid.Cell>{s.responseCount}</Grid.Cell>
              <Grid.Cell>{s.latestAnalysis
                ? formatDistance(new Date(s.latestAnalysis.createdAt), new Date(), { addSuffix: true })
                : null
              }</Grid.Cell>
              <Grid.Cell>{s.latestAnalysis?.state}</Grid.Cell>
              <Grid.Cell>
                <IconButton
                  icon="refresh"
                  loading={requestLoading && loadingStates[s.id]}
                  onClick={() => requestAnalysis(s.id)}
                />
              </Grid.Cell>
            </Fragment>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
