/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import StaticGalaxy from 'components/study/results/StaticGalaxy';

const HiddenGalaxy = styled(StaticGalaxy)`
  display: none;
`;
export default function ClusterMapReport({ participants, personas, reportComplete }) {
  const galaxyRef = useRef();

  useLayoutEffect(() => {
    const sourceSvg = galaxyRef.current;
    const svgXml = (new XMLSerializer()).serializeToString(sourceSvg);

    var link = document.getElementById('saveLink');
    var canvas = document.getElementById('canvas');
    var ctx = canvas.getContext('2d');

    var img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svgXml);
    img.onload = function() {
      ctx.drawImage(img, 0, 0);
      const pngDataUrl = canvas.toDataURL("image/png");
      const downloadUrl = pngDataUrl.replace('image/png', 'application/octet-stream;');

      link.href = downloadUrl;
      link.download = 'cluster_map.png';
      link.click();
    }

    reportComplete();
  }, [reportComplete]);

  return (
    <>
      <HiddenGalaxy ref={galaxyRef} participants={participants} personas={personas} />

      <canvas id="canvas" width="1000" height="1000" style={{ display: 'none' }} />

      <a id="saveLink" style={{ display: 'none' }}>Download</a>
    </>
  );
}
