import { useState } from 'react';
import styled from 'styled-components/macro';
import NavBar from 'components/NavBar';
import ResultsProvider from 'components/study/ResultsProvider';
import ResultsPage from 'components/study/ResultsPage';
import Modal from 'components/Modal';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { Stack, Inline } from 'components/atoms/layout';
import { CtaButton } from 'components/atoms/marketing';

const DemoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 2px solid ${p => p.theme.colors.text};
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.accentBackground};
  overflow: hidden;
`;

export const DemoButton = styled.button`
  padding: 15px 20px;
  color: ${p => p.theme.colors.text};
  background-color: white;
  border: 3px solid ${p => p.theme.colors.text};
  border-radius: ${p => p.theme.borderRadius};
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background-color: ${p => p.theme.colors.text};
    color: white;
  }
`;

export default function ResultsDemo({ studyId = 'bc0c76dd-b833-4467-8328-bba80baaeb81' }) {
  const [demoState, setDemoState] = useState('active');

  // Can start with state=welcome instead to get the explanation modal back
  // (or delete it later if we never end up turning it back on)

  return (
    <DemoWrapper>
      <NavBar blank>
        <p>Clusters of people who use akin</p>
      </NavBar>
      <ResultsProvider studyId={studyId}>
        <ResultsPage studyId={studyId} readOnly />
      </ResultsProvider>

      {demoState === 'welcome' && (
        <Modal>
          <Stack>
            <Heading>Live demo</Heading>
            <Text>
              We surveyed our customers to see what groups then to design better features and products.
              Take a look out our real-live clusters!
            </Text>
            <Inline>
              <CtaButton size="medium" onClick={() => setDemoState('active')}>
                Start exploring
              </CtaButton>
            </Inline>
          </Stack>
        </Modal>
      )}
    </DemoWrapper>
  )
}
