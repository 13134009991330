import styled from 'styled-components/macro';
import { ReactComponent as XSvg } from 'images/icons/x.svg';
import { ReactComponent as EllipsisSvg } from 'images/icons/ellipsis.svg';
import { ReactComponent as RefreshSvg } from 'images/icons/menu/refresh.svg';
import { ReactComponent as ChevronSvg } from 'images/icons/chevron-down.svg';
import { ReactComponent as PencilSvg } from 'images/icons/pencil.svg';
import { ReactComponent as ArrowLeftSvg } from 'images/icons/arrow-left.svg';
import { ReactComponent as TrashSvg } from 'images/icons/trash.svg';
import { ReactComponent as CloneSvg } from 'images/icons/clone.svg';
import { ReactComponent as PlusSvg } from 'images/icons/plus.svg';
import { ReactComponent as FeedbackSvg } from 'images/icons/feedback.svg';

interface IconBaseProps {
  rotation: number
  size: 'small' | 'medium'
  color?: string
}

const IconBase = styled.svg<IconBaseProps>`
  width: ${p => p.theme.iconSizes[p.size]};
  height: ${p => p.theme.iconSizes[p.size]};
  color: ${p => p.color ? p.theme.colors[p.color] : 'inherit'};
  transform: rotate(${p => p.rotation}deg);
  transition: 0.2s transform;
`;

const ICON_MAP = {
  x: XSvg,
  ellipsis: EllipsisSvg,
  refresh: RefreshSvg,
  chevron: ChevronSvg,
  pencil: PencilSvg,
  arrowLeft: ArrowLeftSvg,
  trash: TrashSvg,
  clone: CloneSvg,
  plus: PlusSvg,
  feedback: FeedbackSvg,
};

export interface Props {
  i: keyof typeof ICON_MAP
  size?: IconBaseProps['size']
  rotation?: IconBaseProps['rotation']
  color?: IconBaseProps['color']
}

export default function Icon({ i, color, size = 'small', rotation = 0 }: Props) {
  const IconSvg = ICON_MAP[i];

  return (
    <IconBase
      size={size}
      color={color}
      rotation={rotation}
      as={IconSvg}
    />
  );
}
