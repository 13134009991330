import { gql, useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { Stack, Inline } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import Modal from 'components/Modal';
import useAuth from 'hooks/useAuth';

const DELETE_ME = gql`
  mutation deleteMe {
    deleteMe
  }
`
export default function DeleteForever() {
  const history = useHistory();
  const { setToken } = useAuth();
  const [deleteMutation, { loading }] = useMutation(DELETE_ME, {
    onCompleted: () => setToken(null)
  });

  return (
    <Modal handleClose={() => history.push('/account')}>
      <Stack>
        <Heading>When you delete your account</Heading>
        <Text size="body">
          You obliterate all your data—forever.
          There's no undo button, and we can't get it back for you after.
        </Text>

        <Text size="body">
          You're about to delete all the responses you collected.
          You can download them from the Results section before you delete your account for good.
        </Text>

        <Text size="body">
          You're about to delete all the studies you created.
          And "you" too. Your data will be so obliterated,
          we won’t even know you've ever been a customer.
        </Text>

        <Text size="body">
          Are you sure you want to permanently delete your Akin persona account and all your data?
        </Text>

        <Inline>
          <Button onClick={deleteMutation} loading={loading}>
            Delete forever
          </Button>
          <Button variant="secondary" as={Link} to="/account">Cancel</Button>
        </Inline>
      </Stack>
    </Modal>
  )
}
