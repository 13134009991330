import styled from 'styled-components/macro';
import type { Spacing, Breakpoint } from 'utils/designSystemTypes';

interface ElementProps {
  justify: string
  fullWidth: boolean
  align: string
  spacing: Spacing
  $wrap: boolean
  collapseBelow?: Breakpoint
  collapseSpacing?: Spacing
}

const InlineDiv = styled.div<ElementProps>`
  display: flex;
  justify-content: ${p => p.justify || 'flex-start'};
  width: ${p => p.fullWidth ? '100%' : 'fit-content'};
  align-items: ${p => p.align || 'center'};
  flex-wrap: ${p => p.$wrap ? 'wrap' : 'initial'};
  gap: ${p => p.theme.spacing[p.spacing]};

  ${p => p.collapseBelow ? `
    @media (max-width: ${p.theme.breakpoints[p.collapseBelow]}) {
      flex-direction: column;
      align-items: initial;
      gap: ${p.theme.spacing[p.collapseSpacing ?? p.spacing]};
    }
  ` : null}
`;

export interface Props {
  fullWidth?: boolean
  justify?: 'flex-start' | 'flex-end' | 'space-between' | 'center'
  align?: 'flex-start' | 'flex-end' | 'center'
  spacing?: Spacing
  wrap?: boolean
  children: React.ReactNode
  foo?: string
  collapseBelow?: Breakpoint
  collapseSpacing?: Spacing
}

export default function Inline({
  fullWidth = false,
  justify = 'flex-start',
  align = 'flex-start',
  spacing = 'medium',
  wrap = false,
  collapseBelow,
  collapseSpacing,
  children
}: Props) {
  return (
    <InlineDiv
      fullWidth={fullWidth}
      justify={justify}
      align={align}
      spacing={spacing}
      $wrap={wrap}
      collapseBelow={collapseBelow}
      collapseSpacing={collapseSpacing}
    >
      {children}
    </InlineDiv>
  );
}
