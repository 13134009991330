import { Route, useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { useMutation, gql } from '@apollo/client';
import { Spacer, NotMobile, InlineCollapse, DesktopOnly } from 'components/atoms/layout';
import Banner from 'components/marketing/Banner';
import NewStudy from 'components/NewStudy';
import SourceTile from 'components/create/SourceTile';
import TileGrid from 'components/TileGrid';
import { MarketingContent, PageTitle, Emphasis, LargeImage } from 'components/atoms/marketing';
import MarketingComponent from 'components/marketing/MarketingComponent';
import { Button, Text, Stack } from 'atoms';
import { useAuth } from 'hooks';
import heroImage from 'images/marketing/hero_image.png';

const HeroImage = styled.img`
  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    max-width: 428px;
    max-height: 318px;
  }
`;

// DRY this up with MarketingPage too
function addEmphasis(string) {
  const [head, emph, tail] = string.split('*');

  if (!emph) { return head; }

  return <>{head} <Emphasis>{emph}</Emphasis> {tail}</>;
}

const CREATE_PLACEHOLDER_USER_MUTATION = gql`
  mutation createPlaceholderUser {
    createPlaceholderUser {
      user {
        id
      }
      jwt
    }
  }
`;

export default function Overview({ triggerSignup, blocks }) {
  const { isLoggedIn, setToken } = useAuth();
  const history = useHistory();

  const [createPlaceholderUser] = useMutation(CREATE_PLACEHOLDER_USER_MUTATION);

  function getStarted(source) {
    if (isLoggedIn) {
      history.replace(`/get_started?integration=${source}`);
    } else {
      createPlaceholderUser().then(response => {
        setToken(response.data.createPlaceholderUser.jwt);
        history.replace(`/get_started?integration=${source}`);
      });
    }
  }

  return (
    <>
      <NotMobile><Spacer size="xlarge" /></NotMobile>

      <Stack fullWidth spacing="none" mobileSpacing="small">
        <MarketingContent>
          <InlineCollapse fullWidth spacing="medium">
            <Stack fullWidth spacing="large">
              <Stack spacing="medium">
                <PageTitle>
                  Replace <Emphasis>personas</Emphasis> with one survey
                </PageTitle>

                <Text size="xlarge">
                  Create a survey, share it with customers, and instantly create data-driven segments with AI.
                </Text>
              </Stack>

              <Button variant="primary" onClick={() => getStarted()}>Get started for free</Button>

              <TileGrid>
                <SourceTile onClick={() => getStarted('Akin')}>
                  FROM SCRATCH
                </SourceTile>

                <SourceTile onClick={() => getStarted('CsvUpload')}>
                  UPLOAD CSV
                </SourceTile>

                <SourceTile onClick={() => getStarted('SurveyMonkey')}>
                  SURVEY MONKEY
                </SourceTile>

                <SourceTile
                  onClick={() => getStarted('GoogleForms')}
                  bannerText="Coming soon"
                >
                  GOOGLE FORMS
                </SourceTile>

                <SourceTile
                  onClick={() => getStarted('Typeform')}
                  bannerText="Coming soon"
                >
                  TYPEFORM
                </SourceTile>
              </TileGrid>

              <Spacer/>
            </Stack>

            <DesktopOnly>
              <HeroImage src={heroImage} alt="Computer with stylized clusters" />
            </DesktopOnly>
          </InlineCollapse>
        </MarketingContent>

        {blocks.map((block, index) => (
          // DRY this up, it's duplicated in MarketingPage
          block.body ? (
            <MarketingComponent
              key={index}
              altBackground={index % 2 === 0}
              imageOnLeft={index === 0}
              title={addEmphasis(block.title)}
              text={block.body}
              image={block.image}
              imageAlt={block.imageAlt}
              ctaLabel={block.ctaText}
              ctaLink={block.ctaTarget}
            />
          ) : (
            <LargeImage src={block.image} alt={block.imageAlt} />
          )
        ))}
      </Stack>

      <NotMobile style={{ width: '100%' }}><Banner triggerSignup={triggerSignup} /></NotMobile>

      <Route path="/get_started">
        <NewStudy
          inOnboardingFlow
          handleClose={() => history.replace('/')}
        />
      </Route>
    </>
  );
}
