import { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components/macro';

const TitleBackground = styled.rect`
  fill: ${p => p.color};
`;

const Title = styled.text`
  fill: ${p => p.invert ? p.theme.colors.background : p.theme.colors.text};
  font-weight: 500;
  font-size: 14px;

  /* TODO: later we need a proper way to determine which lines are bold! */
  &:first-of-type {
    font-weight: 600;
  }
`;

// const Caret = styled.path`
//   fill: ${p => p.color};
//   stroke: ${p => p.color};
//   stroke-linejoin: round;
//   stroke-width: 3;
// `;

export default function Label({ text, x, y, color, showBackground = true }) {
  const titleRef = useRef();
  const [titleWidth, setTitleWidth] = useState(0);

  const lines = text.filter(a => a); // Remove nulls

  useLayoutEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.getBBox().width + 10);
    }
  }, [titleRef]);

  if (isNaN(x) || x === Infinity) { return null; }

  return (
    <>
      {showBackground && (
        <>
          <TitleBackground
            x={10 * x - 15}
            y={10 * y + 5}
            rx={5}
            width={titleWidth + 20}
            height={30 * lines.length + 10}
            color={color}
          />
          {/* <Caret
            d={`M${10*x-14},${10*y+16} L${10*x-24},${10*y+25} L${10*x-14},${10*y+34} Z`}
            color={color}
          /> */}
        </>
      )}
      <g ref={titleRef}>
        {lines.map((l, index) => (
          <Title
            key={index}
            x={10 * x}
            y={10 * y + 30 * (index + 1)}
            invert={showBackground}
          >
            {l}
          </Title>
        ))}
      </g>
    </>
  );
}
