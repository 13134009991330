import styled from 'styled-components/macro';
import Highlight from 'atoms/Highlight';

const WordSpan = styled.span`
  cursor: ${p => p.onClick ? 'pointer' : 'inherit'};
`;

export default function HighlightableAnswer({
  answer,
  highlights = [],
  algoHighlights = [],
  removedHighlights = [],
  handleWordClick
}) {
  const answerWords = answer?.split(/\s/) ?? [];

  function wordIsLit(word) {
    return highlights.includes(word) || (algoHighlights.includes(word) && !removedHighlights.includes(word));
  }

  return (
    <span>
      {answerWords.map((word,i) => {
        // TODO: remove some punctuation like trailing commas
        const sanitizedWord = word.toLowerCase().replace(/\b\W|\W\b/g, '');

        return (
          <WordSpan key={i} onClick={handleWordClick ? () => handleWordClick(sanitizedWord) : null}>
            {wordIsLit(sanitizedWord) ? <Highlight>{word}</Highlight> : word}{' '}
          </WordSpan>
        );
      })}
    </span>
  )
}
