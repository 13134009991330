export const MIN_RESPONSES = 20;
export const QUESTION_LIMIT = 50;

export const FREE_PLAN_STUDY_LIMIT = 2;
export const FREE_PLAN_RESPONSE_LIMIT = 30;
export const IGNORED_QUESTION_TYPES = ['screener', 'email', 'message', 'welcome_message', 'ignore'];

export const FRIENDLY_STATE_NAMES = {
  draft: 'Draft',
  published: 'Published',
};

export const QUESTION_TYPES = {
  screener: {
    label: 'Screener',
    description: '',
    options: true,
    defaultOptions: ['', '', ''],
    participantBehavior: 'radio',
  },
  multi: {
    label: 'Multiple choice',
    description: 'Choose only one option from a list.',
    options: true,
    optionDisplayType: 'radio',
    defaultOptions: ['', '', ''],
    participantBehavior: 'radio',
  },
  checkbox: {
    label: 'Checkbox',
    description: 'Choose multiple options from a list.',
    options: true,
    optionDisplayType: 'checkbox',
    defaultOptions: ['', '', ''],
    participantBehavior: 'checkbox',
  },
  semantic_differential: {
    label: 'Comparison',
    description: 'Compare two concepts against each other.',
    defaultQuestion: 'Which of these two options is more important to you? (Answer on a scale)',
    options: true,
    fixedOptions: true,
    defaultOptions: ['', ''],
    optionDisplayType: 'semantic_differential',
    participantBehavior: 'semantic_differential',
  },
  open: {
    label: 'Open question',
    description: 'Answer a question with a free form text box.',
    participantBehavior: 'open',
  },
  email: {
    label: 'Email address',
    description: 'Enter an email for future contact.',
    defaultQuestion: 'If you would be interested in doing more research such as surveys, interviews or testing new products please enter your email address so we can reach out.\n\nWe will of course reimburse you for any of time spent in this research. (Otherwise press skip)',
    participantBehavior: 'email',
  },
  likert: {
    label: 'Likert',
    description: 'Choose from a 7 point scale of options.',
    options: true,
    fixedOptions: true,
    reverseOptions: true,
    defaultOptions: ['Strongly disagree', 'Disagree', 'Somewhat disagree', 'Neutral', 'Somewhat agree', 'Agree', 'Strongly agree'],
    defaultQuestion: 'Do you agree with [insert]?',
    optionDisplayType: 'frequency',
    participantBehavior: 'radio',
  },
  nps: {
    label: 'Net promoter score',
    description: 'Answer the likelihood of recommending a service.',
    options: true,
    fixedOptions: true,
    defaultOptions: ['0 Not likely', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10 Extremely likely'],
    reverseOptions: true,
    defaultQuestion: 'How likely are you to recommend [insert]?',
    optionDisplayType: 'radio',
    participantBehavior: 'radio',
  },
  binary: {
    label: 'Yes or no',
    description: 'Choose from simply yes or no.',
    options: true,
    fixedOptions: true,
    reverseOptions: true,
    defaultOptions: ['No', 'Yes'],
    defaultQuestion: 'Do you agree with this statement?',
    optionDisplayType: 'radio',
    participantBehavior: 'radio',
  },
  welcome_message: {
    label: 'Welcome message',
    options: false,
    description: 'Greet a respondent with a message.',
    defaultQuestion: 'Thank you for participating in this survey. It should take around 10 minutes.',
    participantBehavior: 'message',
    maxAllowed: 1,
  },
  message: {
    label: 'Closing message',
    description: 'Thank a respondent with a message.',
    options: false,
    defaultQuestion: 'Thank you for completing the survey.  Press submit to record your response.',
    participantBehavior: 'message',
  },
  ignore: {
    label: 'Ignored question',
    description: 'Will be removed once your study is processed',
    options: false,
    participantBehavior: 'hide',
  },
  identifier: {
    label: 'Identifier',
    description: 'Will be removed once your study is processed',
    options: false,
    participantBehavior: 'hide',
  },
};

export const TEMPLATES = {
  survey: {
    question_categories: {
      survey: {
        adjective: 'survey',
        label: 'Survey',
      },
    },
  },
  persona: {
    showRecommendations: true,
    question_categories: {
      characteristics: {
        adjective: 'characteristic',
        label: 'Characteristics',
        recommendedAmount: { min: 5 },
      },
      behaviours: {
        adjective: 'behaviour',
        label: 'Behaviour',
        recommendedAmount: { min: 5 },
      },
      motivations: {
        adjective: 'motivation',
        label: 'Motivations',
        recommendedAmount: { min: 5 },
      },
      open_questions: {
        adjective: 'open',
        label: 'Open questions',
        recommendedAmount: { min: 2 },
      },
    },
  }
};

export const REDACTED_EMAIL_STRING = 'email@redacted.tld';
