import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const Tile = styled(motion.button)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: ${p => p.theme.shadows.standard};
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.background};
  cursor: pointer;
  text-align: start;
  border: initial;
`;

export type Props = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean
}

export default function InteractableTile({ onClick, disabled = false, children, ...otherProps }: Props) {
  return (
    <Tile
      {...otherProps}
      disabled={disabled}
      onClick={onClick}
      whileHover={disabled ? {} : { scale: 1.05 }}
      whileTap={disabled ? {} : { scale: 0.95 }}
    >
      {children}
    </Tile>
  );
}
