import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import Modal from 'components/Modal';
import Heading from 'atoms/Heading';
import TextInput from 'atoms/TextInput';
import ValidatedField from 'components/ValidatedField';
import Button from 'atoms/Button';
import Stack from 'atoms/Stack';
import Inline from 'atoms/Inline';

const MAX_CODES = 10000; // not enforced on the backend right now

const NEW_PROMO_CODE_BATCH_MUTATION = gql`
  mutation createPromoCodeBatch($prefix: String, $amount: Int!, $note: String) {
    issuePromoCodes(prefix: $prefix, amount: $amount, note: $note)
  }
`;

type Props = {
  onClose: () => void,
};

export default function CreatePromoCodeBatchModal({ onClose }: Props) {
  const [note, setNote] = useState('');
  const [prefix, setPrefix] = useState('');
  const [amount, setAmount] = useState(100);

  const [prefixError, setPrefixError] = useState('');
  const [amountError, setAmountError] = useState('');

  const [
    createMutation,
    { loading: createLoading }
  ] = useMutation(NEW_PROMO_CODE_BATCH_MUTATION);

  function createBatch() {
    let fail = false;

    setAmountError('');
    setPrefixError('');

    if (!amount) {
      setAmountError('How many codes do you want to issue?');
      fail = true;
    }

    if (amount < 1) {
      setAmountError('Amount must be at least 1');
      fail = true;
    }

    if (amount > MAX_CODES) {
      setAmountError(`Amount can be at most ${MAX_CODES}`);
      fail = true;
    }

  if (prefix.length > 10) {
      setPrefixError('Prefix length must be 10 characters or less');
      fail = true;
    }

    if (fail) { return; }

    createMutation({
      refetchQueries: ['allPromoCodeBatches'],
      awaitRefetchQueries: true,
      variables: {
        prefix: prefix === '' ? undefined : prefix,
        amount,
        note,
      }
    }).then(onClose);
  }

  return (
    <Modal handleClose={onClose}>
      <Stack fullWidth>
        <Heading>Issue promo code batch</Heading>

        <ValidatedField label="Prefix" error={prefixError}>
          <TextInput
            value={prefix}
            placeholder="Lower-case prefix for the codes, e.g. appsumo"
            onChange={v => setPrefix(v)}
          />
        </ValidatedField>

        <ValidatedField label="Amount" error={amountError}>
          <TextInput
            type="num"
            value={amount.toString()}
            onChange={v => setAmount(Number(v))}
          />
        </ValidatedField>

        <ValidatedField label="Note" error={null}>
          <TextInput
            value={note}
            onChange={v => setNote(v)}
          />
        </ValidatedField>

        <Inline>
          <Button onClick={() => createBatch()} loading={createLoading}>
            Save
          </Button>

          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Inline>
      </Stack>
    </Modal>
  );
}
