import { useParams } from 'react-router';
import SingleResponseGrid from './SingleResponseGrid';
import ConfirmButton from 'components/ConfirmButton';
import { Content, Stack, Inline } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { useMutation } from '@apollo/client';
import { UPDATE_ACTIVE_STUDY_MUTATION } from 'queries';
import useStudyResults from 'hooks/useStudyResults';
import IconLink from 'components/IconLink';

export default function Participant({ readOnly, participants, ignoredParticipantIds = [] }) {
  const { participantId } = useParams();
  const { study: { id, questions } } = useStudyResults();

  const [updateMutation, { loading }] = useMutation(UPDATE_ACTIVE_STUDY_MUTATION);

  const participant = participants.find(p => p.id === participantId);
  const included = !ignoredParticipantIds || !ignoredParticipantIds.includes(participantId);

  function setExcluded(exclude) {
    let newParticipantIds = ignoredParticipantIds.filter(id => id !== participantId);

    if(exclude) {
      newParticipantIds = newParticipantIds.concat(participantId);
    }

    updateMutation({
      variables: {
        id,
        input: {
          analysisOptions: { ignoredParticipantIds: newParticipantIds },
        },
      },
    });
  }

  return (
    <Content>
      <Stack spacing="large">
        <Stack>
          <IconLink to="../participants" icon="arrowLeft" label="Back to all participants" />
          <Inline fullWidth justify="space-between">
            <Heading>Participant {participant.num}</Heading>

            {!readOnly && (
              <ConfirmButton
                title={`${included ? 'Exclude' : 'Include'} participant ${participant.num}`}
                body={`This will cause all personas to re-generate and ${included ? 'exclude' : 'include'} this participant ${included ? 'from' : 'in'} the algorithm.`}
                ctaText={included ? 'Exclude' : 'Include'}
                onConfirm={() => setExcluded(included)}
                disabled={loading}
              >
                {included ? 'Exclude' : 'Include'}
              </ConfirmButton>
            )}
          </Inline>
          <Text size="body">
            {participant.furtherResearch
              ? 'Available for further research.'
              : null
            }
          </Text>
        </Stack>

        <SingleResponseGrid questions={questions} answers={participant.answers} />
      </Stack>
    </Content>
  )
}
