import styled from 'styled-components/macro';
import { CSVLink } from 'react-csv';
import { humanizeAnswer, highlightsToShow } from 'utils';

const UnstyledCSVLink = styled(CSVLink)`
  color: initial;
`;

function fullExport(questions, participants, personas) {
  const filteredQuestions = questions.filter(q => q.type !== 'email' && q.type !== 'screener');
  const firstEmailQuestion = questions.find(q => q.type === 'email');

  const headers = [
    'Participant',
    'Cluster',
    ...(firstEmailQuestion ? ['Email address'] : []),
    ...filteredQuestions.map(q => q.label),
  ];

  const data = participants.map(participant => {
    const persona = participant && personas?.find(p => participant.personaId === p.personaId);

    return [
      `Participant ${participant.num}`,
      persona?.name || '',
      ...(firstEmailQuestion ? [humanizeAnswer(firstEmailQuestion, participant.answers[firstEmailQuestion.id])] : []),
      ...filteredQuestions.map(q => humanizeAnswer(q, participant.answers[q.id]))
    ];
  });

  return {
    filename: 'akin-export.csv',
    headers,
    data,
  };
}

function highlightExport(questions, participants, personas) {
  const filteredQuestions = questions.filter(q => q.type === 'open');
  const firstEmailQuestion = questions.find(q => q.type === 'email');

  const headers = [
    'Participant',
    'Cluster',
    ...(firstEmailQuestion ? ['Email address'] : []),
    ...filteredQuestions.map(q => q.label),
  ]

  const data = participants.map(participant => {
    const persona = participant && personas?.find(p => participant.personaId === p.personaId);

    return [
      `Participant ${participant.num}`,
      persona?.name || '',
      ...(firstEmailQuestion ? [humanizeAnswer(firstEmailQuestion, participant.answers[firstEmailQuestion.id])] : []),
      ...filteredQuestions.map(q => (
        highlightsToShow(
          participant.highlights?.[q.id],
          participant.algoHighlights?.[q.id],
          participant.removedHighlights?.[q.id],
        ).join(',')
      )),
    ];
  });

  return {
    filename: 'akin-highlights.csv',
    headers,
    data,
  };
}

const REPORTS = {
  full: fullExport,
  highlights: highlightExport,
}

export default function CsvDownloader({ report, questions, participants, personas, children }) {
  const { filename, headers, data } = REPORTS[report](questions, participants, personas);

  return (
    <UnstyledCSVLink
      variant="secondary"
      filename={filename}
      data={data}
      headers={headers}
    >
      {children}
    </UnstyledCSVLink>
  );
}
