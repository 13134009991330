import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const ToggleWrapper = styled(motion.div)`
  width: 44px;
  height: 24px;
  padding: 3px 4px;
  display: flex;
  justify-content: ${p => p.checked ? 'flex-end' : 'flex-start'};
  align-items: center;
  border-radius: 100px;
  background-color: ${p => p.checked ? p.theme.colors.brand : p.theme.colors.dark};
  cursor: pointer;
  transition: background-color 0.2s;
`;

const ToggleKnob = styled(motion.div)`
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: white;
`;

export default function Toggle({ className, checked, onChange }) {
  return (
    <ToggleWrapper className={className} onClick={() => onChange(!checked)} checked={checked}>
      <ToggleKnob layout />
    </ToggleWrapper>
  );
}
