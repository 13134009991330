import { useCallback, useContext } from 'react';
import { generateId } from 'utils/generateId';
import NotificationContext from 'NotificationContext';

const DEFAULT_TIMEOUT = 4000;

export default function useNotifications() {
const dispatch = useContext(NotificationContext);

  const notify = useCallback(({ tag, message, cta, timeout = DEFAULT_TIMEOUT }) => {
    const id = generateId('n');

    dispatch({ type: 'notify', tag, id, message, cta });

    if (timeout !== null) {
      setTimeout(
        () => dispatch({ type: 'clear', id }),
        timeout
      );
    }
  }, [dispatch]);

  return notify;
}
