import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Heading, { Props as HeadingProps } from 'atoms/Heading';

interface GridWrapperProps {
  rowTemplate: string[]
  rowGap: number
  columnGap: number
}

const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: ${p => p.rowTemplate?.join(' ') ?? '1fr'};
  row-gap: ${p => p.rowGap}px;
  column-gap: ${p => p.columnGap}px;
`;

const Line = styled.span`
  grid-column: 1 / -1;
  height: 2px;
  border-bottom: 1px solid ${p => p.theme.colors.dark};
`;

interface GridHeaderProps {
  align?: HeadingProps['align']
  children?: ReactNode
}

function Header({ children, align }: GridHeaderProps) {
  return <Heading align={align} level={5}>{children}</Heading>
}

const alignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

interface GridCellProps {
  align?: keyof typeof alignMap
  spanAll?: boolean
}

const Cell = styled.div<GridCellProps>`
  display: flex;
  justify-content: ${p => alignMap[p.align || 'left']};
  align-items: center;
  font-size: 14px;
  ${p => p.spanAll ? 'grid-column: 1 / -1;' : null};
`;

export interface Props {
  rowTemplate: string[],
  rowGap?: number
  columnGap?: number
  children: ReactNode
  className?: string
}

export default function Grid({ rowTemplate, rowGap = 15, columnGap = 20, className, children } : Props) {
  return (
    <GridWrapper
      rowTemplate={rowTemplate}
      rowGap={rowGap}
      columnGap={columnGap}
      className={className}
    >
      {children}
    </GridWrapper>
  );
}

Grid.Line = Line;
Grid.Cell = Cell;
Grid.Header = Header;
