import { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as UploadIcon } from 'images/icons/upload.svg';
import Text from 'atoms/Text';
import { Stack } from 'components/atoms/layout';
import Loader from 'components/Loader';

const Zone = styled.div`
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
  background-color: #FAF8F7;
  border-radius: ${p => p.theme.borderRadius};
  color: ${p => p.hovering ? p.theme.colors.brand : p.theme.colors.dark};
  ${p => !p.disabled && 'cursor: pointer;'}

  ${Text} {
    color: ${p => p.hovering ? 'inherit' : p.theme.colors.text};
  }
`;

export default function DropZone({ message, handleUpload, loading, disabled }) {
  const [hovering, setHovering] = useState(false);
  const fileInputRef = useRef();

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
    e.preventDefault();
    if (disabled) return;

    setHovering(true);
  }

  const dragLeave = (e) => {
    e.preventDefault();
    if (disabled) return;

    setHovering(false);
  }

  const fileDrop = (e) => {
    e.preventDefault();
    if (disabled) return;

    setHovering(false);
    const files = e.dataTransfer.files;

    if (files.length) {
      handleUpload(files);
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleUpload(fileInputRef.current.files);
    }
  }

  return (
    <Zone
      hovering={hovering}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      onClick={() => {
        if (disabled) return;
        fileInputRef.current.click()
      }}
      disabled={disabled}
    >
      {loading
        ? <Loader />
        : (
          <Stack fullWidth alignItems="center" spacing="small" style={{ pointerEvents: 'none' }}>
            <UploadIcon />
            <Text strong>{message}</Text>
          </Stack>
        )
      }

      <input style={{ display: 'none' }} ref={fileInputRef} type="file" accept=".csv" onChange={filesSelected} />
    </Zone>
  );
}
