import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as CheckedSvg } from 'images/radio-check.svg';
import { ReactComponent as UncheckedSvg } from 'images/radio-base.svg';
import { ReactComponent as FocusSvg } from 'images/radio-focus.svg';

export const RadioList = styled.ul`
  margin-left: -16px;
  padding-left: 10px;
  border-left: 6px solid ${p => p.error ? p.theme.colors.error : 'transparent'};
  transition: 0.4s border-color;
`;

const RadioListItem = styled.li`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const RadioLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
`;

const checkAnimation = keyframes`
  0% { transform: scale(0) }
  100% { transform: scale(1) }
`;

const RadioInput = styled.input`
  position:absolute;
  clip: rect(0,0,0,0);
  clip: rect(0 0 0 0);
`

const Base = styled(UncheckedSvg)`
  margin-right: 10px;
  min-width: 36px;
`;

const Check = styled(CheckedSvg)`
  position: absolute;
  left: 0;
  display: none;

  ${RadioInput}:checked ~ & {
    display: block;
  }

  animation: ${checkAnimation} 0.1s ease-in-out;
`;

const Focus = styled(FocusSvg)`
  position: absolute;
  left: 0;
  display: none;

  ${RadioInput}:focus ~ & {
    display: block;
  }
`;

function setFocus(e) {
  // Safari doesn't focus the input on click for some reason
  e.target.focus();
}

export function Radio({ children, ...props }) {
  return (
    <RadioListItem>
      <RadioLabel>
        <RadioInput onClick={setFocus} type="radio" { ...props }/>
        <Base />
        <Focus />
        <Check />
        {children}
      </RadioLabel>
    </RadioListItem>
  );
}
