
import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from 'styled-components/macro';
import convexHull from 'monotone-convex-hull-2d';

export default function GalaxyLoader({ delay = 1.5, ...svgProps }) {
  const [showPolygons, setShowPolygons] = useState(false);
  const [colorIndex, setColorIndex] = useState(0);

  const { colors: { clusters: clusterColors }} = useContext(ThemeContext);
  const [points, setPoints] = useState(() => {
    return new Array(10).fill(null).map((_,i) => {
      return [
        5 + Math.random() * 90,
        5 + Math.random() * 90,
      ]
    });
  });

  useEffect(() => {
    let tick = 0;

    const i = setInterval(() => {
      if (tick % 4 === 0) {
        setShowPolygons(true);
      } else if (tick % 4 === 2) {
        setShowPolygons(false);
      } else if (tick % 4 === 3) {
        setPoints(oldPoints => {
          return oldPoints.map(([x,y], i) => {
            return [Math.random()*90 + 5, Math.random()*90 + 5];
          });
        });
        setColorIndex(v => (v + 1) % clusterColors.length);
      }

      tick +=1 ;
    }, delay / 4 * 1000);

    return () => clearInterval(i);
  }, [clusterColors.length, delay]);

  const hull = convexHull(points).map(i => points[i]);

  return (
    <svg viewBox="0 0 102 102" {...svgProps}>
      <g transform="translate(1, 1)">
        <motion.polygon
          points={hull.map(p => p.join(',')).join(' ')}
          animate={{
            fill: clusterColors[colorIndex],
            opacity: showPolygons ? 0.5 : 0,
          }}
          transition={{ duration: delay / 4 }}
        />

        {points.map(([x, y], participantIndex) => {
          return (
            <motion.circle
              key={`star-${participantIndex}`}
              animate={{
                cx: x,
                cy: y,
                r: 2,
                opacity: 1,
                fill: clusterColors[colorIndex],
              }}
              transition={{ duration: delay / 4 }}
            />
          );
        })}
      </g>
    </svg>
  );
}
