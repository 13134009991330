import { Stack, NotMobile, Spacer } from 'components/atoms/layout';
import { PageTitle, CtaButton, Emphasis, LargeImage, MarketingContent } from 'components/atoms/marketing';
import Banner from 'components/marketing/Banner';
import MarketingComponent from 'components/marketing/MarketingComponent';
import Text from 'atoms/Text';
import ClusterBackground from 'components/marketing/ClusterBackground';

// Only supports zero or one emphasized section, which is fine for now
function addEmphasis(string) {
  const [head, emph, tail] = string.split('*');

  if (!emph) { return head; }

  return <>{head} <Emphasis>{emph}</Emphasis> {tail}</>;
}

export default function MarketingPage({ content, triggerSignup }) {
  return (
    <>
      <NotMobile>
        <Spacer size="large" />
      </NotMobile>

      <Stack fullWidth spacing="none" mobileSpacing="small">
        <MarketingContent>
          <ClusterBackground>
            <Stack fullWidth spacing="medium">
              <PageTitle>
                {addEmphasis(content.heading)}
              </PageTitle>

              {content.subheading && <Text size="xlarge">{addEmphasis(content.subheading)}</Text>}

              <NotMobile>
                <CtaButton onClick={triggerSignup}>Sign up for free</CtaButton>
              </NotMobile>
            </Stack>
          </ClusterBackground>
        </MarketingContent>

        {content.blocks.map((block, index) => (
          block.body ? (
            <MarketingComponent
              key={block.title || index}
              altBackground={index % 2 === 0}
              title={addEmphasis(block.title)}
              text={block.body}
              image={block.image}
              imageAlt={block.imageAlt}
              ctaLabel={block.ctaText}
              ctaLink={block.ctaTarget}
            />
          ) : ( // todo: altBackground? also maybe a type instead of checking .body?
            <LargeImage src={block.image} alt={block.imageAlt} />
          )
        ))}
      </Stack>

      <NotMobile style={{ width: '100%' }}>
        <Banner triggerSignup={triggerSignup} />
      </NotMobile>
    </>
  );
}
