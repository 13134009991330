import { useState } from 'react';
import { Fragment } from 'react';
import styled from 'styled-components/macro';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import { Content, Stack, Inline, Spacer } from 'components/atoms/layout';
import Loader from 'components/Loader';
import Button from 'atoms/Button';
import TextInput from 'atoms/TextInput';
import { ReactComponent as CheckedSvg } from 'images/checkbox-check.svg';
import { format, parseISO } from 'date-fns';
import Link from 'atoms/Link';

const ErrorMessage = styled.label`
  color: ${p => p.theme.colors.error};
  font-weight: bold;
`;

const ALL_USERS_QUERY = gql`
  query allUsers($limit: Int, $after: String, $search: String) {
    allUsers(limit: $limit, after: $after, search: $search) {
      users {
        id
        email
        roles
        createdAt
        lastLoggedInAt
        settings {
          marketingOptIn
        }
        studyCount
      }
      after
    }
  }
`;

function formatDate(isoString) {
  if (!isoString) { return ''; }

  return format(parseISO(isoString), 'dd/MM/yy');
}

export default function AdminUserList() {
  const [search, setSearch] = useState('');

  const { data, loading, error, networkStatus, fetchMore } = useQuery(ALL_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { limit: 20, search },
  });

  return (
    <Content style={{ minHeight: '100%' }}>
      <Stack>
        <Heading>All users</Heading>

        <TextInput
          placeholder="filter email address"
          onEnter={value => {
            // TODO: need to clear cache here if they search for something twice it adds on to the list!!
            fetchMore({ variables: { search: value }}).then(() => { setSearch(value) });
          }}
        />

        {loading && networkStatus !== NetworkStatus.fetchMore && (
          <>
            <Spacer />
            <Loader />
          </>
        )}

        {error && (
          <ErrorMessage>
            Error retrieving data: {error.graphQLErrors.map(e => e.message)}
          </ErrorMessage>
        )}

        {data && (
          <Stack>
            <Grid rowTemplate={['1fr', '40px', '40px', '70px', '70px', '70px']}>
              <Grid.Header>Email address</Grid.Header>
              <Grid.Header>Studies</Grid.Header>
              <Grid.Header>Plan</Grid.Header>
              <Grid.Header>Signed up</Grid.Header>
              <Grid.Header>Last login</Grid.Header>
              <Grid.Header>Marketing</Grid.Header>
              <Grid.Line />

              {data.allUsers.users.map((u, i) => (
                <Fragment key={`${u.id}-${i}`}>
                  <Grid.Cell>
                    <Link to={`/admin/user/${u.id}`}>{u.email}</Link>
                  </Grid.Cell>
                  <Grid.Cell>{u.studyCount}</Grid.Cell>
                  <Grid.Cell>{u.roles.includes('pro_plan_active') ? 'Pro' : 'Free'}</Grid.Cell>
                  <Grid.Cell>{formatDate(u.createdAt)}</Grid.Cell>
                  <Grid.Cell>{formatDate(u.lastLoggedInAt)}</Grid.Cell>
                  <Grid.Cell align="center">{u.settings.marketingOptIn ? <CheckedSvg width="13" height="13" /> : null}</Grid.Cell>
                </Fragment>
              ))}
            </Grid>

            {data.allUsers.after && (
              <Inline fullWidth justify="flex-end">
                <Button
                  variant="secondary"
                  onClick={() => fetchMore({ variables: { after: data.allUsers.after }}) }
                  loading={networkStatus === NetworkStatus.fetchMore}
                >
                  Load more
                </Button>
              </Inline>
            )}
          </Stack>
        )}
      </Stack>
    </Content>
  );
}
