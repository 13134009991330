import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

type GridProps = {
  featuredTile: boolean
};

const Grid = styled(motion.div)<GridProps>`
  display: grid;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 140px);
  grid-auto-rows: 140px;
  grid-gap: 23px;


  ${p => p.featuredTile ? `
    > :first-child {
      grid-row: span 2;
      grid-column: 1 / span 2;
    }
  ` : null}

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 140px);
  }
`;

const gridVariants = {
  hidden: {
  },
  show: {
    transition: {
      staggerChildren: 0.1
    },
  },
};

type Props = {
  featuredTile?: boolean,
  children: ReactNode,
};

export default function TileGrid({ featuredTile = false, children }: Props) {
  return (
    <Grid
      variants={gridVariants}
      initial="hidden"
      animate="show"
      featuredTile={featuredTile}
    >
      {children}
    </Grid>
  );
}
