import styled from 'styled-components/macro';
import { Stack, Inline } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import useRequestAnalysis from 'hooks/useRequestAnalysis';
import useStudyResults from 'hooks/useStudyResults';

const Message = styled.div`
  margin: 40px auto 0 auto;
  max-width: ${p => p.wide ? '500px' : '400px'};
  height: fit-content;
  padding: 25px;
  border-radius: ${p => p.theme.borderRadius};
  box-shadow: ${p => p.theme.shadows.standard};
  background-color: ${p => p.theme.colors.background};
  z-index: 1;
`;

export default function AnalysisFailed() {
  const { study: { id }} = useStudyResults();
  const [requestAnalysis, { loading }] = useRequestAnalysis(id, 4); // TODO: numClusters

  return (
    <Message wide>
      <Stack>
        <Inline fullWidth justify="center">
          <Heading level={3}>Analysis failed!</Heading>
        </Inline>
        <Text size="body">
          The analysis failed.
          If this persists, please get in touch with the icon at the bottom right
          and we'll fix things up for you.
        </Text>

        <Inline fullWidth justify="center">
          <Button
            onClick={requestAnalysis}
            loading={loading}
          >
            Retry it
          </Button>
        </Inline>
      </Stack>
    </Message>
  );
}
