import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'components/ErrorPage';

function ErrorDisplay({ error, resetErrorBoundary }) {
  return (
    <ErrorPage unrecoverable />
  );
}

export default function ErrorBoundary({ children, error, resetErrorBoundary }) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorDisplay}
    >
      {children}
    </ReactErrorBoundary>

  );
}
