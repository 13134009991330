import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Island } from 'components/atoms/layout';
import logoImage from 'images/logo.svg';
import { useHistory } from 'react-router';

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${p => p.theme.colors.background};
  z-index: 4;
`;

const Logo = styled(motion.img)`

`;

export default function WelcomeToAkin() {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => history.push('/studies'), 2500);
  }, [history]);

  return (
    <Wrapper
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ delay: 2, duration: 0.5 }}
    >
      <Island>
        <Logo
          src={logoImage}
          width={200}
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 1.5, duration: 0.5 }}
        />
      </Island>
    </Wrapper>
  );
}
