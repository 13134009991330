import React, { useState } from 'react';
import DistributionBar from './DistributionBar';
import styled from 'styled-components/macro';
import Grid from 'atoms/Grid';
import Text from 'atoms/Text';

const DistributionWrapper = styled(Grid)`
  position: relative;
  grid-column: 1/5;
`;

const DistributionAxis = styled.div`
  position: absolute;

  left: ${p => p.xPos};
  width: 1px;
  height: 100%;
  background-color: ${p => p.color ?? p.theme.colors.dark};
`;

export default function DistributionGrid({ color, distribution }) {
  const [hovering, setHovering] = useState(null);

  const maxValue = distribution && Math.max(...distribution.map(([,,n]) => n));

  return (
    <DistributionWrapper rowGap={8} rowTemplate={['1fr', '1fr']}>
      <DistributionAxis xPos={0} />
      <DistributionAxis xPos="51.5%" color={`${color ?? '#000000'}33`} />

      {distribution && distribution.map(([optionIndex, optionLabel, n]) => {
        return (
          <React.Fragment key={optionIndex}>
            <Grid.Cell style={{ paddingLeft: 20 }}>
              <Text strong={hovering === optionIndex}>{optionLabel}</Text>
            </Grid.Cell>

            <DistributionBar
              deemphasize={hovering !== null && hovering !== optionIndex}
              onMouseOver={() => setHovering(optionIndex)}
              onMouseOut={() => setHovering(null)}
              color={color} value={n} maxValue={maxValue}
            />
          </React.Fragment>
        );
      })}
    </DistributionWrapper>
  )
}
