import styled from 'styled-components/macro';
import Label from 'components/study/results/galaxy/Label';
import convexHull from 'monotone-convex-hull-2d';

const ClusterBackdrop = styled.polygon`
  cursor: pointer;
`;

export default function Cluster({ persona, hovered, otherFocused, focused, handleClick, setHoveredPersona }) {
  const points = persona.points.map(p => [p.x*10, p.y*10]);
  const hull = convexHull(points).map(i => points[i]);

  return (
    <>
      <ClusterBackdrop
        points={hull.map(p => p.join(',')).join(' ')}
        fill={`${persona.color}${otherFocused ? '07' : ((focused || hovered) ? 33 : 19)}`}
        color={persona.color}
        onClick={handleClick}
        onMouseOver={() => setHoveredPersona(persona.personaId)}
        onMouseOut={e => {
          // Keep it hovered when hovering over "child" stars
          if (e.relatedTarget?.id === 'gridrect') {
            setHoveredPersona(null);
          }
        }}
      />
      {hovered && (
        <Label
          x={persona.bounds.x}
          y={persona.bounds.y + persona.bounds.height}
          // showBackground={!childHovered}
          text={[persona.name]}
          color={persona.color}
        />
      )}
    </>
  );
}
