import { Button } from 'atoms';

function redirectToSurveyMonkey(inOnboardingFlow: boolean) {
  const params = new URLSearchParams({
    redirect_uri: process.env.REACT_APP_SURVEY_MONKEY_REDIRECT_URI!,
    client_id: process.env.REACT_APP_SURVEY_MONKEY_CLIENT_ID!,
    response_type: 'code',
    state: inOnboardingFlow ? 'onboarding' : 'existing',
  });
  window.location.href = `https://api.surveymonkey.com/oauth/authorize?${params.toString()}`
}

type Props = {
  inOnboardingFlow?: boolean,
}

export default function SurveyMonkeyConnect({ inOnboardingFlow = false }: Props) {
  return (
    <Button onClick={() => redirectToSurveyMonkey(inOnboardingFlow)}>
      Connect
    </Button>
  );
}
