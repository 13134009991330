import React from 'react';
import { Stack } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';

export default function Instructions({ title, message, children }) {
  return (
    <Stack fullWidth alignItems="center" spacing="large" style={{ marginTop: 40 }}>
      <Stack spacing="small">
        <Heading>{ title }</Heading>
        <Text style={{ maxWidth: 400 }}>{ message }</Text>
      </Stack>

      {children}
    </Stack>
  );
}
