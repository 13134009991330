export const DEFAULT_THEME = {
  marketingBackgroundColor: '#F9F6F3', // TODO: replace with colors.dark below
  marketingAltBackgroundColor: '#FBFBFB',

  navbarHeight: 40,
  previewWidth: '450px',
  sidebarWidth: '240px',
  borderRadius: '5px',

  colors: {
    brand: '#E63C36',
    brandLight: '#F4C4C0',
    highlight: '#FCC534',
    tertiary: '#672727',
    text: '#1D1313',
    textInvert: '#FFFFFF',
    dark: '#E8E1D4',
    error: '#DB544D',
    warning: '#F7C373',
    success: '#558F79',

    background: '#F9F6F3',
    accentBackground: '#FBFBFB',

    modalOverlay: 'rgba(37, 40, 43, 0.15)',

    // GOTCHA: these _have_ to be hex codes because of some thug alpha channel manipulation we're doing
    clusters: [
      '#E85331',
      '#F7C373',
      '#558F79',
      '#4C686D',
      '#F7747C',
      '#6A96A6',
      '#7DC6BB',
      '#392F43',
      '#C9B584',
      '#7B7464',
    ],
  },

  breakpoints: {
    tablet: '800px',
    desktop: '1100px',
  },

  iconSizes: {
    small: '12px', // This fits into an IconButton
    medium: '18px',
  },

  fonts: {
    title: 'Lark-Medium, serif',
    body: 'Ubuntu, sans-serif',
    serif: 'Lark-Medium, serif',
    marketing: 'Ubuntu, sans-serif',
    monospace: '"Fira Code Mono", monospace',
  },

  shadows: {
    standard: '-1px -1px 3px #FFFFFF, 1.5px 1.5px 3px #AEAEC066',
    large: '0px 4px 4px 0px #00000040',
  },

  spacing: {
    none: '0',
    xsmall: '7.5px',
    small: '15px',
    medium: '30px',
    mlarge: '45px',
    large: '60px',
    xlarge: '90px',
    xxlarge: '120px',
    xxxlarge: '200px',
  },

  headingSizes: {
    1: '64px',
    2: '40px',
    3: '24px',
    4: '20.8px',
    5: '12px',
  },

  headingLineHeights: {
    1: '75.78px',
    2: '47.36px',
    3: '27.58px',
    4: '23.9px',
    5: '13.79px',
  },

  fontSizes: {
    small: '12px',
    body: '16px',
    large: '20px',
    xlarge: '24px',
    xxlarge: '30px',
  },

  lineHeights: {
    xsmall: '16px',
    small: '16px',
    medium: '20px',
    large: '27px',
    xlarge: '28px',
    xxlarge: '192.9%'
  },
}
