import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { motion, animate } from 'framer-motion'

const BAR_WIDTH = 200;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BarLabel = styled.label`
  margin-left: 5px;

  *:hover + & {
    font-weight: 500;
  }
`;

const Bar = styled(motion.div)`
  height: 15px;
  background-color: ${p => p.color ? p.color : p.theme.colors.text}${p => p.deemphasize ? '33' : 'ff' };
  border-radius: 0px 2px 2px 0px;

  & + ${BarLabel} {
    display: ${p => p.deemphasize ? 'none' : 'initial' };
  }
`;

export default function DistributionBar({ value, maxValue, color, deemphasize, ...otherProps }) {
  const labelRef = useRef();

  useEffect(() => {
    const label = labelRef.current;
    if (!label) { return; }

    const controls = animate(0, value, {
      duration: 0.4,
      onUpdate(v) {
        label.textContent = v.toFixed(0);
      }
    });

    return () => controls.stop();
  }, [value]);

  return (
    <BarWrapper>
      <Bar
        initial={{ width: 0 }}
        animate={{ width: (value / maxValue) * BAR_WIDTH }}
        color={color}
        deemphasize={deemphasize}
        {...otherProps}
      />
      {value > 0 && <BarLabel ref={labelRef}>{ value }</BarLabel>}
    </BarWrapper>
  );
}
