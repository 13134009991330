import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as EllipsisIcon } from 'images/icons/ellipsis.svg';
import useIsMounted from 'hooks/useIsMounted';
import { Stack } from 'components/atoms/layout';
import IconLink from 'components/IconLink';
import Icon from 'atoms/Icon';
import Inline from 'atoms/Inline';

const DropdownLink = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;

  cursor: pointer;
`;

const DropdownText = styled.span`
  ${p => p.open && 'font-weight: 700;'}

  ${DropdownLink}:hover & {
    font-weight: 600;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
`;

const slideUp = keyframes`
  from { transform: translateY(25%); }
`;

const slideDown = keyframes`
  from { transform: translateY(-25%); }
`;

const DropdownMenu = styled(motion.div)`
  position: absolute;
  width: ${p => p.fixedWidth ? `${p.fixedWidth}px` : 'fit-content'};
  max-height: 200px;
  ${p => p.xDirection === 'left' ? `left: ${p.xOffset}px`: `right: ${p.xOffset}px`};
  ${p => p.direction === 'up' ? `bottom: ${30+p.yOffset}px` : `top: ${54+p.yOffset}px`};
  background-color: ${p => p.theme.colors.accentBackground};
  box-shadow: ${p => p.theme.shadows.standard};
  border-radius: ${p => p.theme.borderRadius};
  animation: ${fadeIn} 0.4s ease forwards,
    ${p =>p.direction  === 'up' ? slideUp : slideDown} 0.12s cubic-bezier(.6,1.3,.69,1.05) forwards;
  z-index: 2;
  overflow-y: auto;

  ${p => p.fullWidth ? 'width: 100%;' : ''}

  &:focus {
    outline: none;
  }
`;

const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${p => p.extraPadding ? 'padding: 30px;' : ''};
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownIcon = styled(EllipsisIcon)`
  border-radius: ${p => p.theme.borderRadius};

  &:hover {
    background-color: ${p => p.theme.colors.dark};
  }
`;

export default function Dropdown({ linkText, direction, xDirection = 'right', items, xOffset = 0, yOffset = 0, fixedWidth, children, extraPadding, fullWidth }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  });

  const delayedClose = () => {
    setTimeout(
      () => {
        if (isMounted) {
          setIsOpen(false);
        }
      }
    , 200);
  }

  return (
    <DropdownWrapper onClick={e => e.stopPropagation()} >
      <DropdownLink onClick={e => setIsOpen(c => !c) }>
        {typeof linkText === 'function'
          ? (
            <Inline align="center" spacing="xsmall">
              <DropdownText open={isOpen}>
                {linkText(isOpen)}
              </DropdownText>
              <Icon i="chevron" rotation={isOpen ? 180 : 0} />
            </Inline>
          ) : <DropdownIcon/>
        }
      </DropdownLink>

      {isOpen && (
        <DropdownMenu
          fullWidth={fullWidth}
          fixedWidth={fixedWidth}
          ref={dropdownRef}
          tabIndex={0}
          onBlur={delayedClose}
          direction={direction}
          xDirection={xDirection}
          xOffset={xOffset}
          yOffset={yOffset}
          onTapStart={e => { e.stopPropagation(); }}
        >
          <DropdownList extraPadding={extraPadding}>
            <Stack spacing="small">
              {children || items.filter(item => item).map(([label, target, icon], i) => (
                typeof target === 'function'
                  ? (
                    <IconLink
                      key={`${label}-${i}`}
                      icon={icon}
                      size="small"
                      label={label}
                      onClick={() => {target(); setIsOpen(false); }}
                    />
                  ) : (
                    <IconLink key={`${label}-${i}`} to={target}>
                      {icon}
                      {label}
                    </IconLink>
                  )
              ))}
            </Stack>
          </DropdownList>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
}
