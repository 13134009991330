import { useState, ReactNode } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useHistory, Redirect } from 'react-router-dom';
import { Stack, Inline, Button, Text, Heading, TextInput } from 'atoms';
import Modal from 'components/Modal';
import SourceTile from 'components/create/SourceTile';
import TileGrid from 'components/TileGrid';
import UploadStudy from 'components/create/UploadStudy';
import Loader from 'components/Loader';
import SurveyMonkeyConnect from 'components/create/SurveyMonkeyConnect';
import SurveyMonkeySurveys from 'components/create/SurveyMonkeySurveys';
import ConfirmAccount from 'components/create/ConfirmAccount';
import { useAuth, useNotifications, useLocalStorage } from 'hooks';
import { CREATE_STUDY_MUTATION, MY_STUDIES_QUERY } from 'queries';

const ACTIVE_INTEGRATIONS_QUERY = gql`
  query activeIntegrations {
    me {
      activeIntegrations
    }
  }
`;

type StudyStep = 'choose_source' | 'import_study' | 'name_study' | 'upload_file' | 'confirm_email' | 'success' | 'coming_soon';
type Source = 'Akin' | 'CsvUpload' | 'SurveyMonkey' | 'GoogleForms' | 'Typeform';

type Props = {
  initialStep?: StudyStep;
  handleClose: () => void;
  inOnboardingFlow?: boolean;
};

function initialStepForSource(source: Source) {
  if (source === 'Akin') {
    return 'name_study';
  } else if (['GoogleForms', 'Typeform'].includes(source)) {
    return 'coming_soon';
  } else {
    return 'import_study';
  }
}

export default function NewStudy({ initialStep = 'choose_source', inOnboardingFlow, handleClose }: Props) {
  const history = useHistory();
  const { roles } = useAuth();
  const params = new URLSearchParams(window.location.search);

  const notify = useNotifications();
  const [confirmedEmail, setConfirmedEmail] = useState<string | null>();
  const [, setLastVisitedSection] = useLocalStorage('lastVisitedSetion', 'introduction');

  const initialSource = params.get('integration') as Source | undefined;
  const actualInitialStep = initialStep === 'choose_source' && initialSource
    ? initialStepForSource(initialSource)
    : initialStep;

  const [step, setStep] = useState<StudyStep>(actualInitialStep);
  const [source, setSource] = useState<Source | undefined>(initialSource);
  const [studyName, setStudyName] = useState('');

  const { loading: integrationsLoading, data: integrationsData } = useQuery(ACTIVE_INTEGRATIONS_QUERY, {
    skip: source && ['Akin', 'CsvUpload'].includes(source),
  });
  const activeIntegrations = integrationsData?.me.activeIntegrations;

  const [createNewStudy, { loading }] = useMutation(CREATE_STUDY_MUTATION, {
    refetchQueries: [{ query: MY_STUDIES_QUERY, variables: { userId: null }}],
    onCompleted: data => {
      surveyImported(data.createStudy.id);
    },
    onError: ({ message }) => notify({ message }),
  });

  const createStudy = () => {
    createNewStudy({
      variables: { name: studyName }
    });
  }

  const surveyImported = (studyId: string) => {
    if (roles.includes('placeholder')) {
      setStep('confirm_email');
    } else {
      setLastVisitedSection('introduction');
      history.push(`/study/${studyId}`);
    }
  }

  const voteForFeature = () => {
    notify({ message: 'TODO: do something here' });
    handleClose();
  }

  const chooseSource = (source: Source) => {
    setSource(source);
    setStep(initialStepForSource(source));
  }

  const backToStart = () => {
    if (inOnboardingFlow) {
      handleClose();
    } else {
      setStep('choose_source');
    }
  }

  let content: ReactNode;
  let title: string | null = null;

  if (step === 'choose_source') {
    title = 'Start using akin';

    content = (
      <Stack fullWidth>
        <Text>
          How would you like to get started analysing your survey results?
        </Text>

        <TileGrid>
          <SourceTile onClick={() => chooseSource('Akin')}>
            FROM SCRATCH
          </SourceTile>

          <SourceTile onClick={() => chooseSource('CsvUpload')}>
            UPLOAD CSV
          </SourceTile>

          <SourceTile onClick={() => chooseSource('SurveyMonkey')}>
            SURVEY MONKEY
          </SourceTile>

          <SourceTile
            onClick={() => chooseSource('GoogleForms')}
            bannerText="Coming soon"
          >
            GOOGLE FORMS
          </SourceTile>

          <SourceTile
            onClick={() => chooseSource('Typeform')}
            bannerText="Coming soon"
          >
            TYPEFORM
          </SourceTile>
        </TileGrid>
      </Stack>
    );
  } else if (step === 'name_study') {
    title = 'Name your survey';
    content = (
      <>
        <Stack fullWidth spacing="xsmall">
          <Text size="small">Survey name</Text>
          <TextInput
            borderless
            autoFocus
            value={studyName}
            onChange={v => setStudyName(v)}
            onEnter={createStudy}
          />
        </Stack>

        <Inline spacing="small">
          <Button onClick={createStudy} loading={loading} >
            Create
          </Button>

          <Button variant="secondary" onClick={backToStart}>
            Back
          </Button>
        </Inline>
      </>
    );
  } else if (step === 'import_study') {
    if (source === 'CsvUpload') {
      title = 'Upload survey';
      content = <UploadStudy onSuccess={surveyImported} handleBack={backToStart} />;
    } else if (integrationsLoading) {
      content = <Loader />
    } else {
      if (source === 'SurveyMonkey') {
        if (activeIntegrations && activeIntegrations.includes('SurveyMonkey')) {
          title = 'Choose a survey'
          content = <SurveyMonkeySurveys onSuccess={surveyImported} handleBack={backToStart} />
        } else {
          title = 'Connect to Survey Monkey'
          content = <SurveyMonkeyConnect inOnboardingFlow={inOnboardingFlow} />
        }
      } else {
        title = `Unknown source: ${source}`;
        content = 'Hmm'
      }
    }
  } else if (step === 'confirm_email') {
    title = 'Success! Create an account to access it';
    content = <ConfirmAccount onSuccess={email => {setConfirmedEmail(email); setStep('success')}}/>
  } else if (step === 'success') {
    title = 'Hooray! success! Check your email for the secure log in link to your study';
    content = (
      <>
        <Text>We emailed a link to sign in with to {confirmedEmail}.</Text>
      </>
    );
  } else if (step === 'coming_soon') {
    title = 'We have a roadmap? Want this on it?';
    content = (
      <>
        <Text>
          TODO: capture email or something?
        </Text>

        <Inline spacing="small">
          <Button onClick={voteForFeature}>
            Vote
          </Button>

          <Button variant="secondary" onClick={backToStart}>
            Back
          </Button>
        </Inline>
      </>
    );
  }

  if (content) {
    return (
      <Modal handleClose={handleClose}>
        <Stack fullWidth>
          {title ? (
            <Inline fullWidth justify="center">
              <Heading>{title}</Heading>
            </Inline>
          ) : null}

          {content}
        </Stack>
      </Modal>
    );
  }

  // Shouldn't happen, but as a failsafe!
  return <Redirect to="/studies" />;
}
