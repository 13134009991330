import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import ReactGA from 'react-ga'
import { Emphasis } from 'components/atoms/marketing';
import MarketingComponent from 'components/marketing/MarketingComponent';
import { REFRESH_TOKEN_MUTATION } from 'queries';
import useAuth from 'hooks/useAuth';
import computerImage from 'images/marketing/computer_image.png';
import qs from 'query-string';
import apiCall from 'utils/apiCall';

export default function PlanUpgradeSuccess() {
  const { setToken } = useAuth();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onCompleted: r => setToken(r.refreshToken),
  });

  useEffect(() => {
    ReactGA.plugin.require('ecommerce');

    setTimeout(() => {
      const { session_id } = qs.parse(window.location.search);

      if (!session_id) { return; }

      apiCall(`checkout?session_id=${session_id}`)
        .then(r => r.json())
        .then(({ total }) => {
          ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: session_id,
            revenue: total
          });

          ReactGA.plugin.execute('ecommerce', 'addItem', {
            id: session_id,
            name: 'Akin Pro',
            price: total,
            quantity: '1'
          });

          ReactGA.plugin.execute("ecommerce", "send", null);
          ReactGA.plugin.execute("ecommerce", "clear", null);

          // ReactGA.event({
          //   category: 'event',
          //   action: 'purchase',
          //   value: total / 100,
          //   currency: 'USD',
          //   shipping: 0,
          // });
        }).catch(e => console.error(e));
    }, 3000);
  }, []);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  return (
    <>
      <MarketingComponent
        altBackground
        title={<>Welcome to the <Emphasis>pro</Emphasis> plan</>}
        text="Thank you for supporting us. Enjoy your unlimited studies, uploads, participants, and calibrations."
        image={computerImage}
        imageAlt="Picture of an old-timey computer"
      />
    </>
  );
}
