import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { REFRESH_TOKEN_MUTATION } from 'queries';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader'

export default function ReturnFromStripe() {
  const { setToken } = useAuth();
  const history = useHistory();

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onCompleted: r => {
      setToken(r.refreshToken);
      history.push('/');
    },
  });

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  return (
    <Loader />
  );
}
