import styled from 'styled-components/macro';
import NavBar from 'components/NavBar';

const PageBackground = styled.div`
  height: 100%;
`;

const PageWithNav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
`;

export default function Dashboard({ children }) {
  return (
    <PageBackground>
      <NavBar />

      <PageWithNav>
        {children}
      </PageWithNav>
    </PageBackground>
  );
}
