import FunctionLink from 'atoms/FunctionLink';
import Link from 'atoms/Link';
import Inline from 'atoms/Inline';
import Icon, { Props as IconProps } from 'atoms/Icon';

export interface Props {
  icon?: IconProps['i']
  label: string
  onClick?: () => {}
  to: string
}

export default function IconLink({ icon, label, onClick, to }: Props) {
  const inner = (
    <Inline spacing="xsmall" align="center">
      {icon && <Icon size="medium" i={icon} />}
      <span style={{ lineHeight: 1 }}>{label}</span>
    </Inline>
  );

  if (onClick) {
    return <FunctionLink noUnderline onClick={onClick}>{inner}</FunctionLink>
  } else {
    return <Link noUnderline to={to}>{inner}</Link>;
  }
}
