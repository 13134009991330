export default function calculateNps(question, participants) {
  const answers = participants.map(p => p.answers[question.id]);

  const detractors = answers.filter(a => a <= 6).length;
  // passives are 7-8
  const promoters = answers.filter(a => a >= 9).length;
  const score = (promoters / answers.length) - (detractors / answers.length);

  return Math.round(100 * score);
}
