import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Content, ContentWrapper, Stack, Inline } from 'components/atoms/layout';
import Heading from 'atoms/Heading';
import Grid from 'atoms/Grid';
import StudyContext from 'studyContext';
import { QUESTION_TYPES } from '../../constants';
import Button from 'atoms/Button';
import { useHistory } from 'react-router';
import { BorderlessInput } from 'components/atoms/inputs';
import { ReactComponent as PencilSvg } from 'images/icons/pencil.svg';
import { PUBLISH_MUTATION } from 'queries';
import { useMutation } from '@apollo/client';
import useNotifications from 'hooks/useNotifications';
import Select from 'components/Select';
import HelpPanel from 'components/HelpPanel';
import machineLadyImage from 'images/help/machine_lady.png';

const QuestionNameCell = styled(Grid.Cell)`
  position: relative;
  cursor: pointer;

  &:hover {
    font-weight: 700;

    > svg {
      display: block;
    }
  }
`;

const Pencil = styled(PencilSvg)`
  width: 16px;
  position: absolute;
  display: none;
  top: -2px;
  left: -25px;
`;

const UPLOAD_QUESTION_TYPES = ['multi', 'checkbox', 'open', 'email', 'identifier'];

export default function ConfigureUploadedStudyPage() {
  const history = useHistory();
  const notify = useNotifications();
  const { study: { id, questions }, dispatch, dirty } = useContext(StudyContext);
  const [editingRow, setEditingRow] = useState(null);
  const [readyToSave, setReadyToSave] = useState(false);

  const [publishMutation, { loading }] = useMutation(PUBLISH_MUTATION, {
    variables: { id },
    onCompleted: d => {
      dispatch({ type: 'changeState', state: d.publishStudy.state });
      history.push('results');
    },
    onError: () => {
      notify({ message: 'There was an error publishing.' });
      setReadyToSave(false);
    },
  });

  // When hitting save, queue the actual publish event until all in-flight updates are done
  useEffect(() => {
    if (readyToSave && !dirty) {
      publishMutation();
    }
  }, [readyToSave, dirty, publishMutation])

  return (
    <ContentWrapper>
      <Content>
        <Stack>
          <Inline fullWidth justify="space-between">
            <Heading>Let's cluster your survey</Heading>
            <Button disabled={readyToSave} loading={loading} onClick={() => setReadyToSave(true)}>
              {readyToSave ? 'Waiting' : 'Continue'}
            </Button>
          </Inline>

          <HelpPanel
            imageSrc={machineLadyImage}
            imageStyles={{ right: '60px' }}
            toggleStyles={{ position: 'absolute', top: '8px', right: 0, marginTop: 0 }}
            linkTitle="Resources we love"
            allowHide={false}
            links={[
              { label: 'How to create data-driven personas', href: 'https://uxplanet.org/how-to-create-data-driven-personas-1fc3d6b7035f' },
              { label: 'How to write great survey questions', href: 'https://opentext.wsu.edu/carriecuttler/chapter/7-2-constructing-surveys/' },
            ]}
          >
            <p>
              We have gone through and interpreted the question type and name of your survey.
              You can keep editing clusters and excluding questions and participants once the survey
              is uploaded.
            </p>

            <p>
              Your survey is going to generate clusters so try and have a good balance of <strong className="red">
              behavioural questions</strong> and <strong className="blue">motivational questions</strong>,
              alongside <strong className="green">2–3 open questions</strong>. By marking a question
              as 'Email address' or 'Identifier' it will help you later visualise which participants you can follow up
              with for further research.
            </p>

            <p>
              If you need help, don't hesitate to use the chat in the bottom right-hand corner and a
              researcher will be with you.  Upload will work for up to 1000 responses and up to 50 questions.
            </p>
          </HelpPanel>

          <Heading level={3}>Edit questions</Heading>

          <Grid rowTemplate={['1fr', '200px']}>
            <Grid.Header>Rename question</Grid.Header>
            <Grid.Header>Choose question type</Grid.Header>

            <Grid.Line />

            {questions.map((q, i) => {
              if (editingRow === i) {
                return (
                  <Grid.Cell spanAll key={`editor-${q.id}`}>
                    <BorderlessInput
                      autoFocus
                      value={q.label || ''}
                      onChange={e => dispatch({ type: 'changeLabel', id: q.id, value: e.target.value })}
                      onBlur={() => setEditingRow(null)}
                      onKeyUp={e => {
                        if (e.key === 'Enter') { setEditingRow(null) }
                      }}
                    />
                  </Grid.Cell>
                );
              }
              return (
                <React.Fragment key={q.id}>
                  <QuestionNameCell onClick={() => setEditingRow(i)}>
                    <Pencil />
                    {q.label}
                  </QuestionNameCell>

                  <Select
                    items={[['Ignore', 'ignore'], ...UPLOAD_QUESTION_TYPES.map(t => [QUESTION_TYPES[t].label, t])]}
                    selectedValue={q.type}
                    onChange={v => dispatch({ type: 'changeQuestionType', id: q.id, newType: v })}
                  />

                  <Grid.Line />
                </React.Fragment>
              );
            })}
          </Grid>
        </Stack>
      </Content>
    </ContentWrapper>
  );
}
