import { useContext } from 'react';
import { AuthContext } from 'components/auth/AuthContext';
import jwtDecode from 'jwt-decode';

export default function useAuth() {
  const { token, setToken } = useContext(AuthContext);
  const logoutUser = () => setToken(null);

  if (token) {
    const { userId, name, email, exp, roles } = jwtDecode(token);

    // expiry is enforced on the server but might as well check it here too
    const expiresAt = new Date(0);
    expiresAt.setUTCSeconds(exp);

    if (expiresAt > new Date()) {
      return {
        isLoggedIn: true,
        id: userId,
        name,
        roles,
        email,
        token,
        setToken,
        logoutUser
      };
    } else {
      setToken(null);
    }
  }

  return {
    isLoggedIn: false,
    roles: [],
    setToken,
  }
}
