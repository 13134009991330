import { useState, Fragment } from 'react';
import styled from 'styled-components/macro';
import { Content, Stack } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import Link from 'atoms/Link';
import Grid from 'atoms/Grid';
import { ReactComponent as TickSvg } from 'images/icons/tick.svg';
import ParticipantEmail from 'components/ParticipantEmail';
import Inline from 'atoms/Inline';
import Button from 'atoms/Button';

const PersonaCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${p => p.color};
  margin-right: 5px;
`;

const PAGE_SIZE = 50;

export default function Participants({ responses, participants, personas, ignoredParticipantIds = [] }) {
  const [page, setPage] = useState(0);

  const visibleResponses = responses.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);

  const paginationControls = (
    <Inline fullWidth justify="space-between">
      <Button
        variant="secondary"
        disabled={page <= 0}
        onClick={() => setPage(p => p - 1)}
      >
        Previous page
      </Button>

      <Button
        variant="secondary"
        disabled={(page + 1) * PAGE_SIZE >= responses.length}
        onClick={() => setPage(p => p + 1)}
      >
        Next page
      </Button>
    </Inline>
  );

  return (
    <Content>
      <Stack spacing="large">
        <Stack>
          <Heading>Participants</Heading>
          <Text size="body">
            View individual responses to the survey and include/exclude them from personas.
          </Text>
        </Stack>

        {paginationControls}

        <Grid rowTemplate={['1fr', '150px', '1fr', '60px']}>
          <Grid.Header>Participant</Grid.Header>
          <Grid.Header>Cluster</Grid.Header>
          <Grid.Header>Contact details</Grid.Header>
          <Grid.Header>Included</Grid.Header>
          <Grid.Line />

          {visibleResponses.map(response => {
            const relatedParticipant = participants?.find(p => p.id === response.id);
            const persona = personas && relatedParticipant && personas.find(p => p.personaId === relatedParticipant.personaId);
            const included = !ignoredParticipantIds || !ignoredParticipantIds.includes(response.id);

            return (
              <Fragment key={response.id}>
                <Grid.Cell>
                  <Link to={`participant/${response.id}`}>
                    {response.identifier ?? `Participant ${response.num}`}
                  </Link>
                </Grid.Cell>

                <Grid.Cell>
                  {persona && (
                    <Inline>
                      <PersonaCircle color={persona.color} />
                      {persona.name}
                    </Inline>
                  )}
                </Grid.Cell>

                <Grid.Cell><ParticipantEmail>{response.email}</ParticipantEmail></Grid.Cell>

                <Grid.Cell align="center">
                  {included ? <TickSvg /> : null}
                </Grid.Cell>
                <Grid.Line />
              </Fragment>
            );
          })}
        </Grid>

        {paginationControls}
      </Stack>
    </Content>
  )
}
