import styled from 'styled-components/macro';

export const Fader = styled.div`
  position: absolute;
  ${p => p.bottom ? 'bottom' : 'top'}: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(
    to ${p => p.bottom ? 'top' : 'bottom'},
    ${p => p.theme.colors.background},
    rgba(255, 255, 255, 0)
  );
  z-index: 3;
  pointer-events: none;
`;
