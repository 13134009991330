import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const ProgressBarWrapper = styled.div`
  height: 6px;
`;

const Progress = styled(motion.div)`
  height: 100%;
  background-color: ${p => p.color};
`;

export default function ProgressBar({ progress, color }) {
  return (
    <ProgressBarWrapper>
      <Progress
        color={color}
        initial={false}
        animate={{
          width: `${progress * 100}%`,
          transition: {
            type: 'spring',
            stiffness: 120,
            damping: 15,
          }
        }}
      />
    </ProgressBarWrapper>
  );
}
