import { useState } from 'react';
import styled from 'styled-components/macro';
import { Content, Stack } from 'components/atoms/layout';
import TileGrid from 'components/TileGrid';
import InteractableTile from 'components/InteractableTile';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import CsvDownloader from 'components/study/results/CsvDownloader';
import StaticGalaxy from 'components/study/results/StaticGalaxy';
import ClusterMapReport from 'components/study/reports/ClusterMapReport';

// TODO: DRY this with StudyTile
const GalaxyBackground = styled(StaticGalaxy)`
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
`;

// TODO: centralize this
const StudyTitle = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: flex-end;

  margin-top: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  overflow: hidden;
  word-break: break-word;
`;

export default function Reports({ questions, participants, personas }) {
  const [generateClusterMap, setGenerateClusterMap] = useState(false);

  return (
    <Content style={{ minWidth: '80ch' }}>
      <Stack spacing="large">
        <Heading>Download reports</Heading>

        <TileGrid>
          <CsvDownloader
            report="full"
            questions={questions}
            participants={participants}
            personas={personas}
          >
            <InteractableTile onClick={() => {}}>
              <Stack fullHeight spacing="xsmall">
                <StudyTitle>Raw responses</StudyTitle>
                <Text>.csv</Text>
              </Stack>
            </InteractableTile>
          </CsvDownloader>

          <CsvDownloader
            report="highlights"
            questions={questions}
            participants={participants}
            personas={personas}
          >
            <InteractableTile onClick={() => {}}>
              <Stack fullHeight spacing="xsmall">
                <StudyTitle>Highlights</StudyTitle>
                <Text>.csv</Text>
              </Stack>
            </InteractableTile>
          </CsvDownloader>

          {personas ? (
            <InteractableTile onClick={() => setGenerateClusterMap(true)}>
              <GalaxyBackground
                participants={participants}
                personas={personas}
              />
              <Stack fullHeight spacing="xsmall">
                <StudyTitle>Cluster map</StudyTitle>
                <Text>.png</Text>
              </Stack>
            </InteractableTile>
          ) : null}
        </TileGrid>

        {generateClusterMap ? (
          <ClusterMapReport
            participants={participants}
            personas={personas}
            reportComplete={() => setGenerateClusterMap(false) }
          />
        ) : null}
      </Stack>
    </Content>
  );
}
