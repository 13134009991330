import styled from 'styled-components/macro';
import Sidebar from 'components/Sidebar';
import Nav from './Nav';
import StudyState from '../StudyState';
import Stack from 'atoms/Stack';
import Button from 'atoms/Button';
import { Link } from 'react-router-dom';
import { Expander } from 'components/atoms/layout';

const StudyName = styled.div`
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export default function ResultsSidebar({ studyId, personas, name, myAccess, readOnly }) {
  return (
    <Sidebar>
      <Nav personas={personas} myAccess={myAccess} readOnly={readOnly} />

      <Expander minSize="small" />

      {readOnly ? (
        <Button as={Link} target="_blank" to={`/s/${studyId}`} variant="secondary">
          Take our survey
        </Button>
      ) : (
        <Stack spacing="xsmall">
          <StudyName strong>{name}</StudyName>
          <StudyState />
        </Stack>
      )}
    </Sidebar>
  );
}
