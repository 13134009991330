import { useRef } from 'react';
import styled from 'styled-components/macro';
import { useParams, Redirect } from 'react-router-dom';
import { Content, Stack, Spacer } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import ResultsGrid from './ResultsGrid';
import OpenResultsGrid from './OpenResultsGrid';
import Galaxy from './Galaxy';
import { QUESTION_TYPES } from '../../../constants';
import calculateNps from 'utils/calculateNps';
import useStudyResults from 'hooks/useStudyResults';
import HighlightSummary from 'components/study/results/HighlightSummary';
import RenamePersonaButton from 'components/study/results/RenamePersonaButton';
import Inline from 'atoms/Inline';
import Button from 'atoms/Button';

const SmolGalaxy = styled.div`
  height: 320px;
  width: 100%;
  pointer-events: none;
`;

export default function Persona({ personas, participants, questionImportances }) {
  const { personaId } = useParams();
  const { study: { myAccess, questions }} = useStudyResults();
  const firstOpenRef = useRef();

  if (!personas) {
    return <Redirect to="../.." />;
  }

  const persona = personas.find(p => p.personaId === personaId);
  const participantsForPersona = participants.filter(r => r.personaId === personaId);
  const furtherResearch = participantsForPersona.filter(p => p.furtherResearch);
  const openQuestions = questions.filter(q => QUESTION_TYPES[q.type].participantBehavior === 'open');
  const npsQuestions = questions.filter(q => q.type === 'nps');
  // FIXME: this is kinda manual
  const normalQuestions = questions.filter(q => QUESTION_TYPES[q.type].participantBehavior !== 'open' && QUESTION_TYPES[q.type].participantBehavior !== 'hide' && QUESTION_TYPES[q.type].participantBehavior !== 'message' && q.type !== 'nps' && q.type !== 'email');

  const importances = questionImportances[personaId];

  function scrollToOpen() {
    firstOpenRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Content>
      <Stack spacing="large">
        <Stack>
          <Inline fullWidth justify="space-between">
            <Heading>{persona.name}</Heading>
            <RenamePersonaButton persona={persona} />
          </Inline>

          <Text size="body">
            {furtherResearch.length}{' '}
            {furtherResearch.length === 1 ? 'person' : 'people'} from this
            persona {furtherResearch.length === 1 ? 'wants' : 'want'} to do further research
          </Text>
        </Stack>

        <SmolGalaxy>
          <Galaxy
            participants={participantsForPersona}
            personas={personas}
            focusPersona={persona.personaId}
            setFocusPersona={() => {}}
            focusParticipant={null}
            setFocusParticipant={() => {}}
            movable={false}
            noGrid
            zoomFocusedPersona
          />
        </SmolGalaxy>

        <HighlightSummary
          key={persona.personaId}
          maxToShow={20}
          showHighlights
          responses={participantsForPersona}
        >{children => (
          <Stack spacing="small">
            <Inline fullWidth justify="space-between">
              <Stack spacing="xsmall">
                <Heading level={4}>Highlighted themes</Heading>
                <Text size="body">Themes are drawn from what people said in open questions</Text>
              </Stack>

              {myAccess === 'full' ? (
                <Button onClick={scrollToOpen} variant="text">
                  Edit
                </Button>
              ) : null}
            </Inline>

            <hr />

            <Text size="body">
              {children}
            </Text>
          </Stack>
        )}</HighlightSummary>

        {npsQuestions.length ? (
          <div key="nps">
            <Heading level={4}>NPS</Heading>
            <Spacer size="medium" />
            <ResultsGrid
              questions={npsQuestions}
              participants={participants}
              metricLabel="Score"
              metricFunc={calculateNps}
            />
          </div>
        ) : null}

        <Stack>
          <Heading level={4}>Closed questions</Heading>
          <ResultsGrid
            questions={normalQuestions}
            participants={participantsForPersona}
            questionImportances={importances}
            persona={persona}
          />
        </Stack>

        <Stack>
          <Heading level={4}>Open questions</Heading>

          {openQuestions.map(({ id, label }, i)=> {
            const answers = participantsForPersona.map(participant => {
              return {
                participant,
                questionId: id,
                answer: participant.answers[id],
                highlights: participant.highlights?.[id],
                algoHighlights: participant.algoHighlights?.[id],
                removedHighlights: participant.removedHighlights?.[id],
              };
            });

            return (
              <Stack key={id}>
                <Text
                  size="body"
                  ref={i === 0 ? firstOpenRef : null}
                >
                  {label}
                </Text>

                <OpenResultsGrid
                  answers={answers}
                  editable={myAccess === 'full'}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Content>
  )
}
