import styled from 'styled-components/macro';

const HighlightSpan = styled.span`
  display: inline-block;
  position: relative;
  z-index: 0;

  &::before {
    content: attr(data-word);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 48%;
    width: fit-content;
    color: transparent;
    z-index: -1;
    background-color: #FDCC43CC;
    transform: skew(-20deg);
  }
`;

export interface Props {
  children: string;
}

export default function Highlight({ children }: Props) {
  return (
    <HighlightSpan
      data-word={children}
    >
      {children}
    </HighlightSpan>
  );
}
