import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Stack } from 'components/atoms/layout';
import Button from 'atoms/Button';
import { BorderlessInput } from 'components/atoms/inputs';
import { useMutation } from '@apollo/client';
import { REQUEST_OTP_MUTATION, LOG_IN_MUTATION } from 'queries';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { ReactComponent as EmailSvg } from 'images/icons/email.svg';
import useLocalStorage from 'hooks/useLocalStorage';
import Modal from 'components/Modal';
import useAuth from 'hooks/useAuth';
import ValidatedField from 'components/ValidatedField';
import FunctionLink from 'atoms/FunctionLink';

export default function RequestOtp({ handleClose, toSignup }) {
  const { isLoggedIn, setToken } = useAuth();
  const history = useHistory();
  const [mode, setMode] = useState('email');
  const [error, setError] = useState(null);
  const [mutation, { loading }] = useMutation(REQUEST_OTP_MUTATION, {
    onCompleted: () => setMode('pending'),
    onError: e => {
      if (e.message === 'instance not found') {
        setError('Email has not yet signed up');
      } else {
        setError(e.message);
      }
    },
  });

  const [loginMutation, { loading: bypassLoading }] = useMutation(LOG_IN_MUTATION);
  const [loginEmail, setLoginEmail] = useLocalStorage('loginEmail');

  const handleLogin = () => {
    if (loginEmail === '') {
      setError('Enter a valid email address');
    } else if (process.env.REACT_APP_BYPASS_LOGIN_ON_DEV === 'true') {
      loginMutation({ variables: { email: loginEmail.toLowerCase(), otp: 'dev' }}).then(response => {
        if(response) {
          setToken(response.data.loginUser);
          history.push('/studies/welcome');
        }
      });
    } else {
      mutation({ variables: { email: loginEmail.toLowerCase() } })
    }
  };

  if (mode === 'welcome') {
    return (
      <Modal handleClose={handleClose}>
        <Stack alignItems="center">
          <Heading>Welcome back</Heading>
          <Text size="body">
            Create meaningful data-driven personas. Enhance UX with AI. All with gorgeous visualisations and surveys.
          </Text >

          <Stack>
            <Button variant="secondary" onClick={() => setMode('email')}>
              <EmailSvg />
              Log in via email
            </Button>
          </Stack>

          <Text size="body">
            Don't have an account? <FunctionLink onClick={toSignup}>Sign up</FunctionLink>
          </Text>
        </Stack>
      </Modal>
    );
  }

  if (mode === 'email') {
    return (
      <Modal thin handleClose={handleClose}>
        <Stack>
          <Heading>Log in</Heading>

          <ValidatedField label="Email address" error={error}>
            <BorderlessInput
              fullWidth
              autoFocus
              value={loginEmail}
              onChange={e => setLoginEmail(e.target.value)}
              onKeyUp={e => {
                if (e.key === 'Enter') { handleLogin(); }
              }}
            />
          </ValidatedField>

          <Button variant="secondary" onClick={handleLogin} loading={loading || bypassLoading} >
            Continue
          </Button>
        </Stack>
      </Modal>
    );
  }

  if (mode === 'pending') {
    if (isLoggedIn) {
      return (
        <Modal thin handleClose={handleClose}>
          <Stack>
            <Heading>Welcome!</Heading>
            <Text size="body">
              Welcome back to Akin.
            </Text>
          </Stack>
        </Modal>
      );
    } else {
      return (
        <Modal thin handleClose={handleClose}>
          <Stack>
            <Heading>Check your inbox</Heading>
            <Text size="body">
              We just emailed a sign in link to{' '}
              <strong>{loginEmail}</strong>.
            </Text>
          </Stack>
        </Modal>
      );
    }
  }

  // Shouldn't normally happen, but as a failsafe!
  return <Redirect to="/" />;
}
