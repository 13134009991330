import React, { useContext, useEffect, useState } from 'react';
import StudyContext from 'studyContext';
import QuestionTypeWidget from './QuestionTypeWidget';
import Question from './Question';
import Button from 'atoms/Button';
import { Stack, Spacer, Inline } from 'components/atoms/layout';
import { QUESTION_TYPES, QUESTION_LIMIT, IGNORED_QUESTION_TYPES } from '../../../constants';
import usePositionReorder from 'hooks/usePositionReorder';

const VALID_QUESTION_TYPES = [
  'welcome_message',
  'multi',
  'checkbox',
  'likert',
  'open',
  'semantic_differential',
  'nps',
  'binary',
  'email',
  'message',
];

export default function Questions({ addLabel, maxQuestions, deleteConfirm }) {
  const { dispatch, study: { questions }, activeQuestion, setActiveQuestion } = useContext(StudyContext);
  const [showWidget, setShowWidget] = useState(false);
  const [hoveredQuestion, setHoveredQuestion] = useState(null);
  const [updatePosition, updateOrder] = usePositionReorder((from, to) => {
    dispatch({ type: 'moveQuestion', from, to });

    if (activeQuestion === from) {
      setActiveQuestion(to);
    } else if (activeQuestion === to) {
      setActiveQuestion(from);
    }
  });

  // Preload images for the question type widget
  useEffect(() => {
    VALID_QUESTION_TYPES.forEach(qt => {
      new Image().src = `/images/question_types/${qt}.svg`;
    });
  }, []);

  // To enforce free plan limits — should probably put an explanation message rather than just hiding the button
  const globalCountableQuestions = questions.filter(q => !IGNORED_QUESTION_TYPES.includes(q.type));

  const validQuestionTypes = VALID_QUESTION_TYPES.filter(questionType => {
    const qt = QUESTION_TYPES[questionType];
    if (qt.maxAllowed && questions.filter(q => q.type === questionType).length >= qt.maxAllowed) { return false; }

    return true;
  });

  const canAddMoreQuestions = (globalCountableQuestions.length < QUESTION_LIMIT) && (!maxQuestions || !questions || questions.length < maxQuestions)

  // We need to focus new questions when they are added
  const numQuestions = questions?.length || 0;
  const [questionCount, setQuestionCount] = useState(numQuestions);
  useEffect(() => {
    if (numQuestions > questionCount) {
      setActiveQuestion(numQuestions - 1);
    }

    setQuestionCount(numQuestions);
  }, [questionCount, setQuestionCount, numQuestions, setActiveQuestion]);

  return (
    <>
      {questions?.length ? (
        <>
          <Stack spacing="medium">
            {questions.map(({ id, type, label, options, allowOther }, index) => {
              return (
                <Question
                  key={id}
                  id={id}
                  type={type}
                  label={label}
                  options={options}
                  allowOther={allowOther}
                  canDuplicate={canAddMoreQuestions}
                  questionNumber={questions.findIndex(q => q.id === id) + 1}
                  deleteConfirm={deleteConfirm}
                  hoveredQuestion={hoveredQuestion}
                  setHoveredQuestion={setHoveredQuestion}
                  updatePosition={updatePosition}
                  updateOrder={updateOrder}
                />
              );
            })}
          </Stack>
        </>
      ) : null}

      {canAddMoreQuestions && (
        <>
          {questions.length > 0 && <Spacer />}
          {showWidget ? (
            <>
              <QuestionTypeWidget
                cancel={() => setShowWidget(false)}
                validQuestionTypes={validQuestionTypes}
                addQuestion={t => {
                  dispatch({ type: 'addQuestion', questionType: t });
                  setShowWidget(false);
                }}
              />
            </>
          ) : (
            <Inline>
              <Button
                variant="secondary"
                onClick={() => { setShowWidget(true) }}
              >
                {addLabel || (questions.length === 0 ? 'Add first question' : 'Add question')}
              </Button>
            </Inline>
          )}
        </>
      )}
    </>
  );
}
