import { useEffect, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import PrivateRoute from './auth/PrivateRoute';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/link-error';
import { setContext } from '@apollo/link-context';
import useAuth from 'hooks/useAuth';
import Loader from 'components/Loader';
import StudyLoader from './study/StudyLoader';
import Studies from './Studies';
import AdminHome from 'pages/admin/AdminHome';
import Home from './Home';
import ParticipantViewLoader from './participant/ParticipantViewLoader';
import Account from './Account';
import ResultsDemo from 'components/marketing/ResultsDemo';
import ErrorBoundary from 'components/ErrorBoundary';
import ResultsPage from 'components/study/ResultsPage';
import ResultsWrapper from 'components/study/ResultsWrapper';


function App() {
  const { id: userId, token, setToken, roles } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID && userId) {
      ReactGA.set({ userId });
    }
  }, [userId]);

  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}`: '',
        'Original-Referer': document.referrer,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        // If we get a token problem, clear the local JWT
        if (extensions?.code === 'UNAUTHENTICATED') { setToken(null) }

        // console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Study: {
          fields: {
            questions: {
              merge(_existing, incoming) {
                return incoming;
              },
            },
          }
        },
        Query: {
          fields : {
            myStudies: {
              merge(_existing, incoming) {
                return incoming;
              }
            },
            allUsers: {
              keyArgs: ['type', 'search'],
              merge(existing = { users: [] }, incoming) {
                return {
                  after: incoming.after,
                  users: [...existing.users, ...incoming.users]
                };
              },
              // read(existing, { readField, args: { search }}) {
              //   if (!existing) { return undefined; }

              //   return {
              //     ...existing,
              //     users: existing.users.filter(u => {
              //       if (!search || search === '') { return true; }

              //       return readField('email', u).includes(search);
              //     }),
              //   };
              // }
            },
          },
        },
      },
      cacheRedirects: {
        Query: {
          study: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Study', id: args.id })
        },
      },
    }),
    link: errorLink.concat(authLink).concat(httpLink),
  });

  return (
    <ErrorBoundary>
      <Helmet titleTemplate="akin | %s" defaultTitle="akin" />

      <ApolloProvider client={client}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <PrivateRoute path={`/study/:studyId/results`}>
              <ResultsWrapper>
                <ResultsPage />
              </ResultsWrapper>
            </PrivateRoute>
            <PrivateRoute path="/study/:studyId">
              <StudyLoader />
            </PrivateRoute>
            <PrivateRoute path="/studies">
              <Studies />
            </PrivateRoute>
            {roles.includes('admin') && (
              <PrivateRoute path="/admin">
                <AdminHome />
              </PrivateRoute>
            )}
            <PrivateRoute path="/account">
              <Account />
            </PrivateRoute>
            <Route path="/s/:slug">
              <ParticipantViewLoader />
            </Route>
            <Route path="/demo">
              <ResultsDemo />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
