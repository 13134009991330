import styled from 'styled-components/macro';
import { QUESTION_TYPES } from '../../constants';
import OpenQuestion from './OpenQuestion';
import RadioQuestion from './RadioQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import EmailQuestion from './EmailQuestion';
import SemanticDifferentialQuestion from './SemanticDifferentialQuestion';

const QuestionLabel = styled.h1`
  font-size: ${p => p.long ? '18px' : '32px'};
  line-height: ${p => p.long ? '24px' : '40px'};
  font-weight: ${p => p.long ? '600' : 'bold'};
  white-space: pre-line;
  word-wrap: break-word;

  font-family: ${p => p.typography === 'sans' || p.long ? p.theme.fonts.body : p.theme.fonts.title};
`;

export default function Question({ question, typography, ...otherProps }) {
  const { participantBehavior, optionDisplayType, defaultQuestion, reverseOptions } = QUESTION_TYPES[question.type]
  const QuestionComponent = {
    'open': OpenQuestion,
    'radio': RadioQuestion,
    'checkbox': CheckboxQuestion,
    'email': EmailQuestion,
    'semantic_differential': SemanticDifferentialQuestion,
    'message': null,
  }[participantBehavior];

  return (
    <>
      <QuestionLabel long={question.label.length >= 100} typography={typography}>
        {question.label?.length ? question.label : (defaultQuestion || 'Type your question in here')}
      </QuestionLabel>

      {QuestionComponent && (
        <QuestionComponent
          question={question}
          reverseOptions={reverseOptions}
          displayType={optionDisplayType}
          {...otherProps}
        />
      )}
    </>
  );
}
