import { useRef } from 'react';
import { Content, Stack, Spacer } from 'components/atoms/layout';
import Heading from 'atoms/Heading';
import ResultsGrid from './ResultsGrid';
import OpenResultsGrid from './OpenResultsGrid';
import { QUESTION_TYPES } from '../../../constants';
import calculateNps from 'utils/calculateNps';
import useStudyResults from 'hooks/useStudyResults';
import HighlightSummary from 'components/study/results/HighlightSummary';
import Button from 'atoms/Button';
import Inline from 'atoms/Inline';
import Text from 'atoms/Text';

export default function RawResults({ participants, questionImportances }) {
  const { study: { myAccess, questions } } = useStudyResults();
  const firstOpenRef = useRef();

  const furtherResearch = participants.filter(p => p.email);

  const openQuestions = questions.filter(q => QUESTION_TYPES[q.type].participantBehavior === 'open');
  const npsQuestions = questions.filter(q => q.type === 'nps');
  // FIXME: this is kinda manual
  const normalQuestions = questions.filter(q => QUESTION_TYPES[q.type].participantBehavior !== 'open' && QUESTION_TYPES[q.type].participantBehavior !== 'message' && QUESTION_TYPES[q.type].participantBehavior !== 'hide' && q.type !== 'nps' && q.type !== 'email');

  function scrollToOpen() {
    firstOpenRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Content>
      <Stack spacing="large">
        <Stack>
          <Heading>Results</Heading>

          <Text size="body">
            {furtherResearch.length}{' '}
            {furtherResearch.length === 1 ? 'person' : 'people'}
            {' '}{furtherResearch.length === 1 ? 'is' : 'are'} available to do further research
          </Text>
        </Stack>

        <HighlightSummary
          showHighlights
          maxToShow={20}
          responses={participants}
        >{children => (
          <Stack spacing="small">
            <Inline fullWidth justify="space-between">
              <Stack spacing="xsmall">
                <Heading level={4}>Highlighted themes</Heading>
                <Text size="body">Themes are drawn from what people said in open questions</Text>
              </Stack>

              {myAccess === 'full' ? (
                <Button onClick={scrollToOpen} variant="text">
                  Edit
                </Button>
              ) : null}
            </Inline>

            <hr />

            <Text size="body">
              {children}
            </Text>
          </Stack>
        )}</HighlightSummary>

        {npsQuestions.length ? (
          <div key="nps">
            <Heading level={3}>NPS</Heading>
            <Spacer size="medium" />
            <ResultsGrid
              questions={npsQuestions}
              participants={participants}
              metricLabel="Score"
              metricFunc={calculateNps}
            />
          </div>
        ) : null}

        <Stack>
          <Heading level={4}>Closed questions</Heading>
          <ResultsGrid
            questions={normalQuestions}
            participants={participants}
            questionImportances={questionImportances}
          />
        </Stack>

        <Stack>
          <Heading level={4}>Open questions</Heading>

          {openQuestions.map(({ id, label }, i)=> {
            const answers = participants.map(participant => {
              return {
                participant,
                questionId: id,
                answer: participant.answers[id],
                highlights: participant.highlights?.[id],
                algoHighlights: participant.algoHighlights?.[id],
                removedHighlights: participant.removedHighlights?.[id],
              };
            });

            return (
              <Stack key={id}>
                <div ref={i === 0 ? firstOpenRef : null} />

                <Text size="body" style={{ marginTop: 0 }}>
                  {label}
                </Text>

                <OpenResultsGrid
                  answers={answers}
                  editable={myAccess === 'full'}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Content>
  )
}
