
import styled from 'styled-components/macro';
import Text from 'atoms/Text';

const ValidatedFieldWrapper = styled.div`
width: 100%;
padding-left: ${p => p.hasError ? '22px' : '0px'};
border-left: ${p => p.hasError ? '6px' : '0px'} solid ${p => p.theme.colors.brand};

transition: 0.3s all;
`;

const ErrorMessage = styled.label`
color: ${p => p.theme.colors.error};
font-weight: bold;
`;

export default function ValidatedField({ label, error, children }) {
  return (
    <ValidatedFieldWrapper hasError={error}>
      { error
        ? <ErrorMessage>{error}</ErrorMessage>
        : <Text size="small">{label}</Text>
      }
      {children}
    </ValidatedFieldWrapper>
  );
}
