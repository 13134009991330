
import { forwardRef, Fragment, useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';
import convexHull from 'monotone-convex-hull-2d';

export default forwardRef(function StaticGalaxy({ participants, personas, fakeData, className, showHulls = true, opacity, pointSize = 0.7}, ref) {
  const { colors: { dark, clusters: clusterColors }} = useContext(ThemeContext);

  let personasToUse = personas;
  let participantsToUse = participants;

  if (fakeData) {
    personasToUse = new Array(4).fill(null).map((_,i) => ({ personaId: i }));

    participantsToUse = new Array(32).fill(null).map((_,i) => {
      const personaId = i % 4;

      return {
        personaId,
        x: 5 + Math.random() * 50 + (personaId >= 2 ? 45 : 0),
        y: 10 + Math.random() * 50 + ((personaId % 2 === 0) ? 40 : 0),
      };
    });
  }
  return (
    <svg ref={ref} viewBox="0 0 102 102" className={className}>
      <g transform="translate(1, 1)">
        {personasToUse.map((persona, i) => {
          const personaParticipants = participantsToUse.filter(r => r.personaId === persona.personaId);
          const points = personaParticipants.map(p => [p.x, p.y]);
          const hull = convexHull(points).map(i => points[i]);
          const personaColor = fakeData ? dark : clusterColors[i];

          return (
            <Fragment key={persona.personaId}>
              {showHulls ? <polygon
                points={hull.map(p => p.join(',')).join(' ')}
                fill={personaColor}
                opacity={fakeData ? 0.5 : 0.13}
              /> : null}

              {(showHulls ? hull : points).map(([x, y], participantIndex) => {
                return (
                  <circle
                    key={`star-${participantIndex}`}
                    cx={x}
                    cy={y}
                    r={pointSize}
                    opacity={opacity ?? (fakeData ? 0.9 : 0.26)}
                    fill={personaColor}
                  />
                );
              })}
            </Fragment>
          );
        })}
      </g>
    </svg>
  );
});
