import { forwardRef } from 'react';
import styled from 'styled-components/macro';

const BorderlessInputWrapper = styled.div`
  width: 100%; /* TODO: width option? */
  position: relative;
  border-bottom: 2px solid transparent;
  height: fit-content;

  /* TODO: this shouldn't apply when the delete button is focused, only the text input */
  &:focus-within {
    border-color: ${p => p.theme.colors.highlight};
  }
`;

interface ElementProps {
  borderless: boolean
}

export const TextInputElement = styled.input<ElementProps>`
  width: 100%;
  flex: 1;
  border: 2px solid ${p => p.theme.colors.dark};
  border-radius: ${p => p.theme.borderRadius};
  padding: 4px 10px 6px 10px;
  color: black;
  font-size: 14px;
  background-color: ${p => p.theme.colors.accentBackground};

  &[disabled] {
    pointer-events: none;
    color: ${p => p.theme.colors.dark};
  }

  &:hover {
    border-color: ${p => p.theme.colors.text};
  }

  &:focus {
    border-color: ${p => p.theme.colors.text};
    outline: none;
    box-shadow: 0 0 0 2px ${p => p.theme.colors.highlight};
  }

  ${p => p.borderless ? `
    margin: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid ${p.theme.colors.dark};
    background-color: inherit;

    &:focus {
      box-shadow: none;
    }
  ` : ''}
`;

export interface Props {
  type?: string
  borderless?: boolean
  placeholder?: string
  onEnter?: (value: string) => void
  value?: string,
  onChange?: (v: string) => void
  readOnly?: boolean
  disabled?: boolean
  defaultValue?: string
  autoFocus?: boolean
  onClick?: () => {}
  style?: Record<string, string>
}

// TODO: look at style later, only used in one place so might be able to refactor it away
export default forwardRef<HTMLInputElement, Props>(function TextInput({ type, borderless = false, autoFocus = false, onEnter, onClick, value, onChange, readOnly, disabled, defaultValue, placeholder, style }, ref) {
  const input = (
    <TextInputElement
      ref={ref}
      borderless={borderless}
      value={value}
      type={type}
      onChange={onChange ? e => onChange(e.target.value) : undefined}
      style={style}
      readOnly={readOnly}
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onClick={onClick}
      onKeyPress={onEnter ? (e => {
        if (e.key === 'Enter') { onEnter((e.target as HTMLInputElement).value); }
      }) : undefined}
    />
  );

  if (borderless) {
    // TODO: style prop on the wrapper as well?
    return <BorderlessInputWrapper>{input}</BorderlessInputWrapper>;
  } else {
    return input;
  }
});
