import { useState } from 'react';
import { Switch, Route, Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FullPage, Panel, Inline, Expander, Stack, NotMobile, MobileOnly } from 'components/atoms/layout';
import Overview from 'components/marketing/Overview';
import MarketingPage from 'components/marketing/MarketingPage';
import BlogPost from 'components/BlogPost';
import Pricing from 'components/marketing/Pricing';
import Login from 'components/Login';
import Signup from 'components/Signup';
import ConnectSurveyMonkeyAccount from 'components/ConnectSurveyMonkeyAccount';
import ErrorPage from 'components/ErrorPage';
import logoSvg from 'images/logo.svg';
import HamburgerMenu from 'components/marketing/HamburgerMenu';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import useAuth from 'hooks/useAuth';
import RequestOtp from 'components/RequestOtp';
import ExternalLink from 'atoms/ExternalLink';
import PlanUpgradeSuccess from 'components/marketing/PlanUpgradeSuccess';
import ReturnFromStripe from 'components/marketing/ReturnFromStripe';
import cmsContent from 'content.json';

const NavBar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${p => p.theme.marketingBackgroundColor};

  > Panel > :first-child {
    margin-right: auto;
  }
`;

const Footer = styled.footer`
  padding: 70px 0;
  color: ${p => p.theme.colors.background};
  background-color: ${p => p.theme.colors.brand};
`;

const Logo = styled.img.attrs({
  src: logoSvg,
  alt: 'Akin logo',
})`
  width: 70px;
  height: 24px;
`;

const NavItem = styled(ReactRouterLink)`
  color: ${p => p.$cta ? p.theme.colors.brand : p.theme.colors.text};
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;

  &:active, &:hover {
    color: ${p => p.$cta ? p.theme.colors.text : p.theme.colors.brand};
  }
`;

const ContentWithSpaceForFooter = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: ${p => p.theme.marketingBackgroundColor};
`;

const LogoLink = styled(ReactRouterLink)`
  color: ${p => p.theme.colors.brand};
`;

const FooterText = styled(Heading).attrs({
  level: 3,
})`
  max-width: 500px;
  color: white;
  text-align: center;
`;

export default function Home() {
  const { isLoggedIn } = useAuth();
  const [mode, setMode] = useState(null);

  const triggerSignup = () => setMode('signup');
  const triggerLogin = () => setMode('login');

  const overviewContent = cmsContent.find(c => c.slug === 'overview');
  const nonOverviewContent = cmsContent.filter(c => c.slug !== 'overview');

  return (
    <FullPage>
      <header>
        <NavBar>
          <Panel width={400}>
            <NotMobile>
              <Inline fullWidth spacing="medium">
                <LogoLink to="/">
                  <Logo />
                </LogoLink>

                <Expander />

                <NavItem to="/">Overview</NavItem>
                {nonOverviewContent.map(page => (
                  <NavItem key={page.slug} to={`/${page.slug}`}>{page.title}</NavItem>
                ))}
                <NavItem to="/pricing">Pricing</NavItem>

                {isLoggedIn ? (
                  <NavItem $cta to="/studies">My studies</NavItem>
                ) : (
                  <>
                    <NavItem $cta as="a" onClick={triggerLogin}>Log in</NavItem>
                    <NavItem $cta as="a" onClick={triggerSignup}>Sign up</NavItem>
                  </>
                )}
              </Inline>
            </NotMobile>

            <MobileOnly style={{ width: '100%' }}>
              <HamburgerMenu triggerSignup={triggerSignup} triggerLogin={triggerLogin} />
            </MobileOnly>
          </Panel>
        </NavBar>
      </header>

      <ContentWithSpaceForFooter style={{ display: 'flex', flexDirection: 'column' }}>
        <Switch>
          <Route exact path="/(|get_started)">
            <Overview blocks={overviewContent.blocks} triggerLogin={triggerLogin} triggerSignup={triggerSignup} />
          </Route>
          <Route exact path="/pricing">
            <Pricing triggerSignup={triggerSignup} />
          </Route>
          <Route path="/blog/:slug">
            <BlogPost triggerSignup={triggerSignup} />
          </Route>
          <Route exact path="/success">
            <PlanUpgradeSuccess />
          </Route>
          <Route exact path="/integrations/surveymonkey">
            <ConnectSurveyMonkeyAccount />
          </Route>
          <Route exact path="/refresh">
            <ReturnFromStripe />
          </Route>
          <Route component={Login} exact path="/login" />

          {nonOverviewContent.map(pageContent => (
            <Route key={pageContent.slug} exact path={`/${pageContent.slug}`}>
              <MarketingPage content={pageContent} triggerSignup={triggerSignup} />
            </Route>
          ))}

          <Route>
            <ErrorPage message="That page could not be found." />
          </Route>
        </Switch>
      </ContentWithSpaceForFooter>

      <Footer>
        <Stack spacing="medium" alignItems="center">
          <FooterText>
            Akin is proudly made in New Zealand by two Kiwis and a Canuck.
          </FooterText>
          <Inline spacing="small" style={{ width: '200px' }}>
            <ExternalLink noUnderline href="/policies/akin_privacy_policy_july_2020.pdf">Privacy policy</ExternalLink>
            <ExternalLink noUnderline href="/policies/akin_terms_of_use_july_2020.pdf">Terms of use</ExternalLink>
          </Inline>
          <Text family="marketing" size="small">
            © {new Date().getFullYear()} Akin Personas, Ltd.
          </Text>
        </Stack>
      </Footer>

      {mode === 'login' && <RequestOtp toSignup={() => setMode('signup')} handleClose={() => setMode(null)} />}
      {mode === 'signup' && <Signup toLogin={() => setMode('login')} handleClose={() => setMode(null)} />}
    </FullPage>
  );
}
