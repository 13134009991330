import { useState } from 'react';
import styled from 'styled-components/macro';
import Galaxy from './Galaxy';
import PersonaDetail from './PersonaDetail';
import ParticipantDetail from './ParticipantDetail';
import NotEnoughResponses from './NotEnoughResponses';
import Instructions from './Instructions';
import AnalysisFailed from './AnalysisFailed';
import Loader from 'components/Loader';
import HelpPanel from 'components/HelpPanel';
import Heading from 'atoms/Heading';
import crystalBallImage from 'images/help/the_mystics.png';
import { NotMobile } from 'components/atoms/layout';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${p => p.theme.colors.background};

  &:focus {
    outline: none;
  }
`;

const FloatingContent = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 3;
  pointer-events: none;
`;

export default function Clusters({ readOnly = false, state, questionImportance, personas, participants, responses, resultsOptions }) {
  const [focusPersona, setFocusPersona] = useState(null);
  const [focusParticipant, setFocusParticipant] = useState(null);

  if (!state) return <NotEnoughResponses responses={responses} />
  if (state === 'sent' || state === 'created') return (
    <Instructions
      title="Generating"
      message="This should take a few minutes as we run through every permutation."
    >
      <div style={{ height: 150 }}>
        <Loader />
      </div>
    </Instructions>
  );
  if (['failed', 'parseFailed', 'timedOut'].includes(state)) {
    return (
      <AnalysisFailed />
    );
  }

  return (
    <Wrapper>
      <Galaxy
        participants={participants}
        personas={personas}
        focusPersona={focusPersona}
        setFocusPersona={setFocusPersona}
        focusParticipant={focusParticipant}
        setFocusParticipant={setFocusParticipant}
      />

      <FloatingContent>
        {focusParticipant !== null && (
          <ParticipantDetail
            participant={participants.find(r => r.id === focusParticipant)}
            persona={personas.find(p => p.personaId === focusPersona)}
            questionImportances={questionImportance}
            onBack={() => setFocusParticipant(null)}
          />
        )}

        {focusPersona !== null && focusParticipant === null && (
          <PersonaDetail
            persona={personas.find(p => p.personaId === focusPersona)}
            clusterNames={resultsOptions?.clusterNames}
            onBack={() => setFocusPersona(null)}
            participants={participants}
            questionImportances={questionImportance}
            readOnly={readOnly}
          />
        )}
      </FloatingContent>

      <NotMobile>
        <HelpPanel
          identifier="clusters"
          clickOutsideToClose
          imageSrc={crystalBallImage}
          imageStyles={{ right: '0' }}
          toggleStyles={{ position: 'absolute', top: '60px', right: '60px', marginTop: 0 }}
          panelStyles={{ maxWidth: '600px', position: 'absolute', top: '40px', right: '40px' }}
          hideDirection="right"
          linkTitle="Resources we love"
          links={[
            { label: 'How to create data-driven personas', href: 'https://uxplanet.org/how-to-create-data-driven-personas-1fc3d6b7035f' },
            { label: 'How our clustering algorithm works', href: 'https://akin.nz/howitworks' },
          ]}
        >
          <Heading>Cluster Science</Heading>
          <p>
            Each <strong className="red">dot</strong> represents one <strong className="red">person</strong> that
            filled out your survey, colour coded by what cluster they are in. <strong className="blue">Rings</strong> around
            dots show if a person has consented to <strong className="blue">sharing their details</strong> for follow-up research.
            Tightly clustered respondants show a strong cluster and the closer a person is to the centre the more they fit in the cluster.
          </p>

          <p>
            Improve your clusters by removing bad faith participiants, demographic or unrelated questions and
            changing the number of clusters on the <strong className="green">edit cluster tab</strong>.
          </p>

          <p>
            If you need help, don't hesitate to use the chat in the bottom left hand corner and a researcher will be with you.
          </p>
        </HelpPanel>
      </NotMobile>
    </Wrapper>
  );
}
