import React from 'react';
import styled from 'styled-components/macro';

const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TextAreaInput = styled.textarea`
  width: 100%;
  padding: var(--spacing-small);
  background-color: ${p => p.theme.colors.accentBackground};
  border: 2px solid ${p => p.theme.colors.dark};
  border-radius: ${p => p.theme.borderRadius};
  color: black;
  font-size: 14px;
  resize: none;

  &:hover {
    border-color: ${p => p.theme.colors.text};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${p => p.theme.colors.highlight};
    border-color: ${p => p.theme.colors.text};
  }
`;

const Counter = styled.span`
  position: absolute;
  bottom: 10px;
  right: 12px;
  opacity: 0;

  div:focus-within & {
    opacity: 1;
  }

  transition: 0.2s opacity;
`;

export interface Props {
  value: string
  placeholder?: string
  maxLength?: number
  rows?: number
  onChange: (value: string) => void
}

export default React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { value, maxLength, onChange, ...otherProps } = props;
  const length = value?.length ?? 0;

  const showCounter = maxLength && ((maxLength - length) <= 20);

  return (
    <TextAreaWrapper>
      <TextAreaInput
        autoFocus
        ref={ref}
        maxLength={maxLength}
        onChange={e => onChange ? onChange((e.target as HTMLTextAreaElement).value) : null}
        value={value}
        {...otherProps}
      />
      {showCounter && <Counter>{value.length} / <strong>{maxLength}</strong></Counter>}
    </TextAreaWrapper>
  );
});
