import styled from 'styled-components/macro';
import { Inline, Stack } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { QUESTION_TYPES } from '../../../constants';

const Backdrop = styled.div`
  padding: 40px;
  background-color: ${p => p.theme.colors.accentBackground};
`;

const OptionsList = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
`;

export default function QuestionsLocked({ questions }) {
  return (
    <Backdrop>
      <Stack>
        <Heading>Published survey</Heading>

        <Text size="body">
          This study has been published and cannot be edited for data integrity.<br/>
          Duplicate this study to make changes.
        </Text>

        <Stack spacing="large">
          {questions && questions.map(({ id, type, label, options, allowOther }, index) => {
            return (
              <Stack key={id}>
                <Inline spacing="xsmall" align="baseline">
                  <Heading level={4}>Question {index + 1}</Heading>
                  <span>| {QUESTION_TYPES[type].label}</span>
                </Inline>

                <p>{label}</p>
                <OptionsList>
                  {options && options.map(({ label, exclude }, index) => (
                    <li key={index}>
                      {label} {exclude ? '(exclude)' : null}
                    </li>
                  ))}
                  {allowOther ? <li>Other</li> : null}
                </OptionsList>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Backdrop>
  );
}
