import { useEffect } from 'react';
import Pusher from 'pusher-js';

const client = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

export default function useWebsocket(channelName, callback) {
  useEffect(() => {
    const channel = client.subscribe(channelName);
    channel.bind_global(callback);

    return () => {
      client.unsubscribe(channelName);
      channel.unbind(); // TODO: this unbinds everything, maybe too broad?
    }
  }, [channelName, callback]);
}
