import styled from 'styled-components/macro';

export const PersonaCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${p => p.active ? p.color : p.theme.colors.dark };
  margin-right: 6px;
`;

const PillButton = styled.button`
  height: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: ${p => p.theme.borderRadius};
  background-color: #FAF9F8;
  border: none;
  cursor: pointer;
  outline-color: ${p => p.theme.colors.highlight};
`;

const ButtonLabel = styled.span`
  color: ${p => p.active ? p.theme.colors.text : p.theme.colors.dark}
`;

export default function PersonaToggle({ className, persona, checked, onChange }) {
  return (
    <PillButton className={className} onClick={() => onChange(!checked)} checked={checked}>
      <PersonaCircle color={persona.color} active={checked} />
      <ButtonLabel active={checked}>{persona.name}</ButtonLabel>
    </PillButton>
  );
}
