import styled, { css } from 'styled-components/macro';
import { Link as ReactRouterLink, NavLink as ReactRouterNavLink } from 'react-router-dom';

interface LinkElementProps {
  $noUnderline: boolean
  $allowWrapping?: boolean
  $disabled?: boolean
}

export const commonLinkStyles = css<LinkElementProps>`
  color: inherit;
  width: fit-content;
  text-decoration: none;
  white-space: ${p => p.$allowWrapping ? 'initial' : 'nowrap'};
  cursor: ${p => p.$disabled ? 'progress' : 'pointer'};
  border-bottom: ${p => p.$noUnderline ? '0' : '1px'} solid currentColor;
  margin-bottom: ${p => p.$noUnderline ? '0' : '1px'};
  outline-color: ${p => p.theme.colors.highlight};

  &:hover {
    font-weight: bold;
    border-bottom-width: ${p => p.$noUnderline ? '0' : '2px'};
    margin-bottom: 0px;
  }

  &:active {
    font-weight: bold;
    color: ${p => p.theme.colors.brand};
    border-bottom-width: ${p => p.$noUnderline ? '0' : '2px'};
  }
`;

const BasicLink = styled(ReactRouterLink)`
  ${commonLinkStyles}
`;

const NavLink = styled(ReactRouterNavLink)`
  ${commonLinkStyles}

  &.active {
    font-weight: bold;
    border-bottom-width: ${p => p.$noUnderline ? '0' : '2px'};
    margin-bottom: 0px;
  }
`;

export interface Props {
  to: string
  noUnderline?: boolean
  nav?: boolean
  className?: string
  children: React.ReactNode
  allowWrapping?: boolean
}

export default function Link({ to, noUnderline = false, nav = false, allowWrapping = false, className, children }: Props) {
  if (nav) {
    return (
      <NavLink
        exact
        to={to}
        className={className}
        $noUnderline={noUnderline}
        $allowWrapping={allowWrapping}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <BasicLink
      to={to}
      className={className}
      $noUnderline={noUnderline}
      $allowWrapping={allowWrapping}
    >
      {children}
    </BasicLink>
  );
}
