import styled from 'styled-components/macro';
import Chart from 'components/charts/Chart';
import { useHistory, useRouteMatch } from 'react-router-dom';

const ComparisonChart = styled(Chart)`
  width: 100%;
`;

export default function CompareQuestions({ question1, question2, responses, participants, personas, ignoredPersonaIds, ignoredParticipantIds, showHeatmap, showTrendline, spreadOption }) {
  const history = useHistory();
  const resultsMatch = useRouteMatch('/study/:studyId/results');
  const demoMatch = useRouteMatch('/demo');
  const yAxis = question1.options.map((o,i) => [o.label, i / (question1.options.length - 1)]);
  const xAxis = question2.options.map((o,i) => [o.label, i / (question2.options.length - 1)]);

  const dataset = responses.map(response => {
    const relatedParticipant = participants?.find(p => p.id === response.id);
    const persona = personas && relatedParticipant && personas.find(p => p.personaId === relatedParticipant.personaId);

    const included = (!ignoredParticipantIds || !ignoredParticipantIds.includes(response.id)) &&
      !ignoredPersonaIds.includes(persona?.personaId);

    if (!included) { return null; }

    const answer1 = response.answers[question1.id];
    const answer2 = response.answers[question2.id];

    let x, y;

    if (question1.type === 'semantic_differential') {
      y = (answer1 + 1) / 2;
    } else {
      if (answer1 === null || answer1 === undefined || answer1 === -1) {
        y = null;
      } else {
        [,y] = yAxis[answer1];
      }
    }

    if (question2.type === 'semantic_differential') {
      x = (answer2 + 1) / 2;
    } else {
      if (answer2 === null || answer2 === undefined || answer2 === -1) {
        x = null;
      } else {
        [,x] = xAxis[answer2];
      }
    }

    if (!x || !y) { return null; }

    return {
      id: response.id,
      identifier: response.identifier,
      num: response.num,
      x,
      y,
      color: persona ? persona.color : 'black',
    };
  }).filter(Boolean);

  return (
    <ComparisonChart
      data={dataset}
      width={190.5}
      height={150}
      xAxisTitle={question2.label}
      xAxis={xAxis}
      yAxisTitle={question1.label}
      yAxis={yAxis}
      showHeatmap={showHeatmap}
      showTrendline={showTrendline}
      spreadOption={spreadOption}
      onClickPoint={participant => history.push(`${resultsMatch?.url || demoMatch.url}/participant/${participant.id}`)}
    />
  );
}
