import styled from 'styled-components/macro';

interface ElementProps {
  justify: string
  fullWidth: boolean
  fullHeight: boolean
  align: string
  spacing: string
  mobileSpacing?: string
  $wrap: boolean
}

const StackContainer = styled.div<ElementProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${p => p.justify || 'flex-start'};
  width: ${p => p.fullWidth ? '100%' : 'fit-content'};
  height: ${p => p.fullHeight ? '100%' : 'fit-content'};
  align-items: ${p => p.align || 'center'};
  flex-wrap: ${p => p.$wrap ? 'wrap' : 'initial'};
  gap: ${p => p.theme.spacing[p.spacing]};

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    gap: ${p => p.theme.spacing[p.mobileSpacing || p.spacing]};
  }
`;

export interface Props {
  fullWidth?: boolean
  fullHeight?: boolean
  justify?: 'flex-start' | 'flex-end' | 'space-between' | 'center'
  align?: 'flex-start' | 'flex-end' | 'center'
  spacing?: 'none' | 'xsmall' | 'small' | 'medium' | 'mlarge' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'
  mobileSpacing?: 'none' | 'xsmall' | 'small' | 'medium' | 'mlarge' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'
  wrap?: boolean
  children: React.ReactNode
  foo?: string
}

export default function Inline({
  fullWidth = false,
  fullHeight = false,
  justify = 'flex-start',
  align = 'flex-start',
  spacing = 'medium',
  mobileSpacing,
  wrap = false,
  children
}: Props) {
  return (
    <StackContainer
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      justify={justify}
      align={align}
      spacing={spacing}
      mobileSpacing={mobileSpacing}
      $wrap={wrap}
    >
      {children}
    </StackContainer>
  );
}
