import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import ParticipantView from './ParticipantView';
import ErrorPage from 'components/ErrorPage';
import { STUDY_QUERY } from 'queries';

export default function ParticipantViewLoader() {
  const { slug } = useParams();
  const { data, loading, error } = useQuery(STUDY_QUERY, {
    variables: { slug },
  });
  const queryParams = new URLSearchParams(useLocation().search);
  const startPage = queryParams.get('p') && parseInt(queryParams.get('p'))

  const forcePreview = queryParams.get('preview') !== null;

  // TODO: not 100% sure if this is the right place for this state
  const [currentPage, setCurrentPage] = useState(startPage ?? 0);
  const [priorPage, setPriorPage] = useState(null);

  const changePage = newPage => {
    setPriorPage(currentPage);
    setCurrentPage(newPage);
  };

  if (loading) return <Loader />;
  if (error) return <ErrorPage message={JSON.stringify(error)} />;

  const isPreview = forcePreview || data.study.state === 'draft';

  return (
    <ParticipantView
      study={data.study}
      preview={isPreview}
      showPreviewBanner={isPreview}
      startPage={startPage}
      currentPage={currentPage}
      priorPage={priorPage}
      changePage={changePage}
    />
  );
}
