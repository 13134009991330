import { gql, useMutation } from '@apollo/client';
import useNotifications from 'hooks/useNotifications';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import ErrorPage from './ErrorPage';
import Loader from './Loader';

const CONNECT_INTEGRATION_MUTATION = gql`
  mutation connectSurveyMonkey($code: String!) {
    connectIntegration(type: SurveyMonkey, code: $code)
  }
`;

export default function ConnectSurveyMonkeyAccount() {
  const history = useHistory();
  const notify = useNotifications();
  const params = new URLSearchParams(window.location.search);
  const smState = params.get('state')
  const [connect, { error }] = useMutation(CONNECT_INTEGRATION_MUTATION, {
    variables: { code: params.get('code') },
  });

  useEffect(() => {
    connect().then(() => {
      notify({ message: 'SurveyMonkey account connected successfully!' });

      if (smState === 'onboarding') {
        history.push('/get_started?integration=SurveyMonkey');
      } else {
        // They're an existing account, so redirect to the dashboard
        history.push('/studies/new?integration=SurveyMonkey');
      }
    }).catch(() => {
      // (Supress GraphQL error and we'll display it below)
    });
  }, [connect, history, notify, smState]);

  if (error) { return <ErrorPage message={error.message} /> }

  return <Loader />;
}
