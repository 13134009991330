import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface TextElementProps {
  size?: 'small' | 'body'
  family?: string
  color?: string
  align?: 'left' | 'center' | 'right'
  strong?: boolean
  inline?: boolean;
}

const TextElement = styled.p<TextElementProps>`
  font-size: ${p => p.theme.fontSizes[p.size ?? 'inherit']};
  line-height: ${p => p.theme.lineHeights[p.size ?? 'inherit']};
  font-family: ${p => p.theme.fonts[p.family ?? 'inherit']};
  font-weight: ${p => p.strong ? '500' : 'normal'};
  color: ${p => p.color ? p.theme.colors[p.color] : 'inherit'};
  text-align: ${p => p.align ?? 'inherit'};
  ${p => p.inline ? `display: inline;` : ''}
`;

export interface Props extends TextElementProps {
  children: ReactNode
};

export default function Text(props: Props) {
  return (
    <TextElement {...props}/>
  );
}
