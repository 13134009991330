import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery, gql } from '@apollo/client';
import { Content } from 'components/atoms/layout';
import Heading from 'atoms/Heading';
import Stack from 'atoms/Stack';
import { useParams } from 'react-router';
import Loader from 'components/Loader';
import IconLink from 'components/IconLink';
import Grid from 'atoms/Grid';
import useStudyResults from 'hooks/useStudyResults';
import type { Question, Option } from 'utils/types';
import ImportanceBar from 'components/ImportanceBar';
import Panel from 'atoms/Panel';
import Text from 'atoms/Text';
import Inline from 'atoms/Inline';
import { sortBy } from 'lodash';
import StaticGalaxy from 'components/study/results/StaticGalaxy';

const BigNumber = styled.span`
  display: block;
  width: 70px;
  font-family: ${p => p.theme.fonts.serif};
  font-size: ${p => p.theme.fontSizes.large};
`;

const PREDICTION_QUERY = gql`
  query analysis($analysisId: ID!) {
    analysis(analysisId: $analysisId) {
      id
      state
      questionImportance

      params {
        questionId
        answers
        yAxis
      }
    }
  }
`;

type PredictionRouteParams = {
  studyId: string
  analysisId: string
}

export default function Predict() {
  const { analysisId } = useParams<PredictionRouteParams>();
  const { study: { questions }} = useStudyResults();
  const [polling, setPolling] = useState(false);

  const { data, loading } = useQuery(PREDICTION_QUERY, {
    variables: { analysisId },
    pollInterval: polling ? 3000 : 0,
  });

  const stillLoading = data?.analysis.state === 'created' || data?.analysis.state === 'sent';

  useEffect(() => {
    setPolling(stillLoading);
  }, [stillLoading]);

  if (loading) { return <Loader />; }
  if (stillLoading) { return <Loader />; }

  const { yAxis, questionId, answers } = data.analysis.params;
  const sortedImportances = sortBy(Object.entries(data.analysis.questionImportance), (a: any) => -a[1][2]);
  const question = (questions as Question[]).find(q => q.id === questionId);
  const targetAnswers: Option[] = question
  ? answers.map((index: number) => question.options[index])
  : [];

  const totalParticipants = yAxis.length
  const matchingParticipants = yAxis.filter((v: number) => v === 1).length

  const fakePersonas = [
    { personaId: 0 },
    { personaId: 1 },
  ];
  const fakeParticipants = new Array(totalParticipants).fill(null).map((_, i) => ({
    personaId: i < matchingParticipants ? 0 : 1,
    x: Math.random() * 100,
    y: Math.random() * 100,
  }));

  return (
    <Content>
      <Stack fullWidth>
        <IconLink to="../predict" icon="arrowLeft" label="Back to other searches" />

        <Panel>
          <Stack>
            <Heading>Prediction results</Heading>
            <Text>
              Learn what other answers predicted participants answering a certain question.
            </Text>

            <Inline fullWidth justify="space-between" align="flex-end">
              <Stack spacing="small">
                {question ? (
                  <Stack spacing="xsmall">
                    <Text strong>Question</Text>
                    <Text>{question.label}</Text>
                  </Stack>
                ) : null}

                <Stack spacing="xsmall">
                  <Text strong>Answer{targetAnswers.length > 1 ? 's' : ''}</Text>
                  <Text>{targetAnswers.map(a => a.label).join(', ')}</Text>
                </Stack>

                {yAxis ? (
                  <Text>
                    <strong>
                      {Math.round((yAxis as number[]).filter(v => v === 1).length / yAxis.length * 100)}%
                    </strong> of participants selected that answer.
                  </Text>
                ) : null}
              </Stack>

              <div style={{ width: 150 }}>
                <StaticGalaxy
                  // @ts-expect-error
                  personas={fakePersonas}
                  participants={fakeParticipants}
                  opacity={1}
                  pointSize={1.5}
                  showHulls={false}
                />
              </div>
            </Inline>
          </Stack>
        </Panel>
        {data ? (
          <Stack fullWidth>
            <Heading level={4}>Results</Heading>
            <Grid rowTemplate={['150px', '1fr', '1fr']}>
              <Grid.Header>Predictive Power</Grid.Header>
              <Grid.Header>Answer</Grid.Header>
              <Grid.Header>Question</Grid.Header>
              <Grid.Line />

              {sortedImportances.map(([compoundQuestionId, importanceArray]) => {
                const answerId = compoundQuestionId.match(/_(\d+)$/);
                const questionId = compoundQuestionId.replace(/_\d+$/, '');

                const [, , average] = importanceArray as number[];
                const question = (questions as Question[]).find(q => q.id === questionId);
                const answer = answerId && question?.options[parseInt(answerId[1])];

                const barColor = average >= 0.8 ? 'success' : (
                  average >= 0.4 ? 'warning' : 'error'
                );

                return (
                  <Fragment key={compoundQuestionId}>
                    <Inline fullWidth spacing="xsmall" align="center">
                      <BigNumber>{Math.round(average * 100)}%</BigNumber>
                      <ImportanceBar value={average} color={barColor} />
                    </Inline>

                    <Grid.Cell>{answer?.label}</Grid.Cell>
                    <Grid.Cell>{question?.label}</Grid.Cell>

                    <Grid.Line />
                  </Fragment>
                );
              })}
            </Grid>
          </Stack>
        ) : null}
      </Stack>
    </Content>
  )
}
