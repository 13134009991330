import { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { BorderlessInput } from 'components/atoms/inputs';
import Text from 'atoms/Text';
import { ReactComponent as CheckedSvg } from 'images/checkbox-check.svg';
import { ReactComponent as UncheckedSvg } from 'images/checkbox-base.svg';
import { ReactComponent as FocusSvg } from 'images/checkbox-focus.svg';

const AnswerList = styled.ul`
  margin-left: -16px;
  padding-left: 10px;
  border-left: 6px solid ${p => p.error ? p.theme.colors.error : 'transparent'};
  transition: 0.4s border-color;
`;

const Answer = styled.li`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const AnswerLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;

  font-size: 18px;
  cursor: pointer;
`;

const checkAnimation = keyframes`
  0% { transform: scale(0) }
  100% { transform: scale(1) }
`;

const Checkbox = styled.input`
  position:absolute;
  clip: rect(0,0,0,0);
  clip: rect(0 0 0 0);
`

const Base = styled(UncheckedSvg)`
  min-width: 36px;
  margin-right: 10px;
`;

const Check = styled(CheckedSvg)`
  position: absolute;
  left: 1px;
  top: 3px;
  display: none;

  ${Checkbox}:checked ~ & {
    display: block;
  }

  animation: ${checkAnimation} 0.1s ease-in-out;
`;

const Focus = styled(FocusSvg)`
  position: absolute;
  left: 0;

  display: none;

  ${Checkbox}:focus ~ & {
    display: block;
  }
`;

function setFocus(e) {
  // Safari doesn't focus the input on click for some reason
  e.target.focus();
}

export default function CheckboxQuestion({ question, error, answer, setAnswer }) {
  const [currentOther, setCurrentOther] = useState('');
  const otherInputRef = useRef(null);

  const currentAnswer = answer || [];
  const otherSelected = currentAnswer.includes(currentOther);

  const handleChangeOther = e => {
    const newText = e.target.value;
    const otherIndex = currentAnswer.findIndex(a => typeof a === 'string');

    setAnswer(currentAnswer.map((a,i) => i === otherIndex ? newText : a));
    setCurrentOther(newText);
  }
  const toggleItem = (item, value) => setAnswer(value ? [...(answer || []), item] : currentAnswer.filter(v => v !== item) )

  return (
    <>
      {error && <Text color="error">You must select an option before continuing</Text>}

      <AnswerList error={error}>
        {question.options && question.options.map((o,i) => {
          const checked = currentAnswer.includes(i);

          return (
            <Answer key={`${question.id}_${i}`}>
              <AnswerLabel>
                <Checkbox
                  type="checkbox"
                  onClick={setFocus}
                  checked={checked}
                  onChange={() => toggleItem(i, !checked)}
                />
                <Base />
                <Focus />
                <Check />
                {o.label || `${question.type === 'task_type' ? 'Task' : 'Option'} ${i + 1}`}
              </AnswerLabel>
            </Answer>
          );
        })}

        {question.allowOther ? (
          <Answer>
            <AnswerLabel>
              <Checkbox
                type="checkbox"
                onClick={(e) => { otherInputRef.current?.focus() }}
                checked={otherSelected}
                onChange={() => toggleItem(currentOther, !otherSelected) }
              />
              <Base />
              <Focus />
              <Check />
              Other
              <div style={{ width: '20px' }}/>
              <BorderlessInput
                ref={otherInputRef}
                value={currentOther}
                onChange={handleChangeOther}
                disabled={!otherSelected}
                maxLength="30"
              />
            </AnswerLabel>
          </Answer>
        ) : null}
      </AnswerList>
    </>
  );
};
