import { useMutation } from '@apollo/client';
import { REQUEST_CLUSTERS } from 'queries';

export default function useRequestAnalysis(studyId, numClusters) {
  return useMutation(REQUEST_CLUSTERS, {
    variables: { studyId, numClusters },
    refetchQueries: ['studyQuery'],
    awaitRefetchQueries: true,
  });
}
