import styled from 'styled-components/macro';
import { useRouteMatch } from 'react-router-dom';
import StepList from 'components/StepList';
// import useAuth from 'hooks/useAuth';

const Beta = styled.span`
  color: ${p => p.theme.colors.brand};
  font-style: italic;
`

export default function Nav({ personas, myAccess, readOnly }) {
  // const { roles } = useAuth();
  const match = useRouteMatch();
  const subPageMatch = useRouteMatch(`${match.path}/:subpage`);

  let currentPage = subPageMatch?.params?.subpage;

  // To handle drill-in pages, there's probably a better way to do this
  if(currentPage === 'participant') { currentPage = 'participants' }

  const showPersonas = personas && currentPage?.match(/^clusters?$/);

  const pages = [
    ['clusters', 'Cluster Map'],
    ...(showPersonas ? personas.map(p => [`cluster/${p.personaId}`, p.name, { indented: true, color: p.color }]) : []),
    (readOnly || myAccess !== 'full' ) ? null : ['calibrate', 'Edit clusters'],
    ['compare_questions', <>Compare <Beta>Beta</Beta></>],
    ['predict', <>Predict <Beta>Beta</Beta></>],
    ['raw_results', 'Results'],
    ['participants', 'Participants'],
    myAccess === 'full' ? ['collaborate', 'Collaborate'] : null,
    ['reports', 'Reports'],
  ].filter(Boolean);

  return (
    <StepList hideBackground baseUrl={match.url} activeStep={currentPage} steps={pages}/>
  );
}
