import Text from 'atoms/Text';
import { RadioList, Radio } from 'components/atoms/radio';
import { BorderlessInput } from 'components/atoms/inputs';

export default function RadioQuestion({ question, error, answer, setAnswer, reverseOptions }) {
  const otherSelected = typeof answer === 'string';

  // Frequency types (and some others) get displayed backwards
  let sortedOptions = (question.options || []).map((o, i) => ({ ...o, optionIndex: i }));
  if (reverseOptions) { sortedOptions.reverse(); }

  return (
    <>
      {error && <Text color="error">You must select an option before continuing</Text>}

      <RadioList error={error}>
        {sortedOptions.map(o => {
          return (
            <Radio
              key={`${question.id}_${o.optionIndex}`}
              checked={answer === o.optionIndex}
              onChange={() => setAnswer(o.optionIndex)}
            >
              {o.label || `${question.type === 'task_type' ? 'Task' : 'Option'} ${o.optionIndex + 1}`}
            </Radio>
          );
        })}

        {question.allowOther ? (
          <Radio
            checked={otherSelected}
            onChange={() => setAnswer('')}
          >
            Other
            <div style={{ width: '20px' }}/>
            <BorderlessInput
              value={otherSelected ? answer : ''}
              onChange={e => setAnswer(e.target.value)}
              disabled={!otherSelected}
              maxLength="30"
            />
          </Radio>
        ) : null}
      </RadioList>
    </>
  );
};
