import ReactDOM from 'react-dom';
import GitInfo from 'react-git-info/macro';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import AppWrapper from './components/AppWrapper';
import App from './components/App';

const gitInfo = GitInfo();

// TODO later: react-router integration
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: gitInfo.hash,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2, // TODO: adjust on prod later maybe?
  });

  // TODO: add context with user data etc
  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/context/
}

// TODO: errorBoundary
// https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

// TODO: Sentry.withProfiler() HOC
// https://docs.sentry.io/platforms/javascript/guides/react/components/profiler/

ReactDOM.render(
  <AppWrapper><App /></AppWrapper>,
  document.getElementById('root')
);
