import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import StudyContext from 'studyContext';
import useNotifications from 'hooks/useNotifications';
import Modal from 'components/Modal';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { Inline, Stack } from 'components/atoms/layout';
import Button from 'atoms/Button';
import { TEMPLATES } from '../../constants';
import { PUBLISH_MUTATION } from 'queries';

export default function PublishModal({ handleClose }) {
  const { study: { id, questions, template }, dispatch } = useContext(StudyContext);
  const notify = useNotifications();
  const [publishMutation, { loading }] = useMutation(PUBLISH_MUTATION, {
    variables: { id },
    onCompleted: d => {
      dispatch({ type: 'changeState', state: d.publishStudy.state });
      notify({ message: 'Your survey has been published' });
    },
    onError: () => notify({ message: 'There was an error publishing.' }),
  });

  const [manualBypass, setManualBypass] = useState(false);

  const publish = () => {
    publishMutation().then(() => handleClose());
  }

  let totalQuestionsNeeded = 0;

  const questionsNeededByCategory = Object.entries(TEMPLATES[template].question_categories).map(([category, { adjective, recommendedAmount: { min } = { min: 0 }}]) => {
    const numQuestions = questions.filter(q => q.category === category).length;
    const questionsNeeded = Math.max(0, min - numQuestions);

    totalQuestionsNeeded += questionsNeeded;

    return questionsNeeded > 0 ? [adjective, questionsNeeded] : null;
  }).filter(v => v);

  if (totalQuestionsNeeded === 0 || manualBypass) {
    return (
      <Modal handleClose={handleClose}>
        <Stack>
          <Inline fullWidth justify="center">
            <Heading>Publish survey</Heading>
          </Inline>

          <Text size="body">
            Questions can not be edited after publishing once participants have begun responding. This is to maintain data integrity.
          </Text>

          <Text size="body">
            Once your survey is published you can start sending out a link to participants.
          </Text>

          <Inline spacing="small" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Button loading={loading} onClick={publish}>
              Publish
            </Button>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          </Inline>
        </Stack>
      </Modal>
    );
  }

  return (
    <Modal handleClose={handleClose}>
      <Stack>
        <Inline fullWidth justify="center">
          <Heading>Questions count</Heading>
        </Inline>

        <Text size="body">
          <strong>{totalQuestionsNeeded} more questions in total</strong> are recommended for
          personas to cluster accurately.  The key areas for further questions are:
        </Text>
        <Stack spacing="small">
          {questionsNeededByCategory.map(([adjective, n]) => (
            <Text size="body" key={adjective}>
              <strong>+ {n} more</strong> {adjective} questions
            </Text>
          ))}
        </Stack>

        <Text size="body">
          You may continue but it could affect the persona data.
        </Text>

        <Inline spacing="small" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Button onClick={() => setManualBypass(true)}>Proceed</Button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </Inline>
      </Stack>
    </Modal>
  );
}
