import { useContext } from 'react';
import faker from 'faker';
import useKeyboardShortcut from 'hooks/useKeyboardShortcut';
import useNotifications from 'hooks/useNotifications';
import StudyContext from 'studyContext';
import { QUESTION_TYPES } from '../constants';
import useStudyResults from 'hooks/useStudyResults';
import apiCall from 'utils/apiCall';

function fakeAnswerFor(question) {
  const { participantBehavior } = QUESTION_TYPES[question.type];

  // TODO: never fills out 'other' options

  if (participantBehavior === 'open') {
    return faker.lorem.paragraph();
  } else if (participantBehavior === 'email') {
    return faker.internet.email(); // TODO: sometimes skip is more realistic
  } else if (participantBehavior === 'radio') {
    return Math.floor(Math.random() * question.options.length);
  } else if (participantBehavior === 'checkbox') {
    // TODO: should always be at least 1
    return question.options.map((o,i) => i).filter(() => Math.random() < 0.5);
  } else if (participantBehavior === 'semantic_differential') {
    return Math.random() * 2 - 1;
  } else if (participantBehavior === 'message') {
    return undefined;
  } else {
    throw new Error(`Unknown participantBehavior: ${participantBehavior}`);
  }
};

function manualResponse(response) {
  apiCall('response', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ response }),
  });
}

export default function FakeResponseCreator() {
  const notify = useNotifications();
  // TODO: damn it's annoying having these two providers, unify this at some point
  const { study: { id: sStudyId, state: sState, questions: sQuestions } = {}} = useContext(StudyContext) ?? {};
  const { study: { id: rStudyId, state: rState, questions: rQuestions } = {}} = useStudyResults() ?? {};

  const state = rState ?? sState;
  const studyId = rStudyId ?? sStudyId;
  const questions = rQuestions ?? sQuestions;

  useKeyboardShortcut(['Control', 'r'], () => {
    if (state === 'published') {
      const answers = Object.fromEntries(questions.map(q => {
        return [q.id, fakeAnswerFor(q)];
      }));

      manualResponse({ studyId, answers });
    } else {
      notify({
        tag: 'admin_fakeresponsefail',
        message: 'Publish the study before trying to create responses!',
      });
    }
  });

  return null;
}
