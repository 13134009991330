import { useCallback } from 'react';
import useNotifications from 'hooks/useNotifications';
import useWebsocket from 'hooks/useWebsocket';
import useAuth from 'hooks/useAuth';
import { FREE_PLAN_RESPONSE_LIMIT } from '../constants';
import useStudyResults from 'hooks/useStudyResults';
import useRequestAnalysis from 'hooks/useRequestAnalysis';

export default function useStudyLiveUpdates() {
  const { roles } = useAuth();
  const { study: { id, latestAnalysis, responses }, refetch } = useStudyResults();
  const [requestAnalysis] = useRequestAnalysis(id, 4); // TODO: numClusters
  const notify = useNotifications();

  const hasAnalysis = !!latestAnalysis;
  const onProPlan = roles.includes('pro_plan_active');
  const canHaveMoreResponses = (onProPlan || (responses || []).length <= FREE_PLAN_RESPONSE_LIMIT)

  useWebsocket(`study-${id}`, useCallback((event) => {
    if (event === 'new_response') {
      if (hasAnalysis && canHaveMoreResponses) {
        notify({
          tag: 'new_response',
          message: 'New response to survey, recalibrate to include them and update your personas.',
          timeout: null,
          cta: { label: 'Update', func: requestAnalysis },
        });
        refetch();
      } else {
        notify({ tag: 'new_response', message: 'A new response has arrived.' });
        refetch();
      }
  } else if (event === 'update_analysis') {
      refetch();
    }
  }, [hasAnalysis, requestAnalysis, notify, refetch, canHaveMoreResponses]));
}
