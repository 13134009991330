import { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { useQuery, gql } from '@apollo/client';
import Heading from 'atoms/Heading';
import Stack from 'atoms/Stack';
import Text from 'atoms/Text';
import Grid from 'atoms/Grid';
import { useHistory, useParams } from 'react-router';
import Button from 'atoms/Button';
import Loader from 'components/Loader';
import Link from 'atoms/Link';
import Content from 'atoms/Content';
import useStudyResults from 'hooks/useStudyResults';
import type { Question } from 'utils/types';
import Panel from 'atoms/Panel';
import Inline from 'atoms/Inline';
import GalaxyLoader from '../GalaxyLoader';
import ImportanceBar from 'components/ImportanceBar';
import { sortBy } from 'lodash';
import { AnimateSharedLayout } from 'framer-motion';
import { ReactComponent as BigArrowSvg } from 'images/big-arrow.svg';

const FactorWrapper = styled(motion.div)`
  width: 100%;
`;

const BigNumber = styled(motion.em)`
  display: inline-block;
  font-family: ${p => p.theme.fonts.serif};
  font-size: ${p => p.theme.fontSizes.large};
`;

const RECENT_PREDICTIONS_QUERY = gql`
  query recentPredictions($studyId: ID!) {
    study(id: $studyId) {
      id
      analyses(type: predict) {
        id
        createdAt
        state
        params {
          questionId
          answers
        }
      }
    }
  }
`;

type PredictRouteParams = {
  studyId: string
}

const FACTORS = ['Prefers eco-friendly', 'Shops once a week', 'Is under 35'];

type example = {
  answer: number
  factors: [string, number][]
}

function randomExample(): example {
  return {
    answer: Math.floor(Math.random() * 9) + 1,
    factors: sortBy(FACTORS.map(f => [f, 0.2 + Math.random() * 0.8]), a => -a[1]),
  };
}

export default function Predict() {
  const history = useHistory();
  const [example, setExample] = useState(() => randomExample());
  const { study: { questions }} = useStudyResults();
  const { studyId } = useParams<PredictRouteParams>();
  const { data, loading } = useQuery(RECENT_PREDICTIONS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { studyId },
  });

  useEffect(() => {
    const i = setInterval(() => {
      setExample(randomExample());
    }, 3000);

    return () => clearInterval(i);
  }, []);

  return (
    <Content fullHeight>
      <Stack fullWidth fullHeight>
        <Panel>
          <Stack>
            <Heading>Predict</Heading>

            <Text>
              Learn what other answers predicted participants answering a certain question for any
              closed question and answer. Simply choose a question and answer you want to learn about
              and see a list of answers and their predictive power.
            </Text>

            <Text>
              e.g. <strong>
                Why did people answer
                {' '}<BigNumber>{example.answer}</BigNumber>{' '}
                on NPS?
              </strong></Text>

            <Inline fullWidth justify="space-between" align="center" spacing="small">
              <Stack spacing="small">
                <Text>Question: NPS Score</Text>
                <Text>Answer: <BigNumber>{example.answer}</BigNumber></Text>
              </Stack>

              <BigArrowSvg />

              <GalaxyLoader delay={3} width={150} />

              <BigArrowSvg />

              <Stack spacing="small">
                <AnimateSharedLayout>
                  {example.factors.map(([label, value]) => {
                    // Duplicated in Prediction.tsx
                    const barColor = value >= 0.8 ? 'success' : (
                      value >= 0.4 ? 'warning' : 'error'
                    );

                    return (
                      <FactorWrapper layoutId={label} key={label}>
                        <Text>{label}</Text>
                        <ImportanceBar layoutId={`${label}-bar`} color={barColor} value={value} />
                      </FactorWrapper>
                    );
                  })}
                </AnimateSharedLayout>
              </Stack>
            </Inline>

            <Button onClick={() => history.push('predict/new')}>
              Begin
            </Button>
          </Stack>
        </Panel>

        {loading && <><div /><Loader /></>}

        {data?.study.analyses.length ? (
          <>
            <Heading level={4}>Recent searches</Heading>

            <Grid rowTemplate={['1fr', '1fr', '100px']}>
              <Grid.Header>Question</Grid.Header>
              <Grid.Header>Answers</Grid.Header>
              <Grid.Header></Grid.Header>
              <Grid.Line />

              {data.study.analyses.map((analysis: any) => {
                const question = (questions as Question[]).find(q => q.id === analysis.params.questionId);
                const answers = (analysis.params.answers as number[] | null)?.map(a => question?.options[a]);

                return (
                  <Fragment key={analysis.id}>
                    <Grid.Cell>
                      {question?.label}
                    </Grid.Cell>

                    <Grid.Cell>
                      {answers?.map(a => a?.label).join(', ')}
                    </Grid.Cell>

                    <Grid.Cell align="right">
                      <Link to={`predict/${analysis.id}`}>
                        Open
                      </Link>
                    </Grid.Cell>

                    <Grid.Line />
                  </Fragment>
                );
              })}
            </Grid>
          </>
        ) : null}
      </Stack>
    </Content>
  )
}
