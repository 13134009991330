import styled from 'styled-components/macro';

export const Sidebar = styled.div`
  position: fixed;
`;

export const Panel = styled.div`
  width: 100%;
  padding: var(--spacing-${p => p.spacing || 'medium'});
`

export const FlexPanel = styled(Panel)`
  flex-grow: 1;
`

export const Inline = styled.div`
  display: flex;
  flex-direction: ${p => p.reverse ? 'row-reverse' : 'row'};
  justify-content: ${p => p.justify || 'flex-start'};
  width: ${p => p.fullWidth ? '100%' : 'fit-content'};
  align-items: ${p => p.align || 'center'};

  gap: var(--spacing-${p => p.spacing || 'medium'});
`;

export const InlineCollapse = styled(Inline)`
  @media (max-width: ${p => p.theme.breakpoints.desktop}) {
    flex-direction: column;

   gap: var(--spacing-${p => p.collapseSpacing || p.spacing || 'medium'});
  }
`;

export const Expander = styled.div`
  flex-grow: 1;
  margin: 0 !important;
  min-height: ${p => p.minSize ? p.theme.spacing[p.minSize] : 0};
`;

export const Spacer = styled.div`
  height: var(--spacing-${p => p.size || 'medium'});
  margin: 0 !important; /* Don't want stacks to apply their margin as well as the space */

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    height: var(--spacing-${p => p.mobileSize || p.size || 'medium'});
  }
`;

export const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

export const Island = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.fullWidth ? 'width: 100%;' : ''}
  height: ${p => p.fullHeight ? '100%' : ''};
  ${p => p.alignItems && `align-items: ${p.alignItems};`}
  justify-content: ${p => p.justify || 'flex-start'};

  > * + * {
    margin-top: var(--spacing-${p => p.spacing || 'medium'});

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
      margin-top: var(--spacing-${p => p.mobileSpacing || p.spacing || 'medium'});
    }
  }
`;

export const Content = styled.div`
  max-width: ${p => p.maxWidth ?? '800px'};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 120px;
  height: ${p => p.fullHeight ? '100%;' : 'fit-content'};
  overflow-x: auto;

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    max-width: ${p => p.mobileMaxWidth ?? '100ch'};
  }
`;

export const ThinContent = styled.div`
  width: 360px;
  margin: 100px auto 0 auto;
`;

export const ContentWrapper = styled.div`
  margin-right: ${p => p.showPreview ? p.theme.previewWidth : 0};
  margin-left: ${p => p.showPreview && p.sidebar ? p.theme.sidebarWidth : 0};
  height: calc(100% - ${p => p.offsetY || 0}px);

  padding: ${p => p.noPadding ? '0' : '60px 40px 0 40px'};
  ${p => p.noBottomPadding && 'padding-bottom: 0;'}

  background-color: ${p => p.theme.colors.background};

  &:focus {
    outline: none;
  }

  @media (max-width: ${p => p.theme.breakpoints.desktop}) {
    margin-left: 0;
    padding: ${p => p.noPadding ? '0' : '60px 20px 0 20px'};
  }

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    margin-right: 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${p => p.theme.colors.dark}
`;

export const MobileOnly = styled.span`
  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const DesktopOnly = styled.span`
  @media (max-width: ${p => p.theme.breakpoints.desktop}) {
    display: none;
  }
`;

export const NotMobile = styled.span`
  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const NotDesktop = styled.span`
  @media (min-width: ${p => p.theme.breakpoints.desktop}) {
    display: none;
  }
`;
