export default async function apiCall(url, args) {
  const { headers = {}, ...otherArgs } = args;

  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${url}`, {
    ...otherArgs,
    headers: {
      'Original-Referer': document.referrer,
      ...headers,
    },
  });
}
