import { useState, useReducer } from 'react';
import styled from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import Question from './Question';
import ProgressBar from './ProgressBar';
import PreviewBanner from './PreviewBanner';
import Loader from 'components/Loader';
import { Stack } from 'components/atoms/layout';
import Button from 'atoms/Button';
import IconLink from 'components/IconLink';
import ErrorPage from 'components/ErrorPage';
import LogoImage from 'images/logo.svg';
import { QUESTION_TYPES } from '../../constants';
import apiCall from 'utils/apiCall';

// const REDIRECT_URL_TIMEOUT_MS = 3000;

const ParticipantViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PageWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 600px;
  flex-grow: 1;
`;

const Page = styled(motion.div)`
  position: absolute;
  width: 100%;
  top: 0;
  padding: 30px 30px 80px 30px;
`;

const Footer = styled.footer`
  @media only screen and (max-width: 640px) {
    display: none;
  }

  ${p => p.forceMobile && 'display: none;'}

  background-color: ${p => p.theme.colors.accentBackground};
  height: 80px;
`;

const FixedSection = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
`;

const ContinueButton = styled(Button)`
  background-color: ${p => p.accentColor};
  color: ${p => textColorFor(p.accentColor)};

  &:hover {
    color: ${p => p.accentColor};
  }

  /* TODO: possibly make this generic so other buttons work OK on mobile too? */
  @media (hover: none) {
    &:hover {
      background-color: ${p => p.accentColor};
      color: ${p => textColorFor(p.accentColor)};
      border-color: transparent;
      box-shadow: none;
    }
  }

  &:active {
    background-color: ${p => p.accentColor};
    color: ${p => textColorFor(p.accentColor)};
  }
`;

function responseReducer(state, action) {
  switch (action.type) {
    case 'answer':
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: action.answer,
        }
      };
    default:
      throw new Error();
  }
}

function answerIsValid(question, answer, skip) {
  if (question.type === 'message' || question.type === 'welcome_message') {
    return true;
  }

  if (question.type === 'email') {
    if (skip) { return true; } // TODO: unhardcode this probably

    // World's most basic email validation
    return answer && answer.match(/@/);
  }

  // Basic presence validation
  return answer !== undefined && answer !== null && answer !== '' && (!Array.isArray(answer) || answer.length > 0);
}

function textColorFor(hexCode) {
  const match = hexCode.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);

  if (!match) { return 'black'; }

  const [,r,g,b] = match;

  let r2 = parseInt(r, 16) / 255.0
  r2 = r2 <= 0.03928 ? r2/12.92 : Math.pow((r2+0.055)/1.055, 2.4)

  let g2 = parseInt(g, 16) / 255.0
  g2 = g2 <= 0.03928 ? g2/12.92 : Math.pow((g2+0.055)/1.055, 2.4)

  let b2 = parseInt(b, 16) / 255.0
  b2 = b2 <= 0.03928 ? b2/12.92 : Math.pow((b2+0.055)/1.055, 2.4)

  const L = 0.2126 * r2 + 0.7152 * g2 + 0.0722 * b2

  return L > 0.179 ? 'black' : 'white';
}

export default function ParticipantView({ study, preview, showPreviewBanner, currentPage, priorPage, changePage, forceMobile }) {
  const [response, dispatch] = useReducer(responseReducer, { studyId: study.id, startedAt: new Date().toISOString(), answers: {}});
  const [error, setError] = useState(false);
  const [responseState, setResponseState] = useState('survey');

  const accentColor = study.options?.accentColor || '#2A238A';
  const typography = study.options?.typography || 'combo';
  // const { redirectUrl } = study.options || {};

  // useEffect(() => {
  //   if (!preview && currentPage === 'closing' && redirectUrl) {
  //     setTimeout(() => {
  //       window.location = redirectUrl;
  //     }, REDIRECT_URL_TIMEOUT_MS);
  //   }
  // }, [preview, currentPage, redirectUrl]);

  if (responseState === 'submitting') return <Loader />;
  if (responseState === 'failed') return <ErrorPage />;
  if (responseState === 'submitted') {
    return (
      <ParticipantViewWrapper>
        <FixedSection>
          {showPreviewBanner && <PreviewBanner />}
        </FixedSection>

      <PageWrapper>
        <AnimatePresence initial={false}>
          <Page
            key={currentPage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Stack fullWidth spacing="xlarge" alignItems="center">
              <Question
                question={{ type: 'message', label: 'Thanks for your time.\nYou can close this window.' }}
                typography={typography}
              />

              <a href="https://akin.nz">
                <img
                  src={LogoImage}
                  width={100}
                  alt="Akin logo"
                />
              </a>
            </Stack>
          </Page>
        </AnimatePresence>
      </PageWrapper>

      <Footer forceMobile={forceMobile} />
    </ParticipantViewWrapper>
    );
  }

  function handleSubmit() {
    if (preview) {
      // Don't send anything for previews studies, just head straight to closing.
      setResponseState('submitted');
    } else {
      setResponseState('submitting');
      // TODO: graphql this
      apiCall('response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ response }),
      }).then(() => {
          setResponseState('submitted');
        })
        .catch(() => setResponseState('failed'));
    }
  }

  function previousPage() {
    setError(false);
    changePage(p => p - 1);
  }

  function nextPage({ skip }) {
    const currentQuestion = study.questions[currentPage];
    const currentAnswer = currentQuestion && response.answers[currentQuestion.id];

    if (!answerIsValid(currentQuestion, currentAnswer, skip)) {
      setError(true);
      return;
    }

    // Check for survey end
    if (currentPage === study.questions.length - 1) {
      handleSubmit();
      return;
    }

    // TODO: refactor screener logic outta here
    if (currentQuestion?.type === 'screener') {
      if (currentQuestion.options[currentAnswer].exclude) {
        // Screener question says they gone
        changePage('rejection');
        return;
      }
    }

    setError(false);
    changePage(p => p + 1);
  }

  // TODO: should `page` be in the route?
  const currentQuestion = study.questions[currentPage];
  const currentAnswer = response.answers[currentQuestion.id];

  // TODO: unhardcode this later probably
  const optional = currentQuestion.type === 'email';

  const skipQuestion = () => {
    setAnswer(null);
    nextPage({ skip: true });
  }

  const setAnswer = answer => {
    dispatch({ type: 'answer', questionId: currentQuestion.id, answer });
  };
  let direction = priorPage > currentPage ? -1 : 1;

  const onLastPage = currentPage === study.questions.length - 1;
  const currentQuestionType = QUESTION_TYPES[currentQuestion.type];
  const canContinueWithoutAnswer = currentQuestionType.participantBehavior === 'message' //&& currentPage < (study.questions.length-1);

  return (
    <ParticipantViewWrapper>
      <FixedSection>
        {showPreviewBanner && <PreviewBanner />}
        {Number.isInteger(currentPage) && <ProgressBar color={accentColor} progress={(currentPage + 1) / study.questions.length} />}
      </FixedSection>

      <PageWrapper>
        <AnimatePresence initial={false}>
          <Page
            key={currentPage}
            initial={{ opacity: 0, translateY: `${direction*70}vh` }}
            animate={{ opacity: 1, translateY: '0vh', transition: { duration: 0.6 }}}
            exit={{ opacity: 0, transition: { duration: 0.2 }}}
          >
            <Stack>
              {currentPage > 0 && (
                <IconLink icon="arrowLeft" label="Back" onClick={previousPage} />
              )}

              <Question
                question={currentQuestion}
                typography={typography}
                answer={currentAnswer}
                setAnswer={setAnswer}
                error={error}
              />

              <Stack spacing="small">
                {(currentAnswer === undefined && !canContinueWithoutAnswer) ? null : (
                  <ContinueButton
                    initial={{ scale: 0.5}}
                    animate={{ scale: 1 }}
                    accentColor={accentColor}
                    onClick={nextPage}
                  >
                    {onLastPage ? 'Submit' : 'Continue'}
                  </ContinueButton>
                )}
                {optional && (
                  <Button variant="secondary" onClick={skipQuestion}>
                    Skip
                  </Button>
                )}
              </Stack>
            </Stack>
          </Page>
        </AnimatePresence>
      </PageWrapper>

      <Footer forceMobile={forceMobile} />
    </ParticipantViewWrapper>
  );
}
