import { useState } from 'react';
import styled from 'styled-components/macro';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { motion, AnimateSharedLayout } from 'framer-motion';

const StepListWrapper = styled.ol`
  position: relative;

  > li + li {
    margin-top: 24px;
  }
`;

const StepBar = styled.div`
  position: absolute;
  background-color: ${p => p.$hideBackground ? 'transparent' : '#f2f2f2'};

  top: 6px;
  left: -1px;
  width: 3px;
  height: ${p => (p.stepCount - 1) * 44}px;
`;

const Step = styled.li`
  position: relative;
  padding-left: ${p => p.indented ? '45px' : '20px'};
`;

const ActiveStep = styled(motion.div)`
  position: absolute;
  left: -4px;
  top: 6px;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background-color: ${p => p.color ?? p.theme.colors.brand};
  z-index: 1;
`;

const StepLink = styled(ReactRouterNavLink)`
  color: ${p => p.theme.colors.text};
  position: relative;
  white-space: nowrap;

  &:hover {
    color: ${p => p.theme.colors.text};
    font-weight: 600;
  }

  &:active {
    font-weight: 700;
    color: #808283;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -24px;
    top: 5px;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background-color: ${p => p.$hideBackground ? 'transparent' : '#f2f2f2'};
  }

  &.active {
    font-weight: 700;
  }

  &:active::before {
     background-color: ${p => p.$hideBackground ? 'transparent' : '#808283'};
  }
`;

export default function StepList({ activeStep, steps, baseUrl, hideBackground }) {
  const [hovering, setHovering] = useState(null);

  return (
    <AnimateSharedLayout>
      <StepListWrapper onMouseLeave={e => setHovering(null)}>
        <StepBar stepCount={steps.length} $hideBackground={hideBackground} />

        {steps.map(([stepUrl, stepName, options = {}]) => (
          <Step
            key={stepUrl}
            onMouseEnter={() => setHovering(stepUrl)}
            indented={options.indented}
          >
            <StepLink to={baseUrl ? `${baseUrl}/${stepUrl}` : stepUrl} $hideBackground={hideBackground}>
              {stepName}
            </StepLink>

            {(hovering ? hovering === stepUrl : activeStep === stepUrl) && (
              <ActiveStep color={options.color} layoutId="activestep" />
            )}
          </Step>
        ))}
      </StepListWrapper>
    </AnimateSharedLayout>
  );
}
