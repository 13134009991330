import { Island } from 'components/atoms/layout';
import GalaxyLoader from 'components/study/results/GalaxyLoader';

export default function AdminPlayground() {
  return (
    <Island>
      <GalaxyLoader width={200} height={200} />
    </Island>
  );
}
