import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import sortBy from 'lodash.sortby';
import maxBy from 'lodash.maxby';
import DetailDialog from './DetailDialog';
import ImportanceBar from 'components/ImportanceBar';
import { answerDistribution } from 'utils';
import useStudyResults from 'hooks/useStudyResults';
import HighlightSummary from 'components/study/results/HighlightSummary';
import Grid from 'atoms/Grid';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import { Stack } from 'components/atoms/layout';
import { ReactComponent as HighlighterSvg } from 'images/highlighter.svg';
import Inline from 'atoms/Inline';
import Panel from 'atoms/Panel';

const IGNORED_QUESTION_TYPES = ['email', 'screener', 'open', 'welcome_message', 'message'];

export default function PersonaDetail({ persona, onBack, participants, questionImportances }) {
  const history = useHistory();
  const { study: { questions } } = useStudyResults();

  const importances = questionImportances[persona.personaId];
  // move this so ParticipantDetail can use it too
  const eligibleQuestions = questions.filter(q => !IGNORED_QUESTION_TYPES.includes(q.type));
  const keyQuestions = sortBy(eligibleQuestions, q => importances[q.id] ? -importances[q.id][2] : 0).slice(0, 3);
  const participantsForPersona = participants.filter(r => r.personaId === persona.personaId);

  // TODO: DRY this up we have it in ResultsGrid too
  const importanceValues = Object.values(importances).map(([_total, _n, average]) => average);
  const maxImportance = Math.max(...importanceValues) || 1;

  return (
    <DetailDialog
      onBack={onBack}
      onExplore={() => history.push(`cluster/${persona.personaId}`)}
    >
      <Stack spacing="small">
        <Panel width={400}>
          <Stack>
            <Heading level={3}>{persona.name}</Heading>

            <Grid rowTemplate={['1fr', '100px']} rowGap={20} columnGap={10}>
              <Grid.Header>Key attributes</Grid.Header>
              <Grid.Header align="right">Importance</Grid.Header>

              {keyQuestions.map(q => {
                const distribution = answerDistribution(q, participantsForPersona);
                const commonResponse = distribution && maxBy(distribution, ([_i,_l,n]) => n)[1];

                const importancesForQuestion = importances[q.id];

                return (
                  <Fragment key={q.id}>
                    <div>
                      <Text size="body">{q.label}</Text>
                      {commonResponse ? (
                        <Text size="small">Common response: {commonResponse}</Text>
                      ) : null}
                    </div>
                    { importancesForQuestion ? (
                      <ImportanceBar value={importancesForQuestion[2] / maxImportance} color={persona.color} />
                    ) : (


                      <ImportanceBar value={0} color={persona.color} />
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </Stack>
        </Panel>

        <HighlightSummary responses={participantsForPersona} maxToShow={10}>
          {children => (
            <Panel width={400}>
              <Stack spacing="small">
                <Inline spacing="small" align="center">
                  <HighlighterSvg />
                  <Text strong size="body">Highlighted themes</Text>
                </Inline>

                <Text size="small">
                  {children}
                </Text>
              </Stack>
            </Panel>
          )}
        </HighlightSummary>
      </Stack>
    </DetailDialog>
  );
}
