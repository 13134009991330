import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Heading from 'atoms/Heading';
import { ReactComponent as ChevronSvg } from 'images/icons/chevron-right.svg';

export const PageTitle = styled(Heading).attrs({
  level: 1
})`
  font-size: 64px;
  line-height: 80px;
  color: ${p => p.theme.colors.text};

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const FeatureTitle = styled.h2`
  font-size: 36px;
  font-family: ${p => p.theme.fonts.title};
  line-height: 45px;
  color: ${p => p.theme.colors.text};
`;

export const FeatureDescription = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-family: ${p => p.theme.fonts.marketing};
`;

export const MarketingContent = styled.div`
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 80px 0 80px;

  @media (max-width: ${p => p.theme.breakpoints.desktop}) {
    max-width: 700px;
    padding: 0 40px;
  }

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    max-width: 520px;
    padding: 0 35px;
  }
`;

export const LargeImage = styled.img`
  padding: 80px 0;
  width: 100%;

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    padding: 40px 0;
  }
`;

export const CtaButton = styled.button`
  padding: 15px 24px;
  font-size: ${p => p.theme.fontSizes[p.size ?? 'xlarge']};
  line-height: ${p => p.theme.lineHeights[p.size ?? 'xlarge']};
  border: 0;
  border-radius: ${p => p.theme.borderRadius};
  color: white;
  background-color: ${p => p.theme.colors.brand};
  cursor: pointer;

  &:hover {
    box-shadow: none;
    color: ${p => p.theme.colors.brand};
    background-color: white;
  }
`;

const CallToActionLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: ${p => p.theme.fonts.marketing};
  font-size: ${p => p.small ? '20' : '24'}px;
  cursor: pointer;
  color: ${p => p.inactive ? '#AAA49F' : p.theme.colors.brand};
  ${p => p.inactive ? 'pointer-events: none;' : ''}

  &:link, &:visited {
    color: ${p => p.theme.colors.brand};
  }

  &:hover, &:active {
    color: ${p => p.theme.colors.text};
    text-decoration: underline;
  }
`;

const Chevron = styled(ChevronSvg)`
  color: ${p => p.$inactive ? '#AAA49F' : p.theme.colors.brand};
  margin-left: 15px;

  a:hover &, a:active & {
    color: ${p => p.theme.colors.text};
  }
`;

export function CallToAction({ children, ...props }) {
  return (
    <CallToActionLink {...props}>
      {children}
      <Chevron $inactive={props.inactive} />
    </CallToActionLink>
  );
}

export const Emphasis = styled.em`
  box-shadow: 0px -0.4em 0px ${p => p.theme.colors.highlight} inset;
`;
