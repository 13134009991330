import { useLayoutEffect, useRef, useState } from 'react';

const PADDING = 2;
const FONT_SIZE = 4;

export default function Label({ x, y, children, reverse }) {
  const textRef = useRef();
  const [textWidth, setTextWidth] = useState(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.getBBox().width);
    }
  }, [textRef, children]);

  const totalWidth = textWidth + 2*PADDING;
  const totalHeight = FONT_SIZE + 2*PADDING;

  const xOffset = reverse ? -totalWidth-5 : 5;
  const yOffset = -totalHeight / 2;

  return (
    <g
      transform={`translate(${x + xOffset}, ${y + yOffset})`}
    >
      <rect
        fill="black"
        x={0}
        y={0}
        rx={2}
        width={totalWidth}
        height={totalHeight}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
      />
      <text
        ref={textRef}
        x={PADDING}
        y={FONT_SIZE * 0.9 + PADDING}
        fill="white"
        fontSize={FONT_SIZE}
        fontWeight={600}
      >
        {children}
      </text>
    </g>
  );
}
