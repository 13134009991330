import { createGlobalStyle } from 'styled-components/macro';
import LarkWoff from 'fonts/Lark-Medium/font.woff';
import LarkWoff2 from 'fonts/Lark-Medium/font.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lark-Medium';
    src: url(${LarkWoff2}) format('woff2'), url(${LarkWoff}) format('woff');
  }

  * {
    box-sizing: border-box;
  }

  *::selection {
    background-color: #FCC534; // duplicated from theme.colors.highlight
  }

  #root {
    width: 100%;
  }

  html, #root {
    height: 100%;
  }

  body {
    display: flex;
    height: 100%;
    min-height: 100vh;

    overscroll-behavior: none;
    scroll-behavior: smooth;
    font-size: 14px;
    line-height: 19px;
    background-color: ${p => p.theme.colors.background};
    margin: 0;
    font-family: ${p => p.theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: ${p => p.theme.colors.text};

    --spacing-none: 0px;
    --spacing-xsmall: 7.5px;
    --spacing-small: 15px;
    --spacing-medium: 30px;
    --spacing-mlarge: 45px;
    --spacing-large: 60px;
    --spacing-xlarge: 90px;
    --spacing-xxlarge: 120px;
    --spacing-xxxlarge: 200px;
  }

  input, textarea, button {
    font-family: ${p => p.theme.fonts.body};
    color: black;
  }

  .hide-mobile {
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
      display: none;
    }
  }

  .hide-desktop {
    @media (min-width: ${p => p.theme.breakpoints.desktop}) {
      display: none;
    }
  }

  .only-desktop {
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
  }

  strong, b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  hr {
    width: 100%;
    margin: 0;
    border: 0;
    border-top: 1px solid ${p => p.theme.colors.dark};
  }
`

export default GlobalStyle;
