import { useState } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { useMutation, gql } from '@apollo/client';
import TextArea from 'atoms/TextArea';
import Inline from 'atoms/Inline';
import Button from 'atoms/Button';
import Stack from 'atoms/Stack';
import Text from 'atoms/Text';

import useNotifications from 'hooks/useNotifications';
const FeedbackWrapper = styled(motion.div)`
  position: absolute;
  top: ${p => p.theme.navbarHeight + 20}px;
  right: 20px;
  padding: 20px;
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.text};
  color: ${p => p.theme.colors.accentBackground};
  z-index: 4;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: -4px;
    transform: rotate(45deg);
    background-color: ${p => p.theme.colors.text};
    width: 8px;
    height: 8px;
  }
`;

export interface Props {
  handleClose: () => void
}

const SEND_FEEDBACK_MUTATION = gql`
  mutation sendFeedback($feedback: FeedbackInput!) {
    sendFeedback(feedback: $feedback)
  }
`;

export default function FeedbackPanel({ handleClose }: Props) {
  const notify = useNotifications();
  const [feedback, setFeedback] = useState('');
  const [feedbackMutation, { loading }] = useMutation(SEND_FEEDBACK_MUTATION);

  function submitFeedback() {
    feedbackMutation({
      variables: {
        feedback: {
          text: feedback,
          path: window.location.pathname,
        },
      },
    }).then(() => {
      notify({ message: 'Thanks for your feedback!' });
      handleClose();
    });
  }

  return (
    <FeedbackWrapper
      initial={{ translateY: -20, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: -20, opacity: 0 }}
    >
      <Stack fullWidth spacing="small">
        <Text>
          Help us improve akin. What are your thoughts and feedback?
        </Text>

        <TextArea
          value={feedback}
          onChange={v => setFeedback(v)}
          rows={3}
        />
        <Inline spacing="small">
          <Button
            variant="primary"
            onClick={submitFeedback}
            disabled={feedback === ''}
            loading={loading}
          >
            Submit
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Inline>
      </Stack>
    </FeedbackWrapper>
  );
}
