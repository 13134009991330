import { Fragment, useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery, gql } from '@apollo/client';
import { CSVLink } from 'react-csv';
import Heading from 'atoms/Heading';
import Inline from 'atoms/Inline';
import Button from 'atoms/Button';
import Grid from 'atoms/Grid';
import Icon from 'atoms/Icon';
import CreatePromoCodeBatchModal from './promoCodes/CreatePromoCodeBatchModal';
import { Content, Stack, Spacer } from 'components/atoms/layout';
import Loader from 'components/Loader';
import { formatDate } from 'utils';

const UnstyledCSVLink = styled(CSVLink)`
  color: initial;
`;

const ErrorMessage = styled.label`
  color: ${p => p.theme.colors.error};
  font-weight: bold;
`;

const ALL_PROMO_CODE_BATCHES_QUERY = gql`
  query allPromoCodeBatches {
    promoCodeBatches {
      id
      prefix
      note
      issuedAt
      issuedBy {
        id
        name
      }
      codes {
        id
        status
      }
    }
  }
`;

export default function AdminUserList() {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { data, loading, error } = useQuery(ALL_PROMO_CODE_BATCHES_QUERY, {
    fetchPolicy: 'network-only',
  });

  return (
    <Content style={{ minHeight: '100%' }}>
      <Stack>
        <Inline fullWidth justify="space-between">
          <Heading>Promo codes</Heading>
          <Button onClick={() => setShowCreateModal(true)}>
            Issue codes
          </Button>
        </Inline>

        {loading && (
          <>
            <Spacer />
            <Loader />
          </>
        )}

        {error && (
          <ErrorMessage>
            Error retrieving data: {error.graphQLErrors.map(e => e.message)}
          </ErrorMessage>
        )}

        {data && (
          <Stack>
            <Grid rowTemplate={['100px', '70px', '1fr', '1fr', '70px', '50px', '30px']}>
              <Grid.Header>Prefix</Grid.Header>
              <Grid.Header>Issued on</Grid.Header>
              <Grid.Header>Issued by</Grid.Header>
              <Grid.Header>Note</Grid.Header>
              <Grid.Header>Redeemed</Grid.Header>
              <Grid.Header>Total</Grid.Header>
              <Grid.Header>CSV</Grid.Header>
              <Grid.Line />

              {data.promoCodeBatches.map(pcb => (
                <Fragment key={pcb.id}>
                  <Grid.Cell>{pcb.prefix}</Grid.Cell>
                  <Grid.Cell>{formatDate(pcb.issuedAt)}</Grid.Cell>
                  <Grid.Cell>{pcb.issuedBy.name}</Grid.Cell>
                  <Grid.Cell>{pcb.note}</Grid.Cell>
                  <Grid.Cell>{pcb.codes.filter(c => c.status === 'redeemed').length}</Grid.Cell>
                  <Grid.Cell>{pcb.codes.length}</Grid.Cell>
                  <Grid.Cell align="center">
                    <UnstyledCSVLink
                      filename={`codes-${pcb.prefix}.csv`}
                      data={pcb.codes.map(c => [c.id])}
                      headers={['code']}
                    >
                      <Icon i="clone" />
                    </UnstyledCSVLink>
                  </Grid.Cell>
                </Fragment>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>

      {showCreateModal ? (
        <CreatePromoCodeBatchModal
          onClose={() => setShowCreateModal(false)}
        />
      ) : null}
    </Content>
  );
}
