import styled from 'styled-components/macro';
import Button from 'atoms/Button';
import Inline from 'atoms/Inline';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ActionPanel = styled.div`
  width: fit-content;
  padding: 20px 0;
  pointer-events: initial;
`;

export default function DetailDialog({ children, onBack, onExplore }) {
  return (
    <Wrapper>
      {children}

      <ActionPanel>
        <Inline spacing="small">
          <Button variant="text" onClick={onBack}>
            Back
          </Button>

          <Button onClick={onExplore}>
            Explore
          </Button>
        </Inline>
      </ActionPanel>
    </Wrapper>
  );
}
