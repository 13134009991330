import { MarketingContent, Emphasis } from 'components/atoms/marketing';
import { Stack } from 'components/atoms/layout';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import ClusterBackground from 'components/marketing/ClusterBackground';

export default function Banner({ triggerSignup }) {
  return (
    <MarketingContent>
      <ClusterBackground>
        <Stack alignItems="center">
          <Heading>What’s <Emphasis>hiding</Emphasis> in your survey?</Heading>

          <Button onClick={triggerSignup}>Try it for free</Button>
        </Stack>
        </ClusterBackground>
    </MarketingContent>
  );
}
