import styled, { css } from 'styled-components/macro';

interface HeadingElementProps {
  align?: 'left' | 'center' | 'right'
  level: 1 | 2 | 3 | 4 | 5
}

const commonHeadingCSS = css<HeadingElementProps>`
  color: ${p => p.theme.colors.text};
  font-family: ${p => p.level > 3 ? p.theme.fonts.body : p.theme.fonts.title};
  text-align: ${p => p.align || 'inherit'};
  font-size: ${p => p.theme.headingSizes[p.level]};
  line-height: ${p => p.theme.headingLineHeights[p.level]};
`;

const H1 = styled.h1`${commonHeadingCSS}`;
const H2 = styled.h2`${commonHeadingCSS}`;
const H3 = styled.h3`${commonHeadingCSS}`;
const H4 = styled.h4`
  ${commonHeadingCSS}
  font-weight: 500;
`;
const H5 = styled.h5`
  ${commonHeadingCSS}

  text-transform: uppercase;
  letter-spacing: 3%;
  font-weight: 700;
  color: ${p => p.theme.colors.text}7F;
`;

export interface Props {
  level?: HeadingElementProps['level']
  align?: HeadingElementProps['align']
  children: React.ReactNode
  className?: string
};

export default function Heading({ level = 2, align, className, children }: Props) {
  const HeadingElement = [H1,H2,H3,H4,H5][level - 1];

  return (
    <HeadingElement
      level={level}
      align={align}
      children={children}
      className={className}
    />
  );
}
