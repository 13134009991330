import React, { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components/macro';
import { QUESTION_TYPES } from '../../../constants';
import { Stack } from 'components/atoms/layout';
import Text from 'atoms/Text';
import { Fader } from 'components/Fader';
import { motion, AnimateSharedLayout } from 'framer-motion';

export const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const Wrapper = styled.div`
  position: relative;
  height: 330px;
  display: flex;
  box-shadow: ${p => p.theme.shadows.standard};
  border-radius: ${p => p.theme.borderRadius};
  overflow: hidden;
  z-index: 11;
  cursor: pointer;
`;

const QuestionTypeList = styled.ul`
  width: 60%;
  height: 100%;
  overflow: auto;
  padding-bottom: 15px;
`;

const QuestionTypeLabel = styled.li`
  position: relative;
  font-size: 16px;
  padding: 20px 15px 20px 50px;
  font-weight: ${p => p.active ? 'bold' : 'normal'};
  background-color: ${p => p.active ? '#FAF8F7' : p.theme.colors.accentBackground};
`;

const QuestionTypePreview = styled.div`
  width: 40%;
  background-color: #FAF8F7;
  padding: 18px 15px 8px 15px;
`;

const ActiveStep = styled(motion.div)`
  position: absolute;
  left: 25px;
  top: 25px;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background-color: ${p => p.color ?? p.theme.colors.brand};
  z-index: 1;
`;

const PreviewImage = styled.img`
  border-radius: 9px;
  overflow: hidden;
`;

export default function QuestionTypeWidget({ addQuestion, cancel, validQuestionTypes }) {
  const [hovering, setHovering] = useState(validQuestionTypes[0]);
  const wrapperRef = useRef();

  useLayoutEffect(() => {
    if (!wrapperRef.current) { return; }

    wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <>
      <Background onClick={cancel}/>
      <Wrapper ref={wrapperRef} onClick={() => addQuestion(hovering)}>
        <AnimateSharedLayout>
          <QuestionTypeList>
            {validQuestionTypes.map(t => {
              return (
                <QuestionTypeLabel
                  key={t}
                  onMouseOver={() => setHovering(t)}
                  active={hovering === t}
                >
                  {hovering === t && <ActiveStep layoutId="activestep" />}
                  {QUESTION_TYPES[t].label}
                </QuestionTypeLabel>
              );
            })}
          </QuestionTypeList>
        </AnimateSharedLayout>
        <QuestionTypePreview>
          <Stack spacing="small" style={{ width: 165, margin: '0 auto' }}>
            <PreviewImage
              src={`/images/question_types/${hovering}.svg`}
              alt="Question type preview"
            />
            <Text strong size="body">
              {QUESTION_TYPES[hovering].label}
            </Text>
            <Text size="small">
              {QUESTION_TYPES[hovering].description}
            </Text>
          </Stack>
        </QuestionTypePreview>

        <Fader bottom style={{ width: '50%' }} />
      </Wrapper>
    </>
  );
}
